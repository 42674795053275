import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Input } from "@progress/kendo-react-inputs";
import { Loader } from "@progress/kendo-react-all";
import { Dialog } from "@progress/kendo-react-dialogs";
// components
import FullscreenLoader from "../../components/fullScreenLoader";
import { SelectTable } from "../../components/table/selectTable";
import { Pagination } from "../../components/pagination/pagination";
import { Error } from "@progress/kendo-react-labels";
// api
import config from "../../config/config";
import api from "../../config/api";
import {
  getCurrentLoggedInSessionTokenWithPromise,
  getCurrentLoggedInUserWithPromise,
} from "../../config/userPool";
import { fetchALLCompany } from "../../store/companyList";
// icon
import BackIcon from "../../assets/images/Icon/PreviousIcon.svg";
import CrossIcon from "../../assets/images/Icon/Cross_black.svg";
// route
import {
  COMPANY_MANAGEMENT_ROUTE,
  COMPANY_MANAGEMENT_AUDIT_TRAIL,
} from "../../routes";

import moment from "moment";
// css
import "./company.css";

const header = [
  {
    name: "Name",
  },
  {
    name: "Company",
    width: "200px",
  },
  {
    name: "Package",
  },
  {
    name: "Roles",
  },
  {
    name: "Join Date",
    width: "120px",
  },
];

const userListHeaders = [
  {
    name: "Name",
    width: "150px",
  },
  {
    name: "Company",
    width: "150px",
  },
  {
    name: "Package",
  },
  {
    name: "Roles",
  },
];

export const CompanyManagement_form = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let { search } = useLocation();
  const query = new URLSearchParams(search);
  const id = query.get("id");
  const mandatoryFields = ["company_name"];
  const [tab, setTab] = useState(0);
  const [editData, setEditData] = useState({
    created_date: moment(new Date()).format("DD MMM YYYY"),
    company_name: "",
  });
  const [allCompanies, setAllCompanies] = useState([]);
  const { companyList, loading: companyLoading } = useSelector(
    (state) => state.companyList
  );
  const [errors, setErrors] = useState({});
  const [companyMember, setCompanyMember] = useState([]);
  const [loading, setLoading] = useState(true);
  const [fullScreenLoading, setFullScreenLoading] = useState(false);
  const [companyUser, setCompanyUser] = useState({});
  const [activePages, setActivePages] = useState(1);
  const [membersToRemove, setMembersToRemove] = useState([]);
  const [headerCheckboxFlag, setHeaderCheckboxFlag] = useState(false);
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const {
    GET_COMPANY_DETAIL,
    GET_COMPANY_MEMBER_LIST,
    GET_USER_LIST,
    DELETE_COMPANY_ENDPOINT,
  } = config.api_endpoint;

  const [showAddMemberDialog, setShowAddMemberDialog] = useState(false);
  const [showAddMemberLoading, setShowAddMemberLoading] = useState(false);
  const [activePageAddMember, setActivePageAddMember] = useState(1);
  const [addUser, setAddUser] = useState({});
  const [addUserList, setAddUserList] = useState([]);
  const [membersToAdd, setMembersToAdd] = useState([]);
  const [headerAddMemberCheckboxFlag, setHeaderAddMemberCheckboxFlag] =
    useState(false);

  const handleAddMemberPageChange = (page) => {
    setShowAddMemberLoading(true);
    setAddUserList([]);
    setActivePageAddMember(page);
    getAddMembersList(page);
  };

  const handlePageChange = (page) => {
    setLoading(true);
    setCompanyMember([]);
    setActivePages(page);
    callCompanyUser(page);
  };

  const callCompanyUser = async (page) => {
    const token = await getCurrentLoggedInSessionTokenWithPromise();
    const group_user = await api.get(
      GET_COMPANY_MEMBER_LIST,
      {
        id: id,
        page: page,
        page_size: 10,
      },
      token
    );
    if (group_user.status_code === 200) {
      setCompanyUser(group_user.data);
      const members = [];
      for (let i = 0; i < group_user.data.items.length; i++) {
        let company = [];
        for (let j = 0; j < group_user.data.items[i].company.length; j++) {
          company.push(group_user.data.items[i].company[j].company_name);
        }
        members.push({
          id: group_user.data.items[i].sub,
          item1: group_user.data.items[i].name,
          item2: company.join(", "),
          item3: group_user.data.items[i].package.join(", "),
          item4: group_user.data.items[i].role.replaceAll(",", ", "),
          item5: moment(group_user.data.items[i].joined_date).format(
            "DD MMM YYYY"
          ),
          selected:
            membersToRemove.indexOf(group_user.data.items[i].sub) > -1
              ? true
              : false,
        });
      }
      setCompanyMember([...members]);
      setLoading(false);
    }
  };

  const handleCancelClicked = () => {
    console.log("cancel button clicked.");
    navigate(-1);
  };

  const validateInputs = (data) => {
    const _errors = {};
    mandatoryFields.forEach((_field) => {
      if (["company_name"].indexOf(_field) > -1) {
        if (data[_field].length === 0) {
          _errors[_field] = "This field is required.";
        }
      } else {
        if (!data[_field]) {
          _errors[_field] = "This field is required.";
        }
      }
    });

    return _errors;
  };

  const handleRemoveMemberClicked = async () => {
    console.log("Remove Member Clicked.");
    console.log("Members to remove:", membersToRemove);

    if (membersToRemove.length == 0) {
      return;
    }

    const currentLoginUser = await getCurrentLoggedInUserWithPromise();
    console.log("currentLoginUser:", currentLoginUser);

    const toSaveData = {
      id,
      user_ids: membersToRemove,
      updated_by: currentLoginUser["sub"],
    };
    console.log("remove member data:", toSaveData);

    setFullScreenLoading(true);
    const token = await getCurrentLoggedInSessionTokenWithPromise();
    api
      .remove(GET_COMPANY_MEMBER_LIST, JSON.stringify(toSaveData), token)
      .then((res) => {
        console.log("remove member result: ", res);
        setFullScreenLoading(false);
        if (res["status_code"] >= 200 && res["status_code"] < 300) {
          alert("Company members removed.");
          setHeaderCheckboxFlag(false);
          setCompanyMember([]);
          setMembersToRemove([]);
          handlePageChange(1);
        } else {
          alert("Unable to remove the company members.");
        }
      })
      .catch((err) => {
        console.log("remove the company members err", err);
        setFullScreenLoading(false);
      });
  };

  const handleSaveClicked = async () => {
    console.log("save button clicked.");
    if (Object.keys(errors).length > 0) {
      return;
    }

    const currentLoginUser = await getCurrentLoggedInUserWithPromise();
    console.log("currentLoginUser:", currentLoginUser);

    const toSaveData = JSON.parse(JSON.stringify(editData));

    if (editData.id) {
      toSaveData["updated_by"] = currentLoginUser["sub"];
    } else {
      toSaveData["created_by"] = currentLoginUser["sub"];
    }

    console.log("save data:", toSaveData);

    setFullScreenLoading(true);
    const token = await getCurrentLoggedInSessionTokenWithPromise();
    api
      .post(GET_COMPANY_DETAIL, JSON.stringify(toSaveData), token)
      .then((res) => {
        console.log("save company result: ", res);
        setFullScreenLoading(false);
        if (res["status_code"] >= 200 && res["status_code"] < 300) {
          alert("Company is successfully saved.");
          navigate(COMPANY_MANAGEMENT_ROUTE);
        } else {
          alert("Unable to save the company.");
        }
      })
      .catch((err) => {
        console.log("save the company err", err);
        setFullScreenLoading(false);
      });
  };

  const handleHeaderCheckboxClicked = (row) => {
    console.log("header checkbox clicked.");
    const flag = headerCheckboxFlag ? false : true;
    console.log("flag:", flag);
    setHeaderCheckboxFlag(!headerCheckboxFlag);

    let members = [...companyMember];
    for (let i = 0; i < members.length; i++) {
      members[i].selected = flag;
    }
    setCompanyMember(members);

    let mtr = [...membersToRemove];
    let toRemove = flag ? members.map((x) => x.id) : [];
    console.log("toRemove:", toRemove);
    if (toRemove.length > 0) {
      mtr.push(...toRemove);
    } else {
      let mem = members.map((x) => x.id);
      console.log("mem:", mem);
      mtr = membersToRemove.filter((x) => mem.indexOf(x) < 0);
    }
    setMembersToRemove(mtr);
    console.log("membersToRemove:", mtr);
    console.log("companyMember:", members);
  };

  const handleRowCheckboxClicked = (row) => {
    console.log(
      "row clicked:",
      row,
      row.target.value,
      row.target.getAttribute("data-id")
    );
    console.log("company members:", companyMember);

    let _id = row.target.id;
    let _val = row.target.value;

    let members = [...companyMember];
    let flag = true;
    if (membersToRemove.indexOf(_id) > -1) {
      flag = false;
    }
    for (let i = 0; i < members.length; i++) {
      if (members[i].id == _id) {
        members[i].selected = flag;
      }
    }
    setCompanyMember(members);

    let toRemove = [...membersToRemove];
    if (flag) {
      toRemove.push(_id);
    } else {
      toRemove = toRemove.filter((x) => x !== _id);
    }
    console.log("toRemove:", toRemove);
    setMembersToRemove(toRemove);
  };

  const getCompanyDetail = async () => {
    const token = await getCurrentLoggedInSessionTokenWithPromise();
    const result = await api.get(
      GET_COMPANY_DETAIL,
      {
        id: id,
      },
      token
    );
    // const group_user = await api.get(
    //   GET_COMPANY_MEMBER_LIST,
    //   {
    //     id: id,
    //     page: 1,
    //     page_size: 10,
    //   },
    //   token
    // );
    console.log("company details results: ", result);
    // console.log("company members results: ", group_user);
    // if (group_user.status_code === 200) {
    //   console.log(group_user);
    //   setCompanyUser(group_user.data);
    //   const members = [];
    //   for (let i = 0; i < group_user.data.items.length; i++) {
    //     let company = [];
    //     for (let j = 0; j < group_user.data.items[i].company.length; j++) {
    //       company.push(group_user.data.items[i].company[j].company_name);
    //     }
    //     members.push({
    //       id: group_user.data.items[i].sub,
    //       item1: group_user.data.items[i].name,
    //       item2: company.join(", "),
    //       item3: group_user.data.items[i].package.join(", "),
    //       item4: group_user.data.items[i].role.replaceAll(",", ", "),
    //       item5: moment(group_user.data.items[i].joined_date).format(
    //         "DD MMM YYYY"
    //       ),
    //       selected:
    //         membersToRemove.indexOf(group_user.data.items[i].sub) > -1
    //           ? true
    //           : false,
    //     });
    //   }
    //   setCompanyMember([...members]);
    // }
    if (result.status_code === 200) {
      setEditData({
        ...editData,
        id: result.data.id,
        company_name: result.data.company_name,
        created_date: moment(result.data.created_date).format("DD MMM YYYY"),
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    console.log("companyList:", companyList);
    if (companyList.companyList && companyList.companyList.items.length > 0) {
      let _items = [];
      companyList.companyList.items.forEach((element) => {
        _items.push(element);
      });
      setAllCompanies(_items);
    }
  }, [companyList]);

  useEffect(() => {
    if (id) {
      dispatch(fetchALLCompany({ page: 1, page_size: 9999 }));
      getCompanyDetail();
    } else {
      setLoading(false);
    }
  }, [id]);

  useEffect(() => {
    const errs = validateInputs(editData);
    setErrors(errs);
  }, [editData]);

  const onToggleDialog = () => {
    setShowAddMemberDialog(false);
    setMembersToAdd([]);
    setMembersToRemove([]);
    setAddUser({});
    setActivePageAddMember(1);
    setHeaderAddMemberCheckboxFlag(false);
    setHeaderCheckboxFlag(false);
  };

  const handleAddMemberClicked = () => {
    setMembersToAdd([]);
    setAddUser({});
    setActivePageAddMember(1);
    setShowAddMemberDialog(true);
    setShowAddMemberLoading(true);
    getAddMembersList(activePageAddMember);
  };

  const getAddMembersList = async (page) => {
    const token = await getCurrentLoggedInSessionTokenWithPromise();
    api
      .get(
        GET_USER_LIST,
        {
          exclude_id: id,
          exclude_field: "company",
          page: page,
          page_size: 10,
        },
        token
      )
      .then((res) => {
        setShowAddMemberLoading(false);
        console.log("get excluded users:", res);
        setAddUser(res.data);
        const members = [];
        for (let i = 0; i < res.data.items.length; i++) {
          let company = [];
          for (let j = 0; j < res.data.items[i].company.length; j++) {
            let tmp = allCompanies.find(
              (x) => x.id === res.data.items[i].company[j]
            );
            if (tmp) {
              company.push(tmp.company_name);
            }
          }
          members.push({
            id: res.data.items[i].id,
            item1: res.data.items[i].name,
            item2: company.join(", "),
            item3: res.data.items[i].package.join(", "),
            item4: res.data.items[i].role.replaceAll(",", ", "),
            selected:
              membersToAdd.indexOf(res.data.items[i].id) > -1 ? true : false,
          });
        }
        setAddUserList(members);
      })
      .catch((err) => {
        console.log("get excluded users err", err);
        setShowAddMemberLoading(false);
      });
  };

  const handleAddMemberHeaderCheckboxClicked = (row) => {
    console.log("header checkbox clicked.");
    const flag = headerAddMemberCheckboxFlag ? false : true;
    console.log("flag:", flag);
    setHeaderAddMemberCheckboxFlag(!headerAddMemberCheckboxFlag);

    let members = [...addUserList];
    for (let i = 0; i < members.length; i++) {
      members[i].selected = flag;
    }
    setAddUserList(members);

    let mtr = [...membersToAdd];
    let toAdd = flag ? members.map((x) => x.id) : [];
    console.log("toAdd:", toAdd);
    if (toAdd.length > 0) {
      mtr.push(...toAdd);
    } else {
      let mem = members.map((x) => x.id);
      console.log("mem:", mem);
      mtr = membersToAdd.filter((x) => mem.indexOf(x) < 0);
    }
    setMembersToAdd(mtr);
    console.log("membersToAdd:", mtr);
    console.log("addUserList:", members);
  };

  const handleAddMemberRowCheckboxClicked = (row) => {
    console.log(
      "row clicked:",
      row,
      row.target.value,
      row.target.getAttribute("data-id")
    );
    console.log("add members:", addUserList);

    let _id = row.target.id;
    let _val = row.target.value;

    let members = [...addUserList];
    let flag = true;
    if (membersToAdd.indexOf(_id) > -1) {
      flag = false;
    }
    for (let i = 0; i < members.length; i++) {
      if (members[i].id == _id) {
        members[i].selected = flag;
      }
    }
    setAddUserList(members);

    let toAdd = [...membersToAdd];
    if (flag) {
      toAdd.push(_id);
    } else {
      toAdd = toAdd.filter((x) => x !== _id);
    }
    console.log("toAdd:", toAdd);
    setMembersToAdd(toAdd);
  };

  const handleAddMemberCancelClicked = () => {
    console.log("cancel button on add member dialog clicked.");
    setShowAddMemberDialog(false);
    setMembersToAdd([]);
    setMembersToRemove([]);
    setAddUser({});
    setActivePageAddMember(1);
    setHeaderAddMemberCheckboxFlag(false);
    setHeaderCheckboxFlag(false);
  };

  const handleAddMemberSaveClicked = async () => {
    console.log("save button on add member dialog clicked.");
    console.log("membersToAdd:", membersToAdd);

    if (membersToAdd.length == 0) {
      return;
    }

    const currentLoginUser = await getCurrentLoggedInUserWithPromise();
    console.log("currentLoginUser:", currentLoginUser);

    const toSaveData = {
      id,
      user_ids: membersToAdd,
      created_by: currentLoginUser["sub"],
    };
    console.log("add member data:", toSaveData);

    setFullScreenLoading(true);
    const token = await getCurrentLoggedInSessionTokenWithPromise();
    api
      .post(GET_COMPANY_MEMBER_LIST, JSON.stringify(toSaveData), token)
      .then((res) => {
        console.log("add member result: ", res);
        setFullScreenLoading(false);
        if (res["status_code"] >= 200 && res["status_code"] < 300) {
          alert("Company members added.");
          setShowAddMemberDialog(false);
          setAddUser([]);
          setMembersToAdd([]);
          setMembersToRemove([]);
          handlePageChange(1);
        } else {
          alert("Unable to add the company members.");
        }
      })
      .catch((err) => {
        console.log("add the company members err", err);
        setFullScreenLoading(false);
      });
  };

  const handleDeleteCompany = async () => {
    const token = await getCurrentLoggedInSessionTokenWithPromise();
    const currentLoginUser = await getCurrentLoggedInUserWithPromise();
    setLoading(true);
    setDeleteConfirm(false);
    api
      .remove(
        DELETE_COMPANY_ENDPOINT,
        JSON.stringify({
          id: id,
          // user_ids: memberIds,
          removed_by: currentLoginUser["sub"],
        }),
        token
      )
      .then((res) => {
        console.log(res);
        if (res.status_code === 200) {
          navigate(COMPANY_MANAGEMENT_ROUTE);
        }
      });
  };

  return (
    <>
      {showAddMemberDialog && (
        <Dialog>
          <div className="company-add-member-width_resize">
            <div
              style={{
                position: "absolute",
                top: "15px",
                right: "13px",
                cursor: "pointer",
              }}
              onClick={onToggleDialog}
            >
              <img src={CrossIcon} />
            </div>
            <div className="company-add-member-title">Add Member</div>
            {showAddMemberLoading ? (
              <Loader
                type="infinite-spinner"
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "50vw",
                  margin: "auto",
                  display: "flex",
                  justifyContent: "center",
                  height: "50vh",
                }}
              />
            ) : (
              <>
                <SelectTable
                  header={userListHeaders}
                  data={addUserList}
                  class={"minHeight"}
                  width={"1000px"}
                  maxHeight={"450px"}
                  cutHeaderHeight={false}
                  rowCheckboxClicked={handleAddMemberRowCheckboxClicked}
                  headerCheckboxFlag={headerCheckboxFlag}
                  headerCheckboxClicked={handleAddMemberHeaderCheckboxClicked}
                />
                {addUserList.length === 0 && (
                  <p className="Company_detail_main_box_desc">
                    No available users.
                  </p>
                )}
                <Pagination
                  pageCount={addUser && addUser.total_pages}
                  handlePageChange={handleAddMemberPageChange}
                  activePages={activePageAddMember}
                />
                <div class="Company_add_member_dialog_footer">
                  <div
                    className="Company_form_footer_cancel"
                    onClick={handleAddMemberCancelClicked}
                  >
                    Cancel
                  </div>
                  <div
                    className={
                      membersToAdd.length <= 0
                        ? "Company_form_footer_save disabled"
                        : "Company_form_footer_save "
                    }
                    onClick={handleAddMemberSaveClicked}
                  >
                    Submit
                  </div>
                </div>
              </>
            )}
          </div>
        </Dialog>
      )}
      {deleteConfirm && (
        <Dialog>
          <div style={{ position: "relative" }}>
            <div
              className="company-delete-close-button"
              onClick={() => setDeleteConfirm(false)}
            >
              X
            </div>
          </div>
          <div className="company-delete-main-box">
            <div className="company-delete-title">Delete !</div>
            <div className="company-delete-detail">
              Are you sure? Do you want to delete company?
            </div>
            <div className="company-delete-button-main-box">
              <div
                className="company-delete-button-confirm"
                onClick={handleDeleteCompany}
              >
                Yes
              </div>
              <div
                className="company-delete-button-cancel"
                onClick={() => setDeleteConfirm(false)}
              >
                Cancel
              </div>
            </div>
          </div>
        </Dialog>
      )}
      {fullScreenLoading && <FullscreenLoader />}
      {loading ? (
        <Loader
          type="infinite-spinner"
          style={{
            display: "flex",
            alignItems: "center",
            width: "40px",
            margin: "auto",
            display: "flex",
            justifyContent: "center",
            height: "100vh",
          }}
        />
      ) : (
        <div style={{ background: "white" }}>
          <div className="Company_form_toolbar">
            <div
              className="Company_form_toolbar_back_icon"
              onClick={() => navigate(COMPANY_MANAGEMENT_ROUTE)}
            >
              <img src={BackIcon} />
            </div>
            <div className="Company_form_toolbar_title">Company Details</div>
          </div>
          <div className="Company_form_sec_toolbar">
            <div
              className={
                tab === 0
                  ? "Company_form_sec_toolbar_item_active"
                  : "Company_form_sec_toolbar_item_unactive"
              }
              onClick={() => setTab(0)}
            >
              Details
            </div>
            {id && (
              <div
                className={
                  tab === 1
                    ? "Company_form_sec_toolbar_item_active"
                    : "Company_form_sec_toolbar_item_unactive"
                }
                onClick={() => {
                  setTab(1);
                  handlePageChange(1);
                }}
              >
                Member List
              </div>
            )}
            {tab === 1 && (
              <div
                className="Company_memberList_add_button"
                onClick={handleAddMemberClicked}
              >
                <div>+</div>
                <div>Add Member</div>
              </div>
            )}
          </div>
          {tab === 0 ? (
            <div className="Company_form_main_box">
              {id && (
                <div style={{ marginTop: "24px" }}>
                  <div className="Company_form_input_title">
                    Company created on
                  </div>
                  <div>
                    <Input
                      className="Company_form_input"
                      defaultValue={editData.created_date}
                      type="text"
                      disabled
                    />
                  </div>
                </div>
              )}
              <div style={{ marginTop: "24px" }}>
                <div className="Company_form_input_title">Company Name</div>
                <div>
                  <Input
                    className="Company_form_input_editable"
                    defaultValue={editData.company_name}
                    type="text"
                    onChange={(e) => {
                      setEditData({
                        ...editData,
                        company_name: e.target.value,
                      });
                    }}
                  />
                  {errors.company_name && <Error>{errors.company_name}</Error>}
                </div>
              </div>
            </div>
          ) : (
            <>
              {loading ? (
                <Loader
                  type="infinite-spinner"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "40px",
                    margin: "auto",
                    display: "flex",
                    justifyContent: "center",
                    height: "640px",
                  }}
                />
              ) : (
                <>
                  <SelectTable
                    header={header}
                    data={companyMember}
                    class={"minHeight"}
                    maxHeight={"381px"}
                    cutHeaderHeight={true}
                    rowCheckboxClicked={handleRowCheckboxClicked}
                    headerCheckboxFlag={headerCheckboxFlag}
                    headerCheckboxClicked={handleHeaderCheckboxClicked}
                  />
                  {companyMember.length === 0 && (
                    <p className="Company_detail_main_box_desc">
                      No available company members.
                    </p>
                  )}
                </>
              )}
              <Pagination
                pageCount={companyUser && companyUser.total_pages}
                handlePageChange={handlePageChange}
                activePages={activePages}
              />
            </>
          )}
          <div className="Company_form_footer">
            <div style={{ display: "flex", gap: "12px" }}></div>
            <div className="Company_form_footer_child_box">
              {id && (
                <div
                  className="Company_form_footer_audit_trail"
                  onClick={() =>
                    navigate(`${COMPANY_MANAGEMENT_AUDIT_TRAIL}?id=${id}`)
                  }
                >
                  View Audit Trail
                </div>
              )}
              <div
                className="Company_form_footer_cancel"
                onClick={handleCancelClicked}
              >
                Cancel
              </div>
              {tab === 0 && (
                <div
                  className={
                    Object.keys(errors).length > 0
                      ? "Company_form_footer_save disabled"
                      : "Company_form_footer_save "
                  }
                  onClick={handleSaveClicked}
                >
                  Save
                </div>
              )}
              {tab === 0 && (
                <div
                  className={
                    Object.keys(errors).length > 0
                      ? "Company_form_footer_save disabled"
                      : "Company_form_footer_save "
                  }
                  onClick={() => setDeleteConfirm(true)}
                >
                  Delete
                </div>
              )}
              {tab === 1 && (
                <div
                  className={
                    membersToRemove.length == 0
                      ? "Company_form_footer_remove disabled"
                      : "Company_form_footer_remove "
                  }
                  onClick={handleRemoveMemberClicked}
                >
                  Remove Member
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
