// redux
import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";

import api from "../config/api";
import config from "../config/config";
import { getCurrentLoggedInSessionTokenWithPromise } from "../config/userPool";

const { GET_MAPLAYER } = config.api_endpoint;

const initialState = {
  loading: true,
  mapLayerList: [],
  error: null,
};

export const resetMapLayerList = createAction("RESET_ALL");

export const fetchAllMapLayer = createAsyncThunk(
  "group/fetchAllMapLayer",
  async (_, { rejectWithValue }) => {
    try {
      const token = await getCurrentLoggedInSessionTokenWithPromise();
      const results = await api.post(
        GET_MAPLAYER,
        JSON.stringify({
          data_type: "MapLayers",
          statistics: {
            page: _.page || 1,
            pageSize: _.page_size || 10,
          },
        }),
        token
      );
      console.log(results);
      return {
        mapLayerList: results.data,
      };
    } catch (error) {
      return rejectWithValue(error.details);
    }
  }
);

const MapLayerSlice = createSlice({
  name: "maplayer",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllMapLayer.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAllMapLayer.fulfilled, (state, action) => {
        console.log(action);
        state.loading = false;
        state.mapLayerList = action.payload;
      })
      .addCase(fetchAllMapLayer.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(resetMapLayerList, () => initialState);
  },
});

const MapLayerReducer = MapLayerSlice.reducer;
export default MapLayerReducer;
