import { Input } from "@progress/kendo-react-inputs";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Delete from "../../../assets/images/Icon/delete.svg";
import "./hazards.css";
import { getCurrentLoggedInSessionTokenWithPromise } from "../../../config/userPool";
import api from "../../../config/api";
import { Loader } from "@progress/kendo-react-all";

export const HazardsManagement = () => {
  // const { general_config_data } = useSelector((state) => state.generalConfig);
  // const { EDIT_PACKAGE } = config.api_endpoint;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [all_hazards, setAll_hazards] = useState([]);
  const [editMode, setEditMode] = useState(false);

  const filterHazards = (value, id) => {
    all_hazards.splice(id, 1);
    setAll_hazards([...all_hazards]);
  };

  const editHazards = (value, id) => {
    all_hazards[id] = value;
    setAll_hazards([...all_hazards]);
  };

  const callAPI = async () => {
    console.log([
      ...all_hazards
        .filter((i) => i !== "Others")
        .sort((a, b) => (a.toLowerCase() > b.toLowerCase() ? 1 : -1)),
      ,
      "Others",
    ]);
    setLoading(true);
    const token = await getCurrentLoggedInSessionTokenWithPromise();
    api
      .put(
        "/api-admin/update-selection-list-partial",
        JSON.stringify({
          Hazards: [
            ...all_hazards
              .filter((i) => i !== "Others")
              .filter((j) => j !== null)
              .sort((a, b) => (a.toLowerCase() > b.toLowerCase() ? 1 : -1)),
            "Others",
          ],
        }),
        token
      )
      .then((res) => {
        if (res.status_code === 200) {
          console.log(res);
          setAll_hazards([...res.Hazards]);
          setLoading(false);
        }
      });
    setEditMode(false);
  };

  const handleEdit = () => {
    if (editMode) {
      callAPI();
    }
    setEditMode(!editMode);
  };

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      const token = await getCurrentLoggedInSessionTokenWithPromise();
      api.get("/api-admin/get-selection-list", {}, token).then((res) => {
        console.log(res);
        if (res.Hazards && res.Hazards.length > 0) {
          if (res.Hazards) {
            setAll_hazards(res.Hazards);
            setLoading(false);
          }
        } else {
          setLoading(false);
          alert("Error getting hazards");
        }
      });
    };
    getData();
  }, []);

  return (
    <>
      {loading ? (
        <Loader
          type="infinite-spinner"
          style={{
            height: "80vh",
            display: "flex",
            alignItems: "center",
            width: "40px",
            margin: "auto",
            display: "flex",
            justifyContent: "center",
            minHeight: "100%",
          }}
        />
      ) : (
        <div className="hazards_main_box">
          <div className="hazards_title">Hazards</div>
          <div className="hazards_child_box">
            {all_hazards.map((i, id) => {
              if (editMode && i === "Others") {
                return "";
              } else {
                return (
                  <div key={id} className="hazards_input_box">
                    <Input
                      style={{ borderRadius: "8px" }}
                      value={i}
                      disabled={editMode ? false : true}
                      onChange={(e) => editHazards(e.target.value, id)}
                    />
                    {editMode && (
                      <div
                        className="hazards_delete_icon"
                        onClick={() => filterHazards(i, id)}
                      >
                        <img src={Delete} />
                      </div>
                    )}
                  </div>
                );
              }
            })}
          </div>
          <div style={{ position: "relative" }}>
            <div className="hazards_footer_box">
              {editMode && (
                <div
                  className="hazards_footer_button hazards_footer_add_button"
                  onClick={() => setAll_hazards([...all_hazards, ""])}
                >
                  Add
                </div>
              )}
              <div
                className="hazards_footer_button hazards_footer_edit_button"
                onClick={handleEdit}
                style={{
                  pointerEvents:
                    editMode && all_hazards.find((i) => i === "") !== undefined
                      ? "none"
                      : "",
                  opacity:
                    editMode && all_hazards.find((i) => i === "") !== undefined
                      ? "0.5"
                      : "",
                }}
              >
                {editMode ? "Save" : "Edit"}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
