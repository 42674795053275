import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
// react
import { Input } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
// api
import api from "../../config/api";
import config from "../../config/config";
// component
import FullscreenLoader from "../../components/fullScreenLoader";
// css
import "./auth.css";

export const ForgotPassword = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { FORGOT_PASSWORD } = config.api_endpoint;
  const [email, setEmail] = useState("");
  const [errorText, setErrorText] = useState(null);
  const [popupMsg, setPopupMsg] = useState("");
  const [open, setOpen] = useState(false);

  const handleResetPassword = () => {
    console.log("Reset Password Clicked.");
    setErrorText(null);

    if (!email) {
      setErrorText("Please enter all the required information");
    } else {
      setLoading(true);
      api
        .post(FORGOT_PASSWORD, JSON.stringify({ username: email }), null)
        .then((res) => {
          console.log("reset password result", res);
          setLoading(false);

          if (res["status_code"] >= 200 && res["status_code"] < 300) {
            setPopupMsg(
              "Successfully sent the reset password request. Please check your email inbox for further instructions."
            );
            setOpen(true);
            setTimeout(() => {
              setOpen(false);
              navigate("/");
            }, 3000);
          } else {
            setPopupMsg("Unable to send the reset password request.");
            setOpen(true);
            setTimeout(() => {
              setOpen(false);
            }, 3000);
          }
        })
        .catch((err) => {
          console.log("reset password err", err);
          setLoading(false);
          setPopupMsg("Error encountered.");
          setOpen(true);
          setTimeout(() => {
            setOpen(false);
          }, 3000);
        });
    }
  };

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      {loading && <FullscreenLoader />}
      {open && (
        <Dialog title={""} onClose={() => setOpen(false)}>
          <div
            style={{
              textAlign: "center",
              marginLeft: "30px",
              marginRight: 30,
            }}
          >
            <h4>{popupMsg}</h4>
            <p>
              <button
                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                style={{ width: "300px" }}
                onClick={() => setOpen(false)}
              >
                Close
              </button>
            </p>
          </div>
        </Dialog>
      )}
      <div className="forgot_media_screen">
        <div
          style={{ padding: "0px 16px", marginTop: "26px" }}
          className="forgot_detail_box"
        >
          <p className="Forgot_text">
            Enter your e-mail address below, and we'll e-mail instructions for
            setting a new one
          </p>
          <div className="mb-3">
            <p className="input_label">Email</p>
            <Input
              name="email"
              style={{
                width: "100%",
                borderRadius: "8px",
                border: "1px solid #E4E1DE",
              }}
              placeholder="Enter your email here"
              pattern={"[A-Za-z]+"}
              minLength={2}
              required={true}
              value={email}
              onChange={(e) => setEmail(e.target.value.replaceAll(" ", ""))}
            />
          </div>
        </div>
        {errorText && <p className="error_text">{errorText}</p>}
        <div className="Button_group" style={{ justifyContent: "center" }}>
          <Button className="Button_submit" onClick={handleResetPassword}>
            Reset My Password
          </Button>
        </div>
      </div>
    </div>
  );
};
