export const LOGIN_ROUTE = "/";

export const USER_MANAGEMENT_ROUTE = "/user";
export const USER_MANAGEMENT_CREATE_ROUTE = "/user/create";
export const USER_MANAGEMENT_DETAIL_ROUTE = "/user/details";
export const USER_MANAGEMENT_AUDIT_TRAIL = "/user/auditTrail";
export const GROUP_MANAGEMENT_ROUTE = "/group";
export const GROUP_MANAGEMENT_CREATE_ROUTE = "/group/create";
export const GROUP_MANAGEMENT_AUDIT_TRAIL = "/group/auditTrail";
export const GROUP_MANAGEMENT_DETAIL_ROUTE = "/group/details";
export const COMPANY_MANAGEMENT_ROUTE = "/company";
export const COMPANY_MANAGEMENT_CREATE_ROUTE = "/company/create";
export const COMPANY_MANAGEMENT_DETAIL_ROUTE = "/company/details";
export const COMPANY_MANAGEMENT_AUDIT_TRAIL = "/company/auditTrail";
export const APPROVAL_MANAGEMENT_ROUTE = "/approval";
export const APPROVAL_MANAGEMENT_DETAIL_ROUTE = "/approval/details";
export const APPROVAL_MANAGEMENT_AUDIT_TRAIL = "/approval/auditTrail";
export const FORGOT_PASSWORD_ROUTE = "/forgot_password";
export const RESET_PASSWORD_ROUTE = "/reset_password";
export const CHANGE_PASSWORD_ROUTE = "/change_password";
export const PACKAGE_MANAGMENT = "/package";
export const CCTV_MANAGMENT = "/cctv";
export const CCTV_CREATE_ROUTE = "/cctv/create";
export const CCTV_DETAIL_ROUTE = "/cctv/deatils";
export const CCTV_AUDIT_TRAIL = "/cctv/auditTrail";
export const ECM_MANAGMENT = "/ecm";
export const ECM_CREATE_ROUTE = "/ecm/create";
export const ECM_DETAIL_ROUTE = "/ecm/deatils";
export const ECM_AUDIT_TRAIL = "/ecm/auditTrail";
export const MAP_LAYER_MANAGMENT = "/mapLayer";
export const MAP_LAYER_CREATE_ROUTE = "/mapLayer/create";
export const MAP_LAYER_DETAIL_ROUTE = "/mapLayer/details";
export const MAP_LAYER_AUDIT_TRAIL = "/mapLayer/auditTrail";
export const OHS_PAGE = "/ohs_developer_excel_upload";
export const OHS_Management = "/ohs";
export const OHS_Management_WORK_ACTIVITY = "/ohs/type_of_work_activity";
export const OHS_Management_HAZARDS = "/ohs/hazards";
export const OHS_Management_POTENTIAL = "/ohs/potential_consequences";
