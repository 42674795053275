// redux
import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";

import api from "../config/api";
import config from "../config/config";
import { getCurrentLoggedInSessionTokenWithPromise } from "../config/userPool";

const { GET_GROUP_LIST } = config.api_endpoint;

const initialState = {
  loading: true,
  groupList: [],
  error: null,
};

export const resetGroupList = createAction("RESET_ALL");

export const fetchALLGroup = createAsyncThunk(
  "group/fetchALLGroup",
  async (_, { rejectWithValue }) => {
    try {
      const token = await getCurrentLoggedInSessionTokenWithPromise();
      const results = await api.get(
        GET_GROUP_LIST,
        {
          page: _.page || 1,
          page_size: _.page_size || 10,
          start_created_date: _.start_created_date || "",
          end_created_date: _.end_created_date || "",
          text: _.text || "",
        },
        token
      );
      console.log(results);
      return {
        groupList: results.data,
      };
    } catch (error) {
      return rejectWithValue(error.details);
    }
  }
);

const GroupSlice = createSlice({
  name: "group",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchALLGroup.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchALLGroup.fulfilled, (state, action) => {
        console.log(action);
        state.loading = false;
        state.groupList = action.payload;
      })
      .addCase(fetchALLGroup.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(resetGroupList, () => initialState);
  },
});

const GroupReducer = GroupSlice.reducer;
export default GroupReducer;
