import { React, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { TextBox, InputSuffix } from "@progress/kendo-react-inputs";
import CalendarIcon from "../../assets/images/Icon/Calendar_white.svg";
import moment from "moment/moment";
import "./datePicker.css";

export const CustomDatePicker = (props) => {
  console.log(props);
  const [showPicker, setShowPicker] = useState(false);

  const onChangeValue = (e) => {
    props.onChange(e);
    setShowPicker(!showPicker);
  };
  return (
    <>
      <TextBox
        className={props.cctv ? "cctvDatePicker" : "sitrepDatePicker"}
        value={moment(props.value).format(props.format)}
        disabled={props.disabled && true}
        suffix={() => (
          <>
            <InputSuffix
              onClick={() => !props.disabled && setShowPicker(!showPicker)}
              style={{ cursor: props.disabled ? "" : "pointer" }}
            >
              <img src={CalendarIcon} className="color_change" />
            </InputSuffix>
          </>
        )}
      />
      {showPicker && (
        <div
          style={{
            height: "260px",
            width: "240px",
            zIndex: 11,
            position: "relative",
          }}
        >
          <div className="float-datePicker">
            <DatePicker
              calendarClassName="resizing"
              showYearDropdown
              dateFormatCalendar="MMMM"
              yearDropdownItemNumber={15}
              scrollableYearDropdown
              selected={props.value}
              minDate={props.MinDate && props.MinDate}
              maxDate={props.MaxDate && props.MaxDate}
              onChange={(e) => onChangeValue(e)}
              onClickOutside={() => setShowPicker(false)}
              inline
            />
          </div>
        </div>
      )}
    </>
  );
};
