import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import OtpInput from "react-otp-input";
import validator from "validator";
//logo
import BackIcon from "../../assets/images/Icon/PreviousIcon.svg";
import CrossIcon from "../../assets/images/Icon/cross_icon.svg";
import hide from "../../assets/images/Icon/hide.svg";
import unhide from "../../assets/images/Icon/unhide.svg";
import EditIcon from "../../assets/images/usermanagement/EditIcon.svg";
import AddIcon from "../../assets/images/usermanagement/AddImage.svg";
import NoImage from "../../assets/images/usermanagement/NoImage.png";

import ConfirmMsg from "../../components/confirmMsg";

//kendo
import { Input } from "@progress/kendo-react-inputs";
import { Label, Error } from "@progress/kendo-react-labels";
import { Dialog } from "@progress/kendo-react-dialogs";
//roter
import {
  USER_MANAGEMENT_AUDIT_TRAIL,
  USER_MANAGEMENT_ROUTE,
} from "../../routes";
import FullscreenLoader from "../../components/fullScreenLoader";
//config
import api from "../../config/api";
import config from "../../config/config";
import {
  getCurrentLoggedInSessionTokenWithPromise,
  getCurrentLoggedInUserWithPromise,
} from "../../config/userPool";
import { DropDownList, Loader } from "@progress/kendo-react-all";
import { fetchALLCompany } from "../../store/companyList";
import { fetchALLGroup } from "../../store/groupList";
import moment from "moment";
// css
import "./user.css";

export const UserManagement_Form = () => {
  const { general_config_data } = useSelector((state) => state.generalConfig);
  const uploadRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { search } = useLocation();
  const query = new URLSearchParams(search);
  const [editMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(true);
  const [fullScreenLoading, setFullScreenLoading] = useState(false);
  const [passwordHS, setPasswordHS] = useState({
    new: false,
    confirm: false,
  });
  const [tab, setTab] = useState(0);
  const [companyDropdown, setCompanyDropdown] = useState([]);
  const [groupDropdownWithId, setGroupDropdownWithId] = useState([]);
  const [groupDropdown, setGroupDropdown] = useState([]);
  const departmentDropdown = [
    ...general_config_data.general_config_data.department,
  ];
  const [disableEdit, setDisableEdit] = useState(false);
  const [data, setData] = useState({
    name: "",
    email: "",
    phone_number: "",
    company: "",
    designation: "",
    department: "",
    package: [],
    role: "",
  });
  const [editData, setEditData] = useState({
    name: "",
    email: "",
    phone_number: "",
    company: [],
    designation: "",
    department: "",
    package: [],
    role: "",
    user_group: [],
  });
  const [errors, setErrors] = useState({});
  const [showOTPDialog, setShowOTPDialog] = useState(false);
  const [correctOTP, setCorrectOTP] = useState("");
  const [otp, setOTP] = useState("");
  const [otpError, setOTPError] = useState("");

  const { GET_USER_DETAIL, GENERATE_USER_EDIT_OTP } = config.api_endpoint;
  const { groupList } = useSelector((state) => state.groupList);
  const { companyList } = useSelector((state) => state.companyList);
  const id = query.get("id");
  const mandatoryFields = [
    "name",
    "email",
    "phone_number",
    "company",
    "user_group",
    "designation",
    //    "department",
    "package",
  ];

  const [showSaveConfirmDialog, setShowSaveConfirmDialog] = useState(false);

  const onCloseOTPDialog = () => {
    setShowOTPDialog(false);
  };

  const getUserDetail = async () => {
    const token = await getCurrentLoggedInSessionTokenWithPromise();
    const result = await api.get(
      GET_USER_DETAIL,
      {
        id: id,
      },
      token
    );
    console.log(result);
    if (result.status_code === 200) {
      setEditData({
        id: result.data.id,
        name: result.data.name,
        email: result.data.email,
        phone_number: result.data.phone_number,
        company:
          result.data.company.length > 0 ? [result.data.company[0].id] : [],
        user_group: result.data.user_group.map((x) => x.id),
        designation: result.data.designation,
        department: result.data.department,
        other_department: result.data.other_department || "",
        package: result.data.package,
        role: result.data.role,
        joined_date: moment(result.data.joined_date).format("DD MMM YYYY"),
        last_login:
          result.data.last_login !== "" &&
          moment(result.data.last_login).format("DD MMM YYYY"),
        password_expired_at:
          result.data.password_expired_at &&
          result.data.password_expired_at.indexOf("9999") > -1
            ? "User is using CAG Login"
            : result.data.password_expired_at
            ? moment(result.data.password_expired_at).format(
                "DD MMM YYYY hh:mm A"
              )
            : result.data.password_expired_at,
        status: result.data.status,
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    if (groupList.groupList && groupList.groupList.items.length > 0) {
      setGroupDropdown(groupList.groupList.items);
    }

    if (companyList.companyList && companyList.companyList.items.length > 0) {
      setCompanyDropdown(companyList.companyList.items);
    }
  }, [companyList, groupList]);

  useEffect(() => {
    dispatch(fetchALLCompany({ page: 1, page_size: 9999 }));
    dispatch(fetchALLGroup({ page: 1, page_size: 9999 }));
    if (id) {
      getUserDetail();
      setDisableEdit(true);
    } else {
      setLoading(false);
      handleSaveClicked();
    }
  }, [id]);

  useEffect(() => {
    const errs = validateInputs(editData);
    setErrors(errs);
  }, [editData]);

  const validateInputs = (data) => {
    console.log("calling");
    const _errors = {};
    mandatoryFields.forEach((_field) => {
      if (["company", "user_group", "package"].indexOf(_field) > -1) {
        if (data[_field].length === 0) {
          _errors[_field] = "This field is required.";
        }
      } else {
        if (!data[_field]) {
          _errors[_field] = "This field is required.";
        }
      }
    });
    if (data["phone_number"]) {
      if (/\+65(6|8|9)\d{7}$/.test(data["phone_number"]) == false) {
        _errors["phone_number"] =
          "Please put in +65(9/8/6) followed by 7 digits.";
      }
    }
    if (data["designation"]) {
      if (data["designation"].match("^[a-zA-Z ]*$") === null) {
        _errors["designation"] =
          "Your designation should contain only letters.";
      }
    }

    if (data["department"]) {
      if (data["department"] === "Others" && !data["other_department"]) {
        _errors["other_department"] = "This field is required.";
      }
    }
    if (data["password"] && data["new_password"]) {
      if (data["password"] != data["new_password"]) {
        _errors["password"] = "Passwords do not match.";
        _errors["new_password"] = "Passwords do not match.";
      } else {
        if (
          !validator.isStrongPassword(data["password"], {
            minLength: 8,
            minLowercase: 1,
            minUppercase: 1,
            minNumbers: 1,
            minSymbols: 1,
          })
        ) {
          _errors["password"] =
            "Please enter at least 8 chars of a mix of lower/upper case letters, numbers and special chars";
        }

        if (
          !validator.isStrongPassword(data["new_password"], {
            minLength: 8,
            minLowercase: 1,
            minUppercase: 1,
            minNumbers: 1,
            minSymbols: 1,
          })
        ) {
          _errors["new_password"] =
            "Please enter at least 8 chars of a mix of lower/upper case letters, numbers and special chars";
        }
      }
    } else if (data["password"] && !data["new_password"]) {
      if (
        !validator.isStrongPassword(data["password"], {
          minLength: 8,
          minLowercase: 1,
          minUppercase: 1,
          minNumbers: 1,
          minSymbols: 1,
        })
      ) {
        _errors["password"] =
          "Please enter at least 8 chars of a mix of lower/upper case letters, numbers and special chars";
      }
      _errors["new_password"] = "This field is required.";
    } else if (!data["password"] && data["new_password"]) {
      if (
        !validator.isStrongPassword(data["new_password"], {
          minLength: 8,
          minLowercase: 1,
          minUppercase: 1,
          minNumbers: 1,
          minSymbols: 1,
        })
      ) {
        _errors["new_password"] =
          "Please enter at least 8 chars of a mix of lower/upper case letters, numbers and special chars";
      }
      _errors["password"] = "This field is required.";
    } else if (data["password"]) {
      if (
        !validator.isStrongPassword(data["password"], {
          minLength: 8,
          minLowercase: 1,
          minUppercase: 1,
          minNumbers: 1,
          minSymbols: 1,
        })
      ) {
        _errors["password"] =
          "Please enter at least 8 chars of a mix of lower/upper case letters, numbers and special chars";
      }
    } else if (data["new_password"]) {
      if (
        !validator.isStrongPassword(data["new_password"], {
          minLength: 8,
          minLowercase: 1,
          minUppercase: 1,
          minNumbers: 1,
          minSymbols: 1,
        })
      ) {
        _errors["new_password"] =
          "Please enter at least 8 chars of a mix of lower/upper case letters, numbers and special chars";
      }
    }

    return _errors;
  };

  const handleOTPResendClicked = async () => {
    console.log("otp resend clicked.");
    setOTP("");
    setFullScreenLoading(true);
    const currentLoginUser = await getCurrentLoggedInUserWithPromise();
    const token = await getCurrentLoggedInSessionTokenWithPromise();
    let toSaveData = {
      username: editData.email,
      edited_by: currentLoginUser.email,
    };
    const results = await api.post(
      GENERATE_USER_EDIT_OTP,
      JSON.stringify(toSaveData),
      token
    );
    console.log("Generate otp res:", results);
    if (results.status_code !== 200) {
      setFullScreenLoading(false);
      alert(results.details);
    } else {
      setCorrectOTP(results.data.otp);
      setOTPError("");
      setFullScreenLoading(false);
    }
  };

  const handleOTPConfirmClicked = () => {
    console.log("otp confirm clicked.");
    setOTPError("");
    if (otp === correctOTP) {
      setShowOTPDialog(false);
      setEditMode(true);
    } else {
      setOTPError("OTP is incorrect.");
    }
  };

  const handleSaveClicked = async () => {
    console.log("save button clicked.");
    // const errs = validateInputs(editData);
    // setErrors(errs);
    // console.log(errs);
    if (Object.keys(errors).length > 0) {
      if (id) return;
    }
    if (!editMode) {
      // setEditMode(true);
      setShowSaveConfirmDialog(false);
      setFullScreenLoading(true);
      const currentLoginUser = await getCurrentLoggedInUserWithPromise();
      console.log("currentLoginUser:", currentLoginUser);
      const token = await getCurrentLoggedInSessionTokenWithPromise();
      let toSaveData = {
        username: editData.email,
        edited_by: currentLoginUser.email,
      };
      const results = await api.post(
        GENERATE_USER_EDIT_OTP,
        JSON.stringify(toSaveData),
        token
      );
      console.log("Generate otp res:", results);
      if (results.status_code !== 200) {
        setFullScreenLoading(false);
        alert(results.details);
      } else {
        setCorrectOTP(results.data.otp);
        setFullScreenLoading(false);
        setOTPError("");
        setShowOTPDialog(true);
      }

      return;
    } else {
      if (Object.keys(errors).length > 0) {
        return;
      }
      const currentLoginUser = await getCurrentLoggedInUserWithPromise();
      console.log("currentLoginUser:", currentLoginUser);

      const toSaveData = JSON.parse(JSON.stringify(editData));

      if (editData.id) {
        toSaveData["updated_by"] = currentLoginUser["sub"];
      } else {
        toSaveData["created_by"] = currentLoginUser["sub"];
      }

      console.log("save data:", toSaveData);

      setFullScreenLoading(true);
      const token = await getCurrentLoggedInSessionTokenWithPromise();
      api
        .post(GET_USER_DETAIL, JSON.stringify(toSaveData), token)
        .then((res) => {
          console.log("save user result: ", res);
          setFullScreenLoading(false);
          if (res["status_code"] >= 200 && res["status_code"] < 300) {
            alert("User profile is successfully saved.");
            navigate(USER_MANAGEMENT_ROUTE);
          } else {
            let errMsg = "Unable to save the user profile.";
            if (res["details"] === "failed") {
              errMsg = res["data"];
              if (errMsg.indexOf("operation:") > -1) {
                errMsg = errMsg.split("operation: ")[1];
              }
            }
            alert(errMsg);
          }
        })
        .catch((err) => {
          console.log("save the user profile err", err);
          setFullScreenLoading(false);
        });
    }
  };

  const handleCancelClicked = () => {
    console.log("cancel button clicked.");
    navigate(-1);
  };

  const user_group_placeholder = { id: "Select", user_group_name: "Select" };

  return (
    <>
      {fullScreenLoading && <FullscreenLoader />}
      {loading ? (
        <Loader
          type="infinite-spinner"
          style={{
            display: "flex",
            alignItems: "center",
            width: "40px",
            margin: "auto",
            display: "flex",
            justifyContent: "center",
            height: "100vh",
          }}
        />
      ) : (
        <div style={{ background: "#FFFFFF" }}>
          {showOTPDialog && (
            <Dialog>
              <div className="user-otp-dialog">
                <div
                  style={{
                    position: "absolute",
                    top: "15px",
                    right: "13px",
                    cursor: "pointer",
                  }}
                  onClick={onCloseOTPDialog}
                >
                  <img src={CrossIcon} />
                </div>
                <div className="user-otp-content-wrapper">
                  <div className="user-otp-dialog-title">
                    OTP For User Profile Edit
                  </div>
                  <OtpInput
                    containerStyle="user-otp-otp-parent"
                    value={otp}
                    onChange={setOTP}
                    numInputs={6}
                    renderSeparator={<span></span>}
                    renderInput={(props) => <input {...props} />}
                    inputStyle={"user-otp-otp-input"}
                    inputType={"number"}
                  />
                  {otpError && (
                    <div className="user-otp-error-wrapper">
                      <p className="user-otp-error-text">{otpError}</p>
                      <p
                        className="user-otp-resend-link"
                        onClick={handleOTPResendClicked}
                      >
                        Resend OTP
                      </p>
                    </div>
                  )}
                  <div className="user-otp-button-group">
                    <div
                      className="UserManagement_form_footer_cancel"
                      onClick={() => setShowOTPDialog(false)}
                    >
                      Cancel
                    </div>
                    <div
                      className="UserManagement_form_footer_save"
                      onClick={handleOTPConfirmClicked}
                    >
                      Confirm
                    </div>
                  </div>
                </div>
              </div>
            </Dialog>
          )}
          {showSaveConfirmDialog && (
            <ConfirmMsg
              onCloseDialog={() => {
                setShowSaveConfirmDialog(false);
              }}
              msgTitle={`Are you sure you really want to change the user details?`}
              okBtnMsg={"Yes, proceed"}
              okBtnClicked={handleSaveClicked}
              cancelBtnMsg={"No, cancel"}
              cancelBtnClicked={() => {
                setShowSaveConfirmDialog(false);
              }}
            />
          )}

          <div className="UserManagement_form_toolbar">
            <div
              className="UserManagement_form_toolbar_back_icon"
              onClick={() => navigate(USER_MANAGEMENT_ROUTE)}
            >
              <img src={BackIcon} />
            </div>
            <div className="UserManagement_form_toolbar_title">
              User Details
            </div>
          </div>
          <div className="UserManagement_form_sec_toolbar">
            <div
              className={
                tab === 0
                  ? "UserManagement_form_sec_toolbar_item_active"
                  : "UserManagement_form_sec_toolbar_item_unactive"
              }
              onClick={() => setTab(0)}
            >
              Profile
            </div>
            {id && (
              <div
                className={
                  tab === 1
                    ? "UserManagement_form_sec_toolbar_item_active"
                    : "UserManagement_form_sec_toolbar_item_unactive"
                }
                onClick={() => setTab(1)}
              >
                Account
              </div>
            )}
          </div>
          <div
            style={{ overflow: "scroll", height: "calc(100vh - 303px)" }}
            className="user_scroll"
          >
            {tab === 0 ? (
              <div className="UserMgmt_main_box">
                <div style={{ marginBottom: "40px" }}></div>
                <div className="UserMgmt_detail_main">
                  <div className="UserMgmt_detail_main_box">
                    <div className="UserMgmt_detail_main_box_title">Name</div>
                    {/* {editMode ? ( */}
                    <Input
                      className="UserMgmt_detail_main_box_desc_input"
                      value={editData.name}
                      onChange={(e) => {
                        setEditData({
                          ...editData,
                          name: e.target.value,
                        });
                      }}
                      disabled={editMode ? false : true}
                    />
                    {errors.name && <Error>{errors.name}</Error>}
                  </div>
                  <div className="UserMgmt_detail_main_box">
                    <div className="UserMgmt_detail_main_box_title">
                      Business Email
                    </div>
                    <Input
                      className={
                        disableEdit
                          ? "UserMgmt_detail_main_box_desc_input disabled"
                          : "UserMgmt_detail_main_box_desc_input"
                      }
                      type="email"
                      value={editData.email}
                      onChange={(e) => {
                        setEditData({
                          ...editData,
                          email: e.target.value,
                        });
                      }}
                      disabled={editMode ? false : true}
                      readOnly={disableEdit ? "readonly" : ""}
                    />
                    {errors.email && <Error>{errors.email}</Error>}
                  </div>
                  <div className="UserMgmt_detail_main_box">
                    <div className="UserMgmt_detail_main_box_title">
                      Business Contact Number
                    </div>
                    <Input
                      className="UserMgmt_detail_main_box_desc_input"
                      type="tel"
                      value={editData.phone_number}
                      onChange={(e) => {
                        setEditData({
                          ...editData,
                          phone_number: e.target.value,
                        });
                      }}
                      disabled={editMode ? false : true}
                    />
                    {errors.phone_number && (
                      <Error>{errors.phone_number}</Error>
                    )}
                  </div>
                  <div className="UserMgmt_detail_main_box">
                    <div className="UserMgmt_detail_main_box_title">
                      Company
                    </div>
                    <DropDownList
                      data={companyDropdown}
                      // label={"Please choose a package."}
                      defaultValue={
                        editData.company.length === 1
                          ? companyDropdown.find(
                              (x) => x.id === editData.company[0]
                            )
                          : ""
                      }
                      textField="company_name"
                      dataItemKey="id"
                      onChange={(e) => {
                        setEditData({
                          ...editData,
                          company: [e.target.value.id],
                        });
                      }}
                      disabled={editMode ? false : true}
                    />
                    {errors.company && <Error>{errors.company}</Error>}
                  </div>
                  <div className="UserMgmt_detail_main_box">
                    <div className="UserMgmt_detail_main_box_title">
                      Designation
                    </div>{" "}
                    <Input
                      className="UserMgmt_detail_main_box_desc_input"
                      value={editData.designation}
                      onChange={(e) => {
                        setEditData({
                          ...editData,
                          designation: e.target.value,
                        });
                      }}
                      disabled={editMode ? false : true}
                    />
                    {errors.designation && <Error>{errors.designation}</Error>}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div className="UserMgmt_detail_main_box">
                      <div className="UserMgmt_detail_main_box_title">
                        Department
                      </div>{" "}
                      <DropDownList
                        data={departmentDropdown}
                        value={editData.department}
                        onChange={(e) => {
                          setEditData({
                            ...editData,
                            department: e.target.value,
                          });
                        }}
                        disabled={editMode ? false : true}
                      />
                      {errors.department && <Error>{errors.department}</Error>}
                      {!errors.department && errors.other_department && (
                        <div style={{ height: "19px" }}></div>
                      )}
                    </div>
                    <div className="UserMgmt_detail_main_box">
                      <div className="UserMgmt_detail_main_box_title">
                        Other Department
                      </div>{" "}
                      <Input
                        className="UserMgmt_detail_main_box_desc_input"
                        value={editData.other_department}
                        onChange={(e) => {
                          setEditData({
                            ...editData,
                            other_department: e.target.value,
                          });
                        }}
                        disabled={editMode ? false : true}
                      />
                      {errors.other_department && (
                        <Error>{errors.other_department}</Error>
                      )}
                      {errors.department && !errors.other_department && (
                        <div style={{ height: "19px" }}></div>
                      )}
                    </div>
                  </div>
                  <div className="UserMgmt_detail_main_box">
                    <div className="UserMgmt_detail_main_box_title">
                      Package
                    </div>
                    <DropDownList
                      data={[
                        "All",
                        ...general_config_data.general_config_data.package,
                      ]}
                      value={"Select"}
                      onChange={(e) => {
                        if (editData.package.indexOf(e.target.value) === -1) {
                          editData.package.push(e.target.value);
                          setEditData({ ...editData });
                        }
                      }}
                      disabled={editMode ? false : true}
                    />
                    <div style={{}}>
                      {editData.package.map((da, id) => (
                        <div className="UserManagement_package_box" key={id}>
                          <div className="UserManagement_package_sec_box">
                            {da}
                          </div>
                          <div
                            style={{
                              verticalAlign: "middle",
                              height: "17px",
                              cursor: "pointer",
                              display: "inline-block",
                            }}
                            onClick={() => {
                              const filter = editData.package.filter(
                                (data) => data !== da
                              );
                              setEditData({ ...editData, package: filter });
                            }}
                          >
                            {editMode && <img src={CrossIcon} />}
                          </div>
                        </div>
                      ))}
                    </div>
                    {errors.package && <Error>{errors.package}</Error>}
                  </div>
                  <div className="UserMgmt_detail_main_box">
                    <div className="UserMgmt_detail_main_box_title">Role</div>
                    <DropDownList
                      data={groupDropdown}
                      value={user_group_placeholder}
                      textField="user_group_name"
                      dataItemKey="id"
                      onChange={(e) => {
                        if (
                          editData.user_group.indexOf(e.target.value.id) === -1
                        ) {
                          editData.user_group.push(e.target.value.id);
                          setEditData({ ...editData });
                        }
                      }}
                      disabled={editMode ? false : true}
                    />
                    <div style={{}}>
                      {editData.user_group.map((da, id) => (
                        <div className="UserManagement_package_box" key={id}>
                          <div className="UserManagement_package_sec_box">
                            {
                              groupDropdown.find((x) => da === x.id)
                                .user_group_name
                            }
                          </div>
                          <div
                            style={{
                              verticalAlign: "middle",
                              height: "17px",
                              cursor: "pointer",
                              display: "inline-block",
                            }}
                            onClick={() => {
                              const filter = editData.user_group.filter(
                                (data) => data !== da
                              );
                              setEditData({
                                ...editData,
                                user_group: filter,
                              });
                            }}
                          >
                            {editMode && <img src={CrossIcon} />}
                          </div>
                        </div>
                      ))}
                    </div>
                    {errors.user_group && <Error>{errors.user_group}</Error>}
                  </div>
                </div>
              </div>
            ) : (
              <div className="Usermanagement_form_account_main_box">
                <div style={{ marginTop: "24px" }}>
                  <div className="UserManagement_form_account_input_title">
                    Account Created
                  </div>
                  <div>
                    <Input
                      className="UserManagement_form_account_input"
                      defaultValue={editData.joined_date}
                      type="text"
                      disabled
                    />
                  </div>
                </div>
                <div style={{ marginTop: "24px" }}>
                  <div className="UserManagement_form_account_input_title">
                    Last login
                  </div>
                  <div>
                    <Input
                      className="UserManagement_form_account_input"
                      defaultValue={editData.last_login}
                      type="text"
                      disabled
                    />
                  </div>
                </div>
                <div style={{ marginTop: "24px" }}>
                  <div className="UserManagement_form_account_input_title">
                    Password Expiry
                  </div>
                  <div>
                    <Input
                      className="UserManagement_form_account_input"
                      defaultValue={editData.password_expired_at}
                      type="text"
                      disabled
                    />
                  </div>
                </div>
                <div
                  style={{
                    marginTop: "24px",
                    display: "flex",
                    alignItems: "center",
                    gap: 10,
                  }}
                >
                  <div style={{ width: "24px" }}>
                    <label className="container">
                      <input
                        type="checkbox"
                        checked={
                          editData.status === "Account Disabled" ? true : false
                        }
                        onChange={() => {
                          if (editData.status === "Account Disabled") {
                            setEditData({ ...editData, status: "Active User" });
                          } else {
                            setEditData({
                              ...editData,
                              status: "Account Disabled",
                            });
                          }
                        }}
                        disabled={editMode ? false : true}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                  <div className="UserManagement_form_account_check_title">
                    Account Disable
                  </div>
                </div>
                <div style={{ marginTop: "24px", position: "relative" }}>
                  {editMode && (
                    <div
                      className="icons"
                      onClick={() => {
                        setPasswordHS({
                          ...passwordHS,
                          new: !passwordHS.new,
                        });
                      }}
                      style={{
                        backgroundImage: passwordHS.new
                          ? `url(${hide})`
                          : `url(${unhide})`,
                        cursor: "pointer",
                      }}
                    ></div>
                  )}
                  <div className="UserManagement_form_account_input_title UserManagment_align_text">
                    New Password
                  </div>
                  <div>
                    <Input
                      className="UserManagement_form_account_input_editable"
                      defaultValue={""}
                      type={
                        editMode && passwordHS.new === true
                          ? "text"
                          : "password"
                      }
                      onChange={(e) => {
                        setErrors(
                          validateInputs({
                            ...editData,
                            password: e.target.value,
                          })
                        );
                        setEditData({
                          ...editData,
                          password: e.target.value,
                        });
                      }}
                      minLength={8}
                      disabled={editMode ? false : true}
                    />
                    {errors.password && <Error>{errors.password}</Error>}
                  </div>
                </div>
                <div
                  style={{
                    marginTop: "24px",
                    paddingBottom: "50px",
                    position: "relative",
                  }}
                >
                  {editMode && (
                    <div
                      className="icons"
                      onClick={() => {
                        setPasswordHS({
                          ...passwordHS,
                          confirm: !passwordHS.confirm,
                        });
                      }}
                      style={{
                        backgroundImage: passwordHS.confirm
                          ? `url(${hide})`
                          : `url(${unhide})`,
                        cursor: "pointer",
                      }}
                    ></div>
                  )}
                  <div className="UserManagement_form_account_input_title UserManagment_align_text">
                    Confirm new password
                  </div>
                  <div>
                    <Input
                      className="UserManagement_form_account_input_editable"
                      defaultValue={""}
                      type={
                        editMode && passwordHS.confirm === true
                          ? "text"
                          : "password"
                      }
                      // type="password"
                      onChange={(e) => {
                        setErrors(
                          validateInputs({
                            ...editData,
                            new_password: e.target.value,
                          })
                        );
                        setEditData({
                          ...editData,
                          new_password: e.target.value,
                        });
                      }}
                      minLength={8}
                      disabled={editMode ? false : true}
                    />
                    {errors.new_password && (
                      <Error>{errors.new_password}</Error>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="UserManagement_form_footer">
            <div style={{ display: "flex", gap: "12px", width: "100px" }}>
              {/* {id && (
                <>
                  <div className="UserManagement_form_footer_rejected">
                    Reject
                  </div>
                  <div className="UserManagement_form_footer_approve">
                    Approve
                  </div>
                </>
              )} */}
              {/* <div className="UserManagement_form_footer_archived">Reject</div> */}
            </div>
            <div className="UserManagement_form_footer_child_box">
              {id && (
                <div
                  className="UserManagement_form_footer_audit_trail"
                  onClick={() =>
                    navigate(`${USER_MANAGEMENT_AUDIT_TRAIL}?id=${id}`)
                  }
                >
                  View Audit Trail
                </div>
              )}
              <div
                className="UserManagement_form_footer_cancel"
                onClick={handleCancelClicked}
              >
                Cancel
              </div>
              <div
                // className={
                //   editMode && validateInputs(editData)
                //     ? "UserManagement_form_footer_save disabled"
                //     : "UserManagement_form_footer_save"
                // }
                className="UserManagement_form_footer_save"
                onClick={() => {
                  if (!editMode) {
                    setShowSaveConfirmDialog(true);
                  } else {
                    handleSaveClicked();
                  }
                }}
              >
                {editMode ? "Save" : "Edit"}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
