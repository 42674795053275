import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
// kendo
import { Loader } from "@progress/kendo-react-indicators";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Checkbox, Input } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { DatePicker } from "@progress/kendo-react-dateinputs";
// component
import { Pagination } from "../../components/pagination/pagination";
import { Table } from "../../components/table/table";
// api
import config from "../../config/config";
import {
  fetchALLRegistration,
  resetRegistrationList,
} from "../../store/registrationList";
import { fetchALLGroup } from "../../store/groupList";
import { fetchALLCompany, companyLoading } from "../../store/companyList";
// route
import { APPROVAL_MANAGEMENT_DETAIL_ROUTE } from "../../routes";
// libs
import moment from "moment/moment";
// logo
import FilterIcon from "../../assets/images/Icon/FilterIcon.svg";
import SearchIcon from "../../assets/images/Icon/SearchIcon.svg";
import CrossIcon from "../../assets/images/Icon/Cross_black.svg";
import SmallCrossIcon from "../../assets/images/Icon/cross_icon.svg";
// css
import "./approval.css";
import { getCurrentLoggedInSessionTokenWithPromise } from "../../config/userPool";
import api from "../../config/api";
import { CustomDatePicker } from "../../components/datePicker/datePicker";

const header = [
  {
    name: "Name",
  },
  {
    name: "Email",
    width: "200px",
  },
  {
    name: "Company",
  },
  {
    name: "Package",
  },
  {
    name: "Roles",
    width: "200px",
  },
  {
    name: "Status",
    width: "140px",
  },
  {
    name: "Created Date",
    width: "140px",
  },
  {
    name: "Last Modified",
    width: "140px",
  },
];

export const ApprovalManagement = () => {
  const { general_config_data } = useSelector((state) => state.generalConfig);

  const statusDict = {
    pending: "Pending",
    approved: "Approved",
    rejected: "Rejected",
  };

  const { GET_REGISTRATION_LIST } = config.api_endpoint;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [userData, setUserData] = useState([]);
  const [activePages, setActivePages] = useState(1);
  const { registrationList, loading } = useSelector(
    (state) => state.registrationList
  );
  const { companyList, loading: companyLoading } = useSelector(
    (state) => state.companyList
  );
  const { groupList, loading: groupLoading } = useSelector(
    (state) => state.groupList
  );
  const [allGroups, setAllGroups] = useState([]);
  const [allCompanies, setAllCompanies] = useState([]);
  const all_packages = [
    "All",
    ...general_config_data.general_config_data.package,
  ];
  const all_packages_dict = {};
  all_packages.forEach((element) => {
    all_packages_dict[element] = false;
  });
  console.log("all_packages_dict:", all_packages_dict);

  const [newPage, setNewPage] = useState(false);

  // filters
  const [showFilter, setShowFilter] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [filterSJD, setFilterSJD] = useState();
  const [filterEJD, setFilterEJD] = useState();
  const [filterPkg, setFilterPkg] = useState(all_packages_dict);
  const [filterRole, setFilterRole] = useState([]);
  const [filterText, setFilterText] = useState("");
  const user_group_placeholder = { id: "Select", user_group_name: "Select" };

  const applyFilters = () => {
    // dispatch(resetUserList());
    setUserData([]);
    console.log("filterSJD:", filterSJD);
    console.log("filterEJD:", filterEJD);
    console.log("filterPkg:", filterPkg);
    console.log("filterRole:", filterRole);
    console.log("filterText:", filterText);

    const searchPkg = [];
    Object.entries(filterPkg).forEach((k, i) => {
      if (k[1] == true) {
        searchPkg.push(k[0]);
      }
    });
    console.log("searchPkg:", searchPkg);
    const searchRole = [];
    filterRole.forEach((x) => searchRole.push(x.user_group_name));
    console.log("searchRole:", searchRole);

    dispatch(
      fetchALLRegistration({
        page: 1,
        page_size: 20,
        start_created_date: filterSJD
          ? `${moment(filterSJD).format("YYYY-MM-DD")} ${new Date(
              filterSJD
            ).getHours()}:${new Date(filterSJD).getMinutes()}:${new Date(
              filterSJD
            ).getSeconds()}`
          : "",
        end_created_date: filterEJD
          ? `${moment(filterEJD).format("YYYY-MM-DD")} ${new Date(
              filterEJD
            ).getHours()}:${new Date(filterEJD).getMinutes()}:${new Date(
              filterEJD
            ).getSeconds()}`
          : "",
        package: searchPkg.join(","),
        role: searchRole.join(","),
        status: "",
        text: encodeURIComponent(filterText),
      })
    );

    setShowFilter(false);
  };

  const resetFilterValues = () => {
    setFilterSJD("");
    setFilterEJD("");
    setFilterPkg(all_packages_dict);
    setFilterRole([]);
  };

  const onToggleDialog = () => {
    setShowFilter(false);
  };

  const handleStartDateClicked = (e) => {
    console.log("start date clicked: ", e);
    let date = e;
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    setFilterSJD(date);
  };

  const handleEndDateClicked = (e) => {
    console.log("end date clicked: ", e);
    let date = e;
    date.setHours(23);
    date.setMinutes(59);
    date.setSeconds(59);
    setFilterEJD(date);
  };

  const handlePkg = (pkg) => {
    console.log("handlePkg: ", pkg);
    let filters = { ...filterPkg };
    console.log("before filters: ", filters);
    if (filters[pkg] === true) {
      filters[pkg] = false;
    } else {
      filters[pkg] = true;
    }
    console.log("filters: ", filters);
    setFilterPkg(filters);
  };

  const handleRole = (e) => {
    console.log("handleRole: ", e.target.value);
    let filters = [...filterRole];
    filters.push(e.target.value);
    setFilterRole(filters);
  };

  const handleSearchIconClicked = (e) => {
    setShowSearch(!showSearch);
  };

  useEffect(() => {
    console.log("groupList:", groupList);
    if (
      !groupLoading &&
      groupList.groupList &&
      groupList.groupList.items.length > 0
    ) {
      let _items = [{ id: "All", user_group_name: "All" }];
      groupList.groupList.items.forEach((element) => {
        _items.push(element);
      });
      setAllGroups(_items);
    }
  }, [groupList]);

  useEffect(() => {
    console.log("companyList:", companyList);
    if (
      !companyLoading &&
      companyList.companyList &&
      companyList.companyList.items.length > 0
    ) {
      let _items = [];
      companyList.companyList.items.forEach((element) => {
        _items.push(element);
      });
      setAllCompanies(_items);
    }
  }, [companyList]);

  useEffect(() => {
    dispatch(
      fetchALLRegistration({
        page: 1,
        page_size: 20,
      })
    );
    dispatch(fetchALLGroup({ page: 1, page_size: 9999 }));
    dispatch(fetchALLCompany({ page: 1, page_size: 9999 }));

    return () => {
      dispatch(resetRegistrationList());
    };
  }, []);

  const handlePageChange = async (page) => {
    // setUserData((userData) => []);
    setActivePages(page + 1);

    const searchPkg = [];
    Object.entries(filterPkg).forEach((k, i) => {
      if (k[1] == true) {
        searchPkg.push(k[0]);
      }
    });
    console.log("searchPkg:", searchPkg);
    const searchRole = [];
    filterRole.forEach((x) => searchRole.push(x.user_group_name));
    console.log("searchRole:", searchRole);

    const token = await getCurrentLoggedInSessionTokenWithPromise();
    const results = await api.get(
      GET_REGISTRATION_LIST,
      {
        page: page + 1,
        page_size: 50,
        start_created_date: filterSJD
          ? `${moment(filterSJD).format("YYYY-MM-DD")} ${new Date(
              filterSJD
            ).getHours()}:${new Date(filterSJD).getMinutes()}:${new Date(
              filterSJD
            ).getSeconds()}`
          : "",
        end_created_date: filterEJD
          ? `${moment(filterEJD).format("YYYY-MM-DD")} ${new Date(
              filterEJD
            ).getHours()}:${new Date(filterEJD).getMinutes()}:${new Date(
              filterEJD
            ).getSeconds()}`
          : "",
        package: searchPkg.join(","),
        role: searchRole.join(","),
        status: "",
        text: encodeURIComponent(filterText),
      },
      token
    );
    const _user_data = [];

    for (let i = 0; i < results.data.items.length; i++) {
      let user_company = [];
      for (let j = 0; j < results.data.items[i].user_company.length; j++) {
        let tmp = companyList.companyList.items.find(
          (x) => x.id === results.data.items[i].user_company[j]
        );
        if (tmp) {
          user_company.push(tmp.company_name);
        }
      }
      _user_data.push({
        id: results.data.items[i].id,
        item1: results.data.items[i].user_name,
        item2: results.data.items[i].user_email,
        item3: user_company.join(", "),
        item4: results.data.items[i].user_package.join(", "),
        item5: results.data.items[i].user_role.replaceAll(",", ", "),
        item6: statusDict[results.data.items[i].user_status],
        item7: moment(results.data.items[i].created_date).format("DD MMM YYYY"),
        item8: results.data.items[i].updated_date
          ? moment(results.data.items[i].updated_date).format("DD MMM YYYY")
          : "-",
      });
    }

    setNewPage(results.data.newPage);
    setUserData([...userData, ..._user_data]);

    // dispatch(
    //   fetchALLRegistration({
    //     page: page,
    //     page_size: 50,
    //     start_created_date: filterSJD
    //       ? `${moment(filterSJD).format("YYYY-MM-DD")} ${new Date(
    //           filterSJD
    //         ).getHours()}:${new Date(filterSJD).getMinutes()}:${new Date(
    //           filterSJD
    //         ).getSeconds()}`
    //       : "",
    //     end_created_date: filterEJD
    //       ? `${moment(filterEJD).format("YYYY-MM-DD")} ${new Date(
    //           filterEJD
    //         ).getHours()}:${new Date(filterEJD).getMinutes()}:${new Date(
    //           filterEJD
    //         ).getSeconds()}`
    //       : "",
    //     package: searchPkg.join(","),
    //     role: searchRole.join(","),
    //     status: "",
    //     text: encodeURIComponent(filterText),
    //   })
    // );
  };

  const handleTableClick = (id) => {
    console.log(id);
    navigate(`${APPROVAL_MANAGEMENT_DETAIL_ROUTE}?id=${id}`);
  };

  useEffect(() => {
    console.log("registrationList:", registrationList);
    const _user_data = [];
    if (
      !loading &&
      registrationList.registrationList &&
      registrationList.registrationList.items.length > 0 &&
      !groupLoading &&
      groupList.groupList &&
      groupList.groupList.items.length > 0 &&
      !companyLoading &&
      companyList.companyList &&
      companyList.companyList.items.length > 0
    ) {
      for (let i = 0; i < registrationList.registrationList.items.length; i++) {
        let user_company = [];
        for (
          let j = 0;
          j < registrationList.registrationList.items[i].user_company.length;
          j++
        ) {
          let tmp = companyList.companyList.items.find(
            (x) =>
              x.id ===
              registrationList.registrationList.items[i].user_company[j]
          );
          if (tmp) {
            user_company.push(tmp.company_name);
          }
        }
        _user_data.push({
          id: registrationList.registrationList.items[i].id,
          item1: registrationList.registrationList.items[i].user_name,
          item2: registrationList.registrationList.items[i].user_email,
          item3: user_company.join(", "),
          item4:
            registrationList.registrationList.items[i].user_package.join(", "),
          item5: registrationList.registrationList.items[
            i
          ].user_role.replaceAll(",", ", "),
          item6:
            statusDict[registrationList.registrationList.items[i].user_status],
          item7: moment(
            registrationList.registrationList.items[i].created_date
          ).format("DD MMM YYYY"),
          item8: registrationList.registrationList.items[i].updated_date
            ? moment(
                registrationList.registrationList.items[i].updated_date
              ).format("DD MMM YYYY")
            : "-",
        });
      }
    }
    console.log("_user_data:", _user_data);
    setUserData(_user_data);
  }, [registrationList, groupList, companyList]);

  return (
    <>
      {showFilter && (
        <Dialog>
          <div className="approval-filter-width_resize">
            <div
              style={{
                position: "absolute",
                top: "15px",
                right: "13px",
                cursor: "pointer",
              }}
              onClick={onToggleDialog}
            >
              <img src={CrossIcon} />
            </div>
            <div className="approval-filter-title">Filter</div>
            <div className="approval-filter-date-wrapper">
              <div>
                <span className="approval-filter-date-label">
                  Created From Date
                </span>
                {/* <DatePicker
                  defaultValue={filterSJD}
                  defaultShow={false}
                  value={filterSJD}
                  format={"dd MMM yyyy"}
                  onChange={handleStartDateClicked}
                  className="approval-filter-date-input"
                /> */}
                <CustomDatePicker
                  value={filterSJD}
                  onChange={handleStartDateClicked}
                  format={"DD MMM yyyy"}
                />
              </div>
              <div>
                <span className="approval-filter-date-label">
                  Created To Date
                </span>
                {/* <DatePicker
                  defaultValue={filterEJD}
                  defaultShow={false}
                  value={filterEJD}
                  format={"dd MMM yyyy"}
                  onChange={handleEndDateClicked}
                  className="approval-filter-date-input"
                /> */}
                <CustomDatePicker
                  value={filterEJD}
                  onChange={handleEndDateClicked}
                  format={"DD MMM yyyy"}
                />
              </div>
              <div></div>
            </div>
            <div className="approval-filter-sub-title">Package</div>
            <div className="approval-filter-box">
              {Object.entries(filterPkg).map(([key, value]) => (
                <div
                  className="approval-filter-item"
                  key={1 + Math.random() * (100 - 1)}
                >
                  <Checkbox
                    defaultChecked={value}
                    label={key}
                    onClick={() => handlePkg(key)}
                  />
                </div>
              ))}
            </div>
            <div className="approval-filter-sub-title">Roles</div>
            <div className="approval-filter-dropdown">
              <DropDownList
                data={allGroups}
                textField="user_group_name"
                dataItemKey="id"
                value={user_group_placeholder}
                onChange={(e) => handleRole(e)}
                className="approval-filter-dropdown-value"
              />
              <div style={{}}>
                {filterRole.map((da, id) => (
                  <div className="Approval_package_box" key={id}>
                    <div className="Approval_package_sec_box">
                      {da.user_group_name}
                    </div>
                    <div
                      style={{
                        verticalAlign: "middle",
                        height: "17px",
                        cursor: "pointer",
                        display: "inline-block",
                      }}
                      onClick={() => {
                        const filter = filterRole.filter(
                          (data) => data.id !== da.id
                        );
                        setFilterRole(filter);
                      }}
                    >
                      <img src={SmallCrossIcon} />
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="approval-filter-button-group">
              <Button
                className="approval-cancel-button"
                onClick={() => resetFilterValues()}
              >
                Clear
              </Button>
              <Button
                className="approval-filter-button"
                onClick={() => {
                  applyFilters();
                }}
              >
                Apply
              </Button>
            </div>
          </div>
        </Dialog>
      )}
      {loading || companyLoading || groupLoading ? (
        <Loader
          type="infinite-spinner"
          style={{
            display: "flex",
            alignItems: "center",
            width: "40px",
            margin: "auto",
            display: "flex",
            justifyContent: "center",
            height: "100vh",
          }}
        />
      ) : (
        <div>
          <div className="Approval_toolbar">
            <div className="Approval_toolbar_title">Approval Management</div>
            <div className="Approval_toolbar_child_box">
              {/* <div
                className="Approval_toolbar_add_button"
                onClick={() => navigate(USER_MANAGEMENT_CREATE_ROUTE)}
              >
                <div>+</div>
                <div>Add</div>
              </div> */}
              <div
                className="Approval_toolbar_filter_button"
                onClick={() => setShowFilter(true)}
              >
                <img src={FilterIcon} />
              </div>
              <div
                className="Approval_toolbar_filter_button"
                onClick={handleSearchIconClicked}
              >
                <img src={SearchIcon} />
              </div>
            </div>
          </div>
          {showSearch && (
            <div className="Approval_selection_bar">
              <Input
                value={filterText}
                onChange={(e) => setFilterText(e.target.value)}
                placeholder="Search..."
                onKeyDown={(e) => e.key === "Enter" && applyFilters()}
              />
              <div
                className="Approval_toolbar_add_button"
                onClick={() => {
                  applyFilters();
                }}
              >
                <div>Search</div>
              </div>
            </div>
          )}
          {/* <div className="Approval_selection_bar">
            <div className="Approval_selection_bar_active">All</div>
            <div className="Approval_selection_bar_unactive">
              Pending approval
            </div>
            <div className="Approval_selection_bar_unactive">Rejected</div>
          </div> */}
          {userData.length > 0 ? (
            <Table
              header={header}
              data={userData}
              handleTableClick={handleTableClick}
              handlePageChange={handlePageChange}
              activePages={activePages}
              showSearch={showSearch}
              hasMore={newPage}
            />
          ) : (
            <p style={{ textAlign: "center" }}>No records found.</p>
          )}
          {/* <Pagination
            pageCount={
              registrationList.registrationList &&
              registrationList.registrationList.total_pages
            }
          /> */}
        </div>
      )}
    </>
  );
};
