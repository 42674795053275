const api =
  "https://cereal-admin-dev-api.cereal-dev.lz.changiairport.com";
const config = {
  url: "https://cereal-admin-dev-api.cereal-dev.lz.changiairport.com",
  api_endpoint: {
    GET_USER_LIST: "/api-admin/user",
    GET_USER_DETAIL: "/api-admin/user",
    GET_COMPANY_LIST: "/api-admin/company",
    GET_COMPANY_DETAIL: "/api-admin/company",
    GET_COMPANY_MEMBER_LIST: "/api-admin/company/members",
    DELETE_COMPANY_ENDPOINT: "/api-admin/company/",
    GET_GROUP_LIST: "/api-admin/user_group",
    GET_GROUP_DETAIL: "/api-admin/user_group",
    GET_GROUP_MEMBER_LIST: "/api-admin/user_group/members",
    DELETE_GROUP_ENDPOINT: "/api-admin/group/",
    GET_AUDIT_ENDPOINT: "/api-admin/audits",
    GET_PERMISSION_LIST: "/api-admin/permission",
    GET_REGISTRATION_LIST: "/api-admin/registration",
    APPROVE_REGISTRATION: "/api-admin/registration/approve",
    REJECT_REGISTRATION: "/api-admin/registration/reject",
    DELETE_REGISTRATION: "/api-admin/registration/delete",
    VERIFY_AUTH_CODE: "/api-admin/verify_auth_code",
    FORGOT_PASSWORD: "/api-admin/forgot_password",
    SET_PASSWORD: "/api-admin/set_password",
    CHECK_ADMIN_ACCESS: "/api-admin/check_admin_access",
    GET_CONFIGURATION_ENDPOINT: "/api-admin/configuration",
    CHECK_PASSWORD: "/api-admin/check_password",
    GET_USER_ALT_ENDPOINT: "/api-admin/getUserAlt",
    DISABLE_ACCOUNT_ENDPOINT: "/api-admin/disable_account",
    IS_DISABLED_ACCOUNT_ENDPOINT: "/api-admin/is_disabled_account",
    UPDATE_USER_DETAIL: "/api-admin/update_info",
    CHANGE_PASSWORD: "/api-admin/change_password",
    GENERATE_USER_EDIT_OTP: "/api-admin/generate_user_edit_otp",
    GLOBAL_SIGN_OUT_ENDPOINT: "/api-admin/global_sign_out",
    USER_SESSION_ENDPOINT: "/api-admin/user_session",
    RESEND_VERIFY_OTP: "/api-admin/registration/resend_verification_email",
    EXPORT_USER_ENDPOINT: "/api-admin-exports/user_export",
    EDIT_PACKAGE: "/api-admin/manage_cereal_data",
    GET_CCTV: "/api-admin/get_cereal_data",
    GET_ECM: "/api-admin/get_cereal_data",
    GET_MAPLAYER: "/api-admin/get_cereal_data",
    CREATE_UPDATE_CCTV: "/api-admin/manage_cereal_data",
    CREATE_UPDATE_ECM: "/api-admin/manage_cereal_data",
    CREATE_UPDATE_MAPLAYER: "/api-admin/manage_cereal_data",
    UPLOAD_IMAGE: "/api-admin/upload_map_layer",
    OHS_FILE_UPLOAD: "/api-admin/upload-selection-list",
    OHS_FILE_ENDPOINT: "/api-admin/update-selection-list",
  },
};

export default config;
