import { Input } from "@progress/kendo-react-inputs";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Delete from "../../../assets/images/Icon/delete.svg";
import "./potential_consequences.css";
import { getCurrentLoggedInSessionTokenWithPromise } from "../../../config/userPool";
import api from "../../../config/api";
import { Loader } from "@progress/kendo-react-all";

export const PotentialConsequencesManagement = () => {
  // const { general_config_data } = useSelector((state) => state.generalConfig);
  // const { EDIT_PACKAGE } = config.api_endpoint;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [all_potential_consequences, setAll_potential_consequences] = useState(
    []
  );
  const [editMode, setEditMode] = useState(false);

  const filterPotentialConsequences = (value, id) => {
    all_potential_consequences.splice(id, 1);
    setAll_potential_consequences([...all_potential_consequences]);
  };

  const editPotentialConsequences = (value, id) => {
    all_potential_consequences[id] = value;
    setAll_potential_consequences([...all_potential_consequences]);
  };

  const callAPI = async () => {
    setLoading(true);
    const token = await getCurrentLoggedInSessionTokenWithPromise();
    api
      .put(
        "/api-admin/update-selection-list-partial",
        JSON.stringify({
          ["Potential_Consequences"]: [
            ...all_potential_consequences
              .filter((i) => i !== "Others")
              .filter((j) => j !== null)
              .sort((a, b) => (a.toLowerCase() > b.toLowerCase() ? 1 : -1)),
            "Others",
          ],
        }),
        token
      )
      .then((res) => {
        if (res.status_code === 200) {
          setAll_potential_consequences([...res?.["Potential Consequences"]]);
          setLoading(false);
        }
      });

    setEditMode(false);
  };

  const handleEdit = () => {
    if (editMode) {
      callAPI();
    }
    setEditMode(!editMode);
  };

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      const token = await getCurrentLoggedInSessionTokenWithPromise();
      api.get("/api-admin/get-selection-list", {}, token).then((res) => {
        console.log(res);
        if (
          res?.["Potential Consequences"] &&
          res?.["Potential Consequences"].length > 0
        ) {
          if (res?.["Potential Consequences"]) {
            setAll_potential_consequences(res?.["Potential Consequences"]);
            setLoading(false);
          }
        } else {
          setLoading(false);
          alert("Error getting hazards");
        }
      });
    };
    getData();
  }, []);

  return (
    <>
      {loading ? (
        <Loader
          type="infinite-spinner"
          style={{
            height: "80vh",
            display: "flex",
            alignItems: "center",
            width: "40px",
            margin: "auto",
            display: "flex",
            justifyContent: "center",
            minHeight: "100%",
          }}
        />
      ) : (
        <div className="potential_consequences_main_box">
          <div className="potential_consequences_title">
            Potential Consequences
          </div>
          <div className="potential_consequences_child_box">
            {all_potential_consequences.map((i, id) => {
              if (editMode && i === "Others") {
                return "";
              } else {
                return (
                  <div key={id} className="potential_consequences_input_box">
                    <Input
                      style={{ borderRadius: "8px" }}
                      value={i}
                      disabled={editMode ? false : true}
                      onChange={(e) =>
                        editPotentialConsequences(e.target.value, id)
                      }
                    />
                    {editMode && (
                      <div
                        className="potential_consequences_delete_icon"
                        onClick={() => filterPotentialConsequences(i, id)}
                      >
                        <img src={Delete} />
                      </div>
                    )}
                  </div>
                );
              }
            })}
          </div>
          <div style={{ position: "relative" }}>
            <div className="potential_consequences_footer_box">
              {editMode && (
                <div
                  className="potential_consequences_footer_button potential_consequences_footer_add_button"
                  onClick={() =>
                    setAll_potential_consequences([
                      ...all_potential_consequences,
                      "",
                    ])
                  }
                >
                  Add
                </div>
              )}
              <div
                className="potential_consequences_footer_button potential_consequences_footer_edit_button"
                onClick={handleEdit}
                style={{
                  pointerEvents:
                    editMode &&
                    all_potential_consequences.find((i) => i === "") !==
                      undefined
                      ? "none"
                      : "",
                  opacity:
                    editMode &&
                    all_potential_consequences.find((i) => i === "") !==
                      undefined
                      ? "0.5"
                      : "",
                }}
              >
                {editMode ? "Save" : "Edit"}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
