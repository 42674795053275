import { useContext, useState } from "react"
import { useIdleTimer } from "react-idle-timer"
const {
  signout,
} = require("../config/userPool");
/**
 * @param onIdle - function to notify user when idle timeout is close
 * @param idleTime - number of seconds to wait before user is logged out
 */
const useIdleTimeout = () => {
    const idleTimeout = 1000 * 120;
    const [isIdle, setIdle] = useState(false);
    const onIdle = () => {
        try{
              console.log("Logout");
              signout();
              localStorage.removeItem("login");
              localStorage.removeItem("username");
              localStorage.removeItem("shouldLogout");
              localStorage.removeItem("session_id");
              window.location.href = "/";
              localStorage.setItem("redirect_to", window.location.href);
        }
        catch(e)
        {
            console.log(e);
        }

    }
    const handleIdle = () => {
        try{
            setIdle(true);
            console.log("Logout");
        }
        catch(e){
            console.log(e);
        }

    }
    const idleTimer = useIdleTimer({
        timeout: idleTimeout,
        promptTimeout: idleTimeout,
        onPrompt: onIdle,
        onIdle: handleIdle,
        debounce: 500
    })
    return {
        isIdle,
        setIdle,
        idleTimer
    }
}
export default useIdleTimeout;