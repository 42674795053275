import React, { useEffect, useState } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import api from "./config/api";
import config from "./config/config";
// kendo
import { Loader } from "@progress/kendo-react-indicators";
import "@progress/kendo-theme-default/dist/all.css";

// api
import { fetchGeneralConfig } from "../src/store/generalConfigSlice";

//file
import Navigation from "./components/navigation/navigations";
import { UserManagement } from "./pages/user";
import { GroupManagement } from "./pages/group";
import { GroupAuditTrail } from "./pages/group/groupAuditTrail";
import { CompanyManagement } from "./pages/company";
import { CompanyAuditTrail } from "./pages/company/companyAuditTrail";
import { UserManagement_Form } from "./pages/user/userForm";
import { AuditTrail } from "./pages/user/userAuditTrail";
import { GroupManagement_form } from "./pages/group/groupForm";
import { ApprovalManagement_Form } from "./pages/approval/approvalForm";
import { ApprovalManagement } from "./pages/approval";
import { ApprovalAuditTrail } from "./pages/approval/approvalAuditTrail";
import { Login } from "./pages/auth/login";
import { ForgotPassword } from "./pages/auth/forgot_password";
import { ResetPassword } from "./pages/auth/reset_password";
import { CCTVManagment } from "./pages/cctv";
import ChangePassword from "./pages/auth/change_password";
import useIdleTimeout from "./components/useIdleTimeout";
import browserTabCloseEvent from "./browserTabCloseEvent";
//import checkidletime from "./checkidletime";

import { CompanyManagement_form } from "./pages/company/companyForm";
// css
import "./App.css";

//other
import {
  COMPANY_MANAGEMENT_CREATE_ROUTE,
  COMPANY_MANAGEMENT_DETAIL_ROUTE,
  COMPANY_MANAGEMENT_ROUTE,
  COMPANY_MANAGEMENT_AUDIT_TRAIL,
  GROUP_MANAGEMENT_AUDIT_TRAIL,
  GROUP_MANAGEMENT_CREATE_ROUTE,
  GROUP_MANAGEMENT_DETAIL_ROUTE,
  GROUP_MANAGEMENT_ROUTE,
  LOGIN_ROUTE,
  USER_MANAGEMENT_AUDIT_TRAIL,
  USER_MANAGEMENT_CREATE_ROUTE,
  USER_MANAGEMENT_DETAIL_ROUTE,
  USER_MANAGEMENT_ROUTE,
  APPROVAL_MANAGEMENT_ROUTE,
  APPROVAL_MANAGEMENT_DETAIL_ROUTE,
  APPROVAL_MANAGEMENT_AUDIT_TRAIL,
  FORGOT_PASSWORD_ROUTE,
  RESET_PASSWORD_ROUTE,
  CHANGE_PASSWORD_ROUTE,
  PACKAGE_MANAGMENT,
  CCTV_MANAGMENT,
  CCTV_CREATE_ROUTE,
  CCTV_DETAIL_ROUTE,
  CCTV_AUDIT_TRAIL,
  ECM_MANAGMENT,
  ECM_CREATE_ROUTE,
  ECM_DETAIL_ROUTE,
  ECM_AUDIT_TRAIL,
  MAP_LAYER_MANAGMENT,
  MAP_LAYER_CREATE_ROUTE,
  MAP_LAYER_DETAIL_ROUTE,
  MAP_LAYER_AUDIT_TRAIL,
  OHS_PAGE,
  OHS_Management_HAZARDS,
  OHS_Management_POTENTIAL,
  OHS_Management_WORK_ACTIVITY,
} from "./routes";
import PackageManagment from "./pages/package";
import { CCTVForm } from "./pages/cctv/cctvForm";
import { CCTVAuditTrail } from "./pages/cctv/cctvAuditTrail";
import { ECMManagment } from "./pages/ecm";
import { ECMForm } from "./pages/ecm/ecmForm";
import { ECMAuditTrail } from "./pages/ecm/ecmAuditTrail";
import { MapLayerManagment } from "./pages/mapLayer";
import { MapLayerForm } from "./pages/mapLayer/mapLayerForm";
import { MapLayerAuditTrail } from "./pages/mapLayer/mapLayerAuditTrail";
import { OHS } from "./pages/OHS";
import { HazardsManagement } from "./pages/OHS/hazards";
import { PotentialConsequencesManagement } from "./pages/OHS/potential_consequences";
import { WorkActivityManagement } from "./pages/OHS/work_activity";

const {
  getCurrentLoggedInSessionTokenWithPromise,
  getCurrentLoggedInUserWithPromise,
  requrieRefresh,
  signout,
} = require("./config/userPool");

function App() {
  const navigate = useNavigate();
  let location = useLocation();
  const [login, setLogin] = useState(false);
  const [logged, setLogged] = useState(false);
  const [open, setOpen] = useState(false);
  const [oldPath, setOldPath] = useState("");
  let selected = location.pathname.split("/")[1] || "login";
  const dispatch = useDispatch();
  const idle = useIdleTimeout(); //checkidletime();
  const browserCloseEventHandler = browserTabCloseEvent();
  const { GET_USER_ALT_ENDPOINT } = config.api_endpoint;
  const { general_config_loading } = useSelector(
    (state) => state.generalConfig
  );

  useEffect(() => {
    dispatch(fetchGeneralConfig());
  }, []);

  const checkPasswordExpiry = async () => {
    const token = await getCurrentLoggedInSessionTokenWithPromise();
    const user = await getCurrentLoggedInUserWithPromise();
    console.log("checkPasswordExpiry - token:", token, "user:", user);
    api
      .get(GET_USER_ALT_ENDPOINT, { uid: user["sub"] }, token)
      .then((res) => {
        console.log("checkPasswordExpiry - get user alt res", res);
        let now = new Date();
        let expired_at = new Date(res.data.password_expired_at);
        console.log(
          "checkPasswordExpiry - Has password expired:",
          expired_at,
          now,
          expired_at <= now
        );
        if (expired_at <= now) {
          if (window.location.href.indexOf("change_password") < 0) {
            setOpen(true);
          }
        }
      })
      .catch((err) => {
        console.log("checkPasswordExpiry - get user alt err", err);
      });
  };

  useEffect(() => {
    let redirect_to_user = false;
    if (
      localStorage.getItem("shouldLogout") === "false" &&
      selected === "login"
    ) {
      selected = "user";
      redirect_to_user = true;
    }

    console.log("location.pathname: ", location.pathname);
    console.log("selected: ", selected);

    setOldPath(selected);

    if (oldPath !== selected) {
      checkPasswordExpiry();
    }
    if (open) {
      navigate(CHANGE_PASSWORD_ROUTE);
    }
    const ROUTES_CAN_ACCESS_WITHOUT_LOGIN = [
      FORGOT_PASSWORD_ROUTE,
      RESET_PASSWORD_ROUTE,
    ];

    if (
      selected !== "login" &&
      ROUTES_CAN_ACCESS_WITHOUT_LOGIN.indexOf(window.location.pathname) === -1
    ) {
      setLogin(true);
      setLogged(true);
    } else {
      setLogin(false);
      setLogged(false);
    }
    console.log("redirect_to_user:", redirect_to_user);
    if (redirect_to_user) {
      navigate(USER_MANAGEMENT_ROUTE);
    }
  });

  return (
    <div className="App">
      {open && (
        <Dialog title={""} onClose={() => setOpen(false)}>
          <div
            style={{
              textAlign: "center",
              marginLeft: "30px",
              marginRight: 30,
            }}
          >
            <h4>
              {"Password has expired. You must change your password first."}
            </h4>
            <p>
              <button
                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                style={{ width: "300px" }}
                onClick={() => {
                  setOpen(false);
                }}
              >
                OK
              </button>
            </p>
          </div>
        </Dialog>
      )}
      {general_config_loading ? (
        <Loader
          type="infinite-spinner"
          style={{
            height: "80vh",
            display: "flex",
            alignItems: "center",
            width: "40px",
            margin: "auto",
            display: "flex",
            justifyContent: "center",
            minHeight: "100%",
          }}
        />
      ) : login ? (
        <Navigation selected={selected}>
          <Routes>
            <Route
              exact={true}
              path={USER_MANAGEMENT_ROUTE}
              element={<UserManagement />}
            />
            <Route
              path={USER_MANAGEMENT_CREATE_ROUTE}
              element={<UserManagement_Form />}
            />
            <Route
              path={USER_MANAGEMENT_DETAIL_ROUTE}
              element={<UserManagement_Form />}
            />
            <Route
              path={USER_MANAGEMENT_AUDIT_TRAIL}
              element={<AuditTrail />}
            />
            <Route
              path={GROUP_MANAGEMENT_ROUTE}
              element={<GroupManagement />}
            />
            <Route
              path={GROUP_MANAGEMENT_AUDIT_TRAIL}
              element={<GroupAuditTrail />}
            />
            <Route
              path={GROUP_MANAGEMENT_DETAIL_ROUTE}
              element={<GroupManagement_form />}
            />
            <Route
              path={GROUP_MANAGEMENT_CREATE_ROUTE}
              element={<GroupManagement_form />}
            />
            <Route
              path={COMPANY_MANAGEMENT_ROUTE}
              element={<CompanyManagement />}
            />
            <Route
              path={COMPANY_MANAGEMENT_CREATE_ROUTE}
              element={<CompanyManagement_form />}
            />
            <Route
              path={COMPANY_MANAGEMENT_DETAIL_ROUTE}
              element={<CompanyManagement_form />}
            />
            <Route
              path={COMPANY_MANAGEMENT_AUDIT_TRAIL}
              element={<CompanyAuditTrail />}
            />
            <Route path={PACKAGE_MANAGMENT} element={<PackageManagment />} />
            <Route
              path={APPROVAL_MANAGEMENT_ROUTE}
              element={<ApprovalManagement />}
            />
            <Route
              path={APPROVAL_MANAGEMENT_DETAIL_ROUTE}
              element={<ApprovalManagement_Form />}
            />
            <Route
              path={APPROVAL_MANAGEMENT_AUDIT_TRAIL}
              element={<ApprovalAuditTrail />}
            />
            <Route path={CCTV_MANAGMENT} element={<CCTVManagment />} />
            <Route path={CCTV_CREATE_ROUTE} element={<CCTVForm />} />
            <Route path={CCTV_DETAIL_ROUTE} element={<CCTVForm />} />
            <Route path={CCTV_AUDIT_TRAIL} element={<CCTVAuditTrail />} />
            <Route path={CHANGE_PASSWORD_ROUTE} element={<ChangePassword />} />
            <Route path={ECM_MANAGMENT} element={<ECMManagment />} />
            <Route path={ECM_CREATE_ROUTE} element={<ECMForm />} />
            <Route path={ECM_DETAIL_ROUTE} element={<ECMForm />} />
            <Route path={ECM_AUDIT_TRAIL} element={<ECMAuditTrail />} />
            <Route path={MAP_LAYER_MANAGMENT} element={<MapLayerManagment />} />
            <Route path={MAP_LAYER_CREATE_ROUTE} element={<MapLayerForm />} />
            <Route path={MAP_LAYER_DETAIL_ROUTE} element={<MapLayerForm />} />
            <Route
              path={MAP_LAYER_AUDIT_TRAIL}
              element={<MapLayerAuditTrail />}
            />
            <Route path={OHS_PAGE} element={<OHS />} />
            <Route
              path={OHS_Management_HAZARDS}
              element={<HazardsManagement />}
            />
            <Route
              path={OHS_Management_POTENTIAL}
              element={<PotentialConsequencesManagement />}
            />
            <Route
              path={OHS_Management_WORK_ACTIVITY}
              element={<WorkActivityManagement />}
            />
          </Routes>
        </Navigation>
      ) : (
        <Routes>
          <Route exact={true} path={LOGIN_ROUTE} element={<Login />} />
          <Route path={FORGOT_PASSWORD_ROUTE} element={<ForgotPassword />} />
          <Route path={RESET_PASSWORD_ROUTE} element={<ResetPassword />} />
        </Routes>
      )}
    </div>
  );
}

export default App;
