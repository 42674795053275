// redux
import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";

import api from "../config/api";
import config from "../config/config";
import { getCurrentLoggedInSessionTokenWithPromise } from "../config/userPool";

const { GET_CCTV } = config.api_endpoint;

const initialState = {
  loading: true,
  cctvList: [],
  error: null,
};

export const resetCCTVLIST = createAction("RESET_ALL");

export const fetchAllCCTV = createAsyncThunk(
  "group/fetchAllCCTV",
  async (_, { rejectWithValue }) => {
    try {
      const token = await getCurrentLoggedInSessionTokenWithPromise();
      const results = await api.post(
        GET_CCTV,
        JSON.stringify({
          data_type: "CCTV",
          statistics: {
            page: _.page || 1,
            pageSize: _.page_size || 10,
          },
        }),
        token
      );
      console.log(results);
      return {
        cctvList: results.data,
      };
    } catch (error) {
      return rejectWithValue(error.details);
    }
  }
);

const CCTVSlice = createSlice({
  name: "cctv",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllCCTV.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAllCCTV.fulfilled, (state, action) => {
        console.log(action);
        state.loading = false;
        state.cctvList = action.payload;
      })
      .addCase(fetchAllCCTV.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(resetCCTVLIST, () => initialState);
  },
});

const CCTVReducer = CCTVSlice.reducer;
export default CCTVReducer;
