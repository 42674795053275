import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment/moment";
import "./user.css";

//logo
import FilterIcon from "../../assets/images/Icon/FilterIcon.svg";
import SearchIcon from "../../assets/images/Icon/SearchIcon.svg";
import CrossIcon from "../../assets/images/Icon/Cross_black.svg";
import SmallCrossIcon from "../../assets/images/Icon/cross_icon.svg";
import ExportIcon from "../../assets/images/Icon/export.svg";

//route
import {
  USER_MANAGEMENT_CREATE_ROUTE,
  USER_MANAGEMENT_DETAIL_ROUTE,
} from "../../routes";

//api
import config from "../../config/config";
import { fetchALLUser, resetUserList } from "../../store/userList";
import { fetchALLGroup } from "../../store/groupList";
import { fetchALLCompany } from "../../store/companyList";

//component
import { Pagination } from "../../components/pagination/pagination";
import { Table } from "../../components/table/table";

//kendo
import { Loader } from "@progress/kendo-react-indicators";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Checkbox, Input } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import { DropDownList } from "@progress/kendo-react-dropdowns";
// import { DatePicker } from "@progress/kendo-react-dateinputs";
import { getCurrentLoggedInSessionTokenWithPromise } from "../../config/userPool";
import api from "../../config/api";
import { fetchGeneralConfig } from "../../store/generalConfigSlice";
import UserExport from "./userExport";
import { CustomDatePicker } from "../../components/datePicker/datePicker";

const header = [
  {
    name: "Name",
  },
  {
    name: "Email",
    width: "260px",
  },
  {
    name: "Company",
  },
  {
    name: "Package",
  },
  {
    name: "Roles",
  },
  {
    name: "Status",
    width: "140px",
  },
  {
    name: "Join Date",
    width: "120px",
  },
  {
    name: "Last Modified",
    width: "120px",
  },
];

export const UserManagement = () => {
  const { general_config_data } = useSelector((state) => state.generalConfig);
  const { GET_USER_LIST, EXPORT_USER_ENDPOINT } = config.api_endpoint;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [userData, setUserData] = useState([]);
  const [activePages, setActivePages] = useState(1);
  const [newPage, setNewPage] = useState(false);
  const [exportAllLoading, setExportAllLoading] = useState(false);
  const { companyList, loading: companyLoading } = useSelector(
    (state) => state.companyList
  );
  const { userList, loading } = useSelector((state) => state.userList);
  const { groupList, loading: groupLoading } = useSelector(
    (state) => state.groupList
  );
  const [allGroups, setAllGroups] = useState([]);
  const [allCompanies, setAllCompanies] = useState([]);
  const all_packages = [
    "All",
    ...general_config_data.general_config_data.package,
  ];
  const all_packages_dict = {};
  all_packages.forEach((element) => {
    all_packages_dict[element] = false;
  });
  console.log("all_packages_dict:", all_packages_dict);
  const [showExport, setShowExport] = useState(false);
  const [exportData, setExportData] = useState({
    start_joined_date: new Date(),
    end_joined_date: new Date(),
    start_joined_date: new Date(),
    end_joined_date: new Date(),
    package: [],
    user_group_name: [],
  });
  const [exportError, setExportError] = useState({
    startDate: false,
    endDate: false,
    package: false,
    user_group_name: false,
  });
  const [exportLoading, setExportLoading] = useState(false);

  // filters
  const [showFilter, setShowFilter] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [filterSJD, setFilterSJD] = useState();
  const [filterEJD, setFilterEJD] = useState();
  const [filterPkg, setFilterPkg] = useState(all_packages_dict);
  const [filterRole, setFilterRole] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [dateError, setDateError] = useState(false);
  const [fetchMoreLoading, setFetchMoreLoading] = useState(false);
  const user_group_placeholder = { id: "Select", user_group_name: "Select" };

  const applyFilters = () => {
    console.log(filterSJD);
    // dispatch(resetUserList());
    if (filterSJD > filterEJD) {
      setDateError(true);
      return;
    }
    setUserData([]);
    console.log("filterSJD:", filterSJD);
    console.log("filterEJD:", filterEJD);
    console.log("filterPkg:", filterPkg);
    console.log("filterRole:", filterRole);
    console.log("filterText:", filterText);

    const searchPkg = [];
    Object.entries(filterPkg).forEach((k, i) => {
      if (k[1] == true) {
        searchPkg.push(k[0]);
      }
    });
    console.log("searchPkg:", searchPkg);
    const searchRole = [];
    filterRole.forEach((x) => searchRole.push(x.user_group_name));
    console.log("searchRole:", searchRole);

    dispatch(
      fetchALLUser({
        page: 1,
        page_size: 20,
        start_joined_date: filterSJD
          ? `${moment(filterSJD).format("YYYY-MM-DD")} ${new Date(
              filterSJD
            ).getHours()}:${new Date(filterSJD).getMinutes()}:${new Date(
              filterSJD
            ).getSeconds()}`
          : "",
        end_joined_date: filterEJD
          ? `${moment(filterEJD).format("YYYY-MM-DD")} ${new Date(
              filterEJD
            ).getHours()}:${new Date(filterEJD).getMinutes()}:${new Date(
              filterEJD
            ).getSeconds()}`
          : "",
        package: searchPkg.join(","),
        role: searchRole.join(","),
        status: "",
        text: encodeURIComponent(filterText),
      })
    );

    setShowFilter(false);
  };

  const resetFilterValues = () => {
    setFilterSJD("");
    setFilterEJD("");
    setFilterPkg(all_packages_dict);
    setFilterRole([]);
  };

  const onToggleDialog = () => {
    setShowFilter(false);
  };

  const handleStartDateClicked = (e) => {
    console.log("start date clicked: ", e);
    let date = e;
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    if (filterEJD < date) {
      setDateError(true);
    } else {
      setDateError(false);
    }
    setFilterSJD(date);
  };

  const handleEndDateClicked = (e) => {
    console.log("end date clicked: ", e);
    let date = e;
    date.setHours(23);
    date.setMinutes(59);
    date.setSeconds(59);
    if (date < filterSJD) {
      setDateError(true);
    } else {
      setDateError(false);
    }
    setFilterEJD(date);
  };

  const handlePkg = (pkg) => {
    console.log("handlePkg: ", pkg);
    let filters = { ...filterPkg };
    console.log("before filters: ", filters);
    if (filters[pkg] === true) {
      filters[pkg] = false;
    } else {
      filters[pkg] = true;
    }
    console.log("filters: ", filters);
    setFilterPkg(filters);
  };

  const handleRole = (e) => {
    console.log("handleRole: ", e.target.value);
    let filters = [...filterRole];
    filters.push(e.target.value);
    setFilterRole(filters);
  };

  const handleSearchIconClicked = (e) => {
    setShowSearch(!showSearch);
  };

  useEffect(() => {
    console.log("groupList:", groupList);
    if (groupList.groupList && groupList.groupList.items.length > 0) {
      let _items = [{ id: "All", user_group_name: "All" }];
      groupList.groupList.items.forEach((element) => {
        _items.push(element);
      });
      setAllGroups(_items);
    }
  }, [groupList]);

  useEffect(() => {
    console.log("companyList:", companyList);
    if (companyList.companyList && companyList.companyList.items.length > 0) {
      let _items = [];
      companyList.companyList.items.forEach((element) => {
        _items.push(element);
      });
      setAllCompanies(_items);
    }
  }, [companyList]);

  useEffect(() => {
    dispatch(
      fetchALLUser({
        page: 1,
        page_size: 20,
      })
    );
    dispatch(fetchALLGroup({ page: 1, page_size: 9999 }));
    dispatch(fetchALLCompany({ page: 1, page_size: 9999 }));

    return () => {
      dispatch(resetUserList());
    };
  }, []);

  const handlePageChange = async (page) => {
    // setUserData((userData) => []);
    console.log(page);
    setActivePages(page + 1);

    const searchPkg = [];
    Object.entries(filterPkg).forEach((k, i) => {
      if (k[1] == true) {
        searchPkg.push(k[0]);
      }
    });
    console.log("searchPkg:", searchPkg);
    const searchRole = [];
    filterRole.forEach((x) => searchRole.push(x.user_group_name));
    console.log("searchRole:", searchRole);
    const token = await getCurrentLoggedInSessionTokenWithPromise();
    const results = await api.get(
      GET_USER_LIST,
      {
        page: page + 1,
        page_size: 20,
        start_joined_date: filterSJD
          ? `${moment(filterSJD).format("YYYY-MM-DD")} ${new Date(
              filterSJD
            ).getHours()}:${new Date(filterSJD).getMinutes()}:${new Date(
              filterSJD
            ).getSeconds()}`
          : "",
        end_joined_date: filterEJD
          ? `${moment(filterEJD).format("YYYY-MM-DD")} ${new Date(
              filterEJD
            ).getHours()}:${new Date(filterEJD).getMinutes()}:${new Date(
              filterEJD
            ).getSeconds()}`
          : "",
        package: searchPkg.join(","),
        role: searchRole.join(","),
        status: "",
        text: encodeURIComponent(filterText),
      },
      token
    );
    console.log(results);
    const _user_data = [];
    for (let i = 0; i < results.data.items.length; i++) {
      let company = [];
      for (let j = 0; j < results.data.items[i].company.length; j++) {
        let tmp = companyList.companyList.items.find(
          (x) => x.id === results.data.items[i].company[j]
        );
        if (tmp) {
          company.push(tmp.company_name);
        }
      }
      _user_data.push({
        id: results.data.items[i].id,
        item1: results.data.items[i].name,
        item2: results.data.items[i].email,
        item3: company.join(", "),
        item4: results.data.items[i].package.join(", "),
        item5: results.data.items[i].role.replaceAll(",", ", "),
        item6: results.data.items[i].status,
        item7: moment(results.data.items[i].joined_date).format("DD MMM YYYY"),
        item8: results.data.items[i].last_modified
          ? moment(results.data.items[i].last_modified).format("DD MMM YYYY")
          : "-",
      });
    }
    setNewPage(results.data.newPage);
    setUserData([...userData, ..._user_data]);

    // dispatch(
    //   fetchALLUser({
    //     page: page + 1,
    //     page_size: 20,
    //     start_joined_date: filterSJD
    //       ? `${moment(filterSJD).format("YYYY-MM-DD")} ${new Date(
    //           filterSJD
    //         ).getHours()}:${new Date(filterSJD).getMinutes()}:${new Date(
    //           filterSJD
    //         ).getSeconds()}`
    //       : "",
    //     end_joined_date: filterEJD
    //       ? `${moment(filterEJD).format("YYYY-MM-DD")} ${new Date(
    //           filterEJD
    //         ).getHours()}:${new Date(filterEJD).getMinutes()}:${new Date(
    //           filterEJD
    //         ).getSeconds()}`
    //       : "",
    //     package: searchPkg.join(","),
    //     role: searchRole.join(","),
    //     status: "",
    //     text: encodeURIComponent(filterText),
    //   })
    // );
  };

  const handleTableClick = (id) => {
    console.log(id);
    navigate(`${USER_MANAGEMENT_DETAIL_ROUTE}?id=${id}`);
  };

  useEffect(() => {
    const _user_data = [];
    if (
      !loading &&
      userList.userList &&
      userList.userList.items &&
      userList.userList.items.length > 0 &&
      !groupLoading &&
      groupList.groupList &&
      groupList.groupList.items &&
      groupList.groupList.items.length > 0 &&
      !companyLoading &&
      companyList.companyList &&
      companyList.companyList.items &&
      companyList.companyList.items.length > 0
    ) {
      for (let i = 0; i < userList.userList.items.length; i++) {
        let company = [];
        for (let j = 0; j < userList.userList.items[i].company.length; j++) {
          let tmp = companyList.companyList.items.find(
            (x) => x.id === userList.userList.items[i].company[j]
          );
          if (tmp) {
            company.push(tmp.company_name);
          }
        }
        console.log(userList.userList.items[i]);
        _user_data.push({
          id: userList.userList.items[i].id,
          item1: userList.userList.items[i].name,
          item2: userList.userList.items[i].email,
          item3: company.join(", "),
          item4: userList.userList.items[i].package.join(", "),
          item5: userList.userList.items[i].role.replaceAll(",", ", "),
          item6: userList.userList.items[i].status,
          item7: moment(userList.userList.items[i].joined_date).format(
            "DD MMM YYYY"
          ),
          item8: userList.userList.items[i].last_modified
            ? moment(userList.userList.items[i].last_modified).format(
                "DD MMM YYYY"
              )
            : "-",
        });
      }
      setNewPage(userList.userList.newPage);
    }
    console.log("_user_data:", _user_data);
    setUserData(_user_data);
  }, [userList, groupList, companyList]);

  const padZero = (n) => {
    return String("0" + n).slice(-2);
  };

  const dateTimeToDateString = (value) => {
    return (
      value.getFullYear() +
      "-" +
      padZero(value.getMonth() + 1) +
      "-" +
      padZero(value.getDate())
    );
  };

  const handleExport = (value, target) => {
    console.log(value, target);
    if (target === "package") {
      setExportError({
        ...exportError,
        package: false,
      });
    } else if (target === "user_group_name") {
      setExportError({
        ...exportError,
        user_group_name: false,
      });
    } else if (target === "start_joined_date") {
      if (exportData.end_joined_date > value) {
        setExportError({
          ...exportError,
          startDate: false,
          endDate: false,
        });
      }
    } else {
      if (exportData.start_joined_date < value) {
        setExportError({
          ...exportError,
          endDate: false,
          startDate: false,
        });
      }
    }
    setExportData({
      ...exportData,
      [target]: value,
    });
  };
  const handleExportAPI = async () => {
    setExportLoading(true);
    console.log(exportData);
    if (
      exportData.start_joined_date === "" ||
      exportData.end_joined_date === "" ||
      exportData.package.length === 0 ||
      exportData.user_group_name.length === 0 ||
      exportData.start_joined_date > exportData.end_joined_date
    ) {
      setExportError({
        ...exportError,
        startDate:
          exportData.start_joined_date > exportData.end_joined_date && true,
        endDate:
          exportData.start_joined_date > exportData.end_joined_date && true,
        package: exportData.package.length === 0 && true,
        user_group_name: exportData.user_group_name.length === 0,
      });
      setExportLoading(false);
      return;
    } else {
      setExportError({
        ...exportError,
        startDate: false,
        endDate: false,
        package: false,
        user_group_name: false,
      });
    }
    const role = [];
    exportData.user_group_name.map((i) => {
      role.push(i.user_group_name);
    });

    console.log(exportData);

    const token = await getCurrentLoggedInSessionTokenWithPromise();
    api
      .get(
        EXPORT_USER_ENDPOINT,
        {
          start_joined_date: dateTimeToDateString(exportData.start_joined_date),
          end_joined_date: dateTimeToDateString(exportData.end_joined_date),
          package: exportData.package.join(","),
          role: role.join(","),
        },
        token
      )
      .then((res) => {
        console.log(res);
        if (res.url) {
          const link = document.createElement("a");
          link.href = res.url;
          link.download = res.url.split("/").pop();
          link.target = "_blank";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else {
          alert("Error encountered while downloading the file.");
        }
        setExportData({
          ...exportData,
          start_joined_date: new Date(),
          end_joined_date: new Date(),
          package: [],
          user_group_name: [],
        });
        setExportLoading(false);
        setShowExport(false);
      });
  };

  const exportAll = async () => {
    setExportAllLoading(true);
    const token = await getCurrentLoggedInSessionTokenWithPromise();
    api.get(EXPORT_USER_ENDPOINT, {}, token).then((res) => {
      console.log(res);
      if (res.url) {
        setExportAllLoading(false);
        const link = document.createElement("a");
        link.href = res.url;
        link.download = res.url.split("/").pop();
        link.target = "_blank";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        alert("Error encountered while downloading the file.");
      }
    });
  };

  return (
    <>
      {showFilter && (
        <Dialog>
          <div className="usermanagement-filter-width_resize">
            <div
              style={{
                position: "absolute",
                top: "15px",
                right: "13px",
                cursor: "pointer",
              }}
              onClick={onToggleDialog}
            >
              <img src={CrossIcon} />
            </div>
            <div className="usermanagement-filter-title">Filter</div>
            <div className="usermanagement-filter-date-wrapper">
              <div>
                <span className="usermanagement-filter-date-label">
                  Account Joined From Date
                </span>
                {/* <DatePicker
                  defaultValue={filterSJD}
                  defaultShow={false}
                  value={filterSJD}
                  format={"dd MMM yyyy"}
                  onChange={handleStartDateClicked}
                  className="usermanagement-filter-date-input"
                /> */}
                <CustomDatePicker
                  value={filterSJD}
                  onChange={handleStartDateClicked}
                  format={"DD MMM yyyy"}
                />
                {dateError && (
                  <div className="Work_error_text">
                    Start date should not be later than End Date!
                  </div>
                )}
              </div>
              <div>
                <span className="usermanagement-filter-date-label">
                  Account Joined To Date
                </span>
                {/* <DatePicker
                  defaultValue={filterEJD}
                  defaultShow={false}
                  value={filterEJD}
                  format={"dd MMM yyyy"}
                  onChange={handleEndDateClicked}
                  className="usermanagement-filter-date-input"
                /> */}
                <CustomDatePicker
                  value={filterEJD}
                  onChange={handleEndDateClicked}
                  format={"DD MMM yyyy"}
                />
                {dateError && (
                  <div className="Work_error_text">
                    End date should not be earlier than Start Date!
                  </div>
                )}
              </div>
              <div></div>
            </div>
            <div className="usermanagement-filter-sub-title">Package</div>
            <div className="usermanagement-filter-box">
              {Object.entries(filterPkg).map(([key, value]) => (
                <div
                  className="usermanagement-filter-item"
                  key={1 + Math.random() * (100 - 1)}
                >
                  <Checkbox
                    defaultChecked={value}
                    label={key}
                    onClick={() => handlePkg(key)}
                  />
                </div>
              ))}
            </div>
            <div className="usermanagement-filter-sub-title">Roles</div>
            <div className="usermanagement-filter-dropdown">
              <DropDownList
                data={allGroups}
                textField="user_group_name"
                dataItemKey="id"
                value={user_group_placeholder}
                onChange={(e) => handleRole(e)}
                className="usermanagement-filter-dropdown-value"
              />
              <div style={{}}>
                {filterRole.map((da, id) => (
                  <div className="UserManagement_package_box" key={id}>
                    <div className="UserManagement_package_sec_box">
                      {da.user_group_name}
                    </div>
                    <div
                      style={{
                        verticalAlign: "middle",
                        height: "17px",
                        cursor: "pointer",
                        display: "inline-block",
                      }}
                      onClick={() => {
                        const filter = filterRole.filter(
                          (data) => data.id !== da.id
                        );
                        setFilterRole(filter);
                      }}
                    >
                      <img src={SmallCrossIcon} />
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="usermanagement-filter-button-group">
              <Button
                className="usermanagement-cancel-button"
                onClick={() => resetFilterValues()}
              >
                Clear
              </Button>
              <Button
                className="usermanagement-filter-button"
                onClick={() => {
                  applyFilters();
                }}
              >
                Apply
              </Button>
            </div>
          </div>
        </Dialog>
      )}
      {showExport && (
        <UserExport
          Package={all_packages}
          role={allGroups}
          closeExport={setShowExport}
          handleExport={handleExport}
          handleExportAPI={handleExportAPI}
          exportData={exportData}
          exportLoading={exportLoading}
          exportError={exportError}
        />
      )}
      {exportAllLoading || loading || companyLoading || groupLoading ? (
        <Loader
          type="infinite-spinner"
          style={{
            display: "flex",
            alignItems: "center",
            width: "40px",
            margin: "auto",
            display: "flex",
            justifyContent: "center",
            height: "100vh",
          }}
        />
      ) : (
        <div>
          <div className="UserManagement_toolbar">
            <div className="UserManagement_toolbar_title">User Management</div>
            <div className="UserManagement_toolbar_child_box">
              <div
                className="UserManagement_toolbar_add_button"
                onClick={() => navigate(USER_MANAGEMENT_CREATE_ROUTE)}
              >
                <div>+</div>
                <div>Add</div>
              </div>
              <div
                className="UserManagement_toolbar_filter_button"
                onClick={() => setShowFilter(true)}
              >
                <img src={FilterIcon} />
              </div>
              <div
                className="UserManagement_toolbar_filter_button"
                onClick={() => setShowExport(true)}
              >
                <img src={ExportIcon} />
              </div>
              <div
                className="UserManagement_toolbar_filter_button"
                style={{
                  width: "120px",
                  gap: "10px",
                  fontFamily: "Montserrat-SemiBold",
                  color: "#eb7930",
                  fontSize: "14px",
                }}
                onClick={exportAll}
              >
                <img src={ExportIcon} />
                Export all
              </div>
              <div
                className="UserManagement_toolbar_filter_button"
                onClick={handleSearchIconClicked}
              >
                <img src={SearchIcon} />
              </div>
            </div>
          </div>
          {showSearch && (
            <div className="UserManagement_selection_bar">
              <Input
                value={filterText}
                onChange={(e) => setFilterText(e.target.value)}
                placeholder="Search..."
                onKeyDown={(e) => e.key === "Enter" && applyFilters()}
              />
              <div
                className="UserManagement_toolbar_add_button"
                onClick={() => {
                  applyFilters();
                }}
              >
                <div>Search</div>
              </div>
            </div>
          )}
          {/* <div className="UserManagement_selection_bar">
            <div className="UserManagement_selection_bar_active">All</div>
            <div className="UserManagement_selection_bar_unactive">
              Pending approval
            </div>
            <div className="UserManagement_selection_bar_unactive">Rejected</div>
          </div> */}
          {/* <div id="scrollableDiv"> */}
          {/* <InfiniteScroll
              dataLength={userData ? userData.length : 0}
              scrollableTarget="scrollableDiv"
              loader={
                <Loader
                  style={{
                    position: "relative",
                    height: "70px",
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                />
              }
              // next={() => {
              //   props.AddData(
              //     props.status,
              //     searchTxtStr,
              //     allFilters.startDate,
              //     allFilters.endDate
              //   );
              // }}
              // hasMore={props.hasMore}
            > */}
          {console.log(userList)}
          {userData.length > 0 ? (
            <Table
              header={header}
              data={userData}
              handleTableClick={handleTableClick}
              hasMore={newPage}
              handlePageChange={handlePageChange}
              activePages={activePages}
              showSearch={showSearch}
            />
          ) : (
            <p style={{ textAlign: "center" }}>No records found.</p>
          )}
          {/* </InfiniteScroll> */}
          {/* </div> */}
          {/* <Pagination
            pageCount={userList.userList && userList.userList.total_pages}
          /> */}
        </div>
      )}
    </>
  );
};
