// redux
import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";

import api from "../config/api";
import config from "../config/config";
import { getCurrentLoggedInSessionTokenWithPromise } from "../config/userPool";

const { GET_ECM } = config.api_endpoint;

const initialState = {
  loading: true,
  ecmList: [],
  error: null,
};

export const resetECMList = createAction("RESET_ALL");

export const fetchAllECM = createAsyncThunk(
  "group/fetchAllECM",
  async (_, { rejectWithValue }) => {
    try {
      const token = await getCurrentLoggedInSessionTokenWithPromise();
      const results = await api.post(
        GET_ECM,
        JSON.stringify({
          data_type: "ECM",
          statistics: {
            page: _.page || 1,
            pageSize: _.page_size || 10,
          },
        }),
        token
      );
      console.log(results);
      return {
        ecmList: results.data,
      };
    } catch (error) {
      return rejectWithValue(error.details);
    }
  }
);

const ECMSlice = createSlice({
  name: "ecm",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllECM.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAllECM.fulfilled, (state, action) => {
        console.log(action);
        state.loading = false;
        state.ecmList = action.payload;
      })
      .addCase(fetchAllECM.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(resetECMList, () => initialState);
  },
});

const ECMReducer = ECMSlice.reducer;
export default ECMReducer;
