import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

//logo
import BackIcon from "../../assets/images/Icon/PreviousIcon.svg";
import CrossIcon from "../../assets/images/Icon/cross_icon.svg";

//kendo
import { Input } from "@progress/kendo-react-inputs";
import { Error } from "@progress/kendo-react-labels";
//routes
import {
  APPROVAL_MANAGEMENT_AUDIT_TRAIL,
  APPROVAL_MANAGEMENT_ROUTE,
} from "../../routes";
import FullscreenLoader from "../../components/fullScreenLoader";
//config
import api from "../../config/api";
import config from "../../config/config";
import {
  getCurrentLoggedInSessionTokenWithPromise,
  getCurrentLoggedInUserWithPromise,
} from "../../config/userPool";
import { DropDownList, Loader } from "@progress/kendo-react-all";
import { fetchALLCompany } from "../../store/companyList";
import { fetchALLGroup } from "../../store/groupList";
import moment from "moment";

export const ApprovalManagement_Form = () => {
  const { general_config_data } = useSelector((state) => state.generalConfig);

  const statusDict = {
    pending: "Pending",
    approved: "Approved",
    rejected: "Rejected",
  };

  const uploadRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { search } = useLocation();
  const query = new URLSearchParams(search);
  const [loading, setLoading] = useState(true);
  const [fullScreenLoading, setFullScreenLoading] = useState(false);
  const [tab, setTab] = useState(0);
  const [companyDropdown, setCompanyDropdown] = useState([]);
  const [groupDropdown, setGroupDropdown] = useState([]);
  const departmentDropdown = [
    ...general_config_data.general_config_data.department,
  ];
  const [disableEdit, setDisableEdit] = useState(false);
  const [data, setData] = useState({
    user_name: "",
    user_email: "",
    user_phone_number: "",
    user_company: [],
    user_designation: "",
    user_department: "",
    user_package: [],
    user_role: "",
  });
  const [editData, setEditData] = useState({
    user_name: "",
    user_email: "",
    user_phone_number: "",
    user_company: [],
    user_designation: "",
    user_department: "",
    user_package: [],
    user_role: "",
    user_group: [],
  });
  const [errors, setErrors] = useState({});
  const {
    GET_REGISTRATION_LIST,
    APPROVE_REGISTRATION,
    REJECT_REGISTRATION,
    DELETE_REGISTRATION,
    RESEND_VERIFY_OTP
  } = config.api_endpoint;
  const { groupList } = useSelector((state) => state.groupList);
  const { companyList } = useSelector((state) => state.companyList);
  const id = query.get("id");
  const mandatoryFields = [
    "user_name",
    "user_email",
    "user_phone_number",
    "user_company",
    "user_group",
    "user_designation",
    //    "user_department",
    "user_package",
  ];

  const getApprovalDetail = async () => {
    const token = await getCurrentLoggedInSessionTokenWithPromise();
    const result = await api.get(
      GET_REGISTRATION_LIST,
      {
        id: id,
      },
      token
    );
    console.log(result);
    if (result.status_code === 200) {
      setEditData({
        id: result.data.id,
        user_name: result.data.user_name,
        user_email: result.data.user_email,
        user_phone_number: result.data.user_phone_number,
        user_company:
          result.data.user_company.length > 0
            ? [result.data.user_company[0].id]
            : [],
        user_group: result.data.user_group.map((x) => x.id),
        user_designation: result.data.user_designation,
        user_department: result.data.user_department,
        user_other_department: result.data.user_other_department || "",
        user_package: result.data.user_package,
        user_role: result.data.user_role,
        created_date: moment(result.data.created_date).format("DD MMM YYYY"),
        user_status: result.data.user_status,
        has_verified: result.data.has_verified,
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    if (groupList.groupList && groupList.groupList.items.length > 0) {
      setGroupDropdown(groupList.groupList.items);
    }

    if (companyList.companyList && companyList.companyList.items.length > 0) {
      setCompanyDropdown(companyList.companyList.items);
    }
  }, [companyList, groupList]);

  useEffect(() => {
    dispatch(fetchALLCompany({ page: 1, page_size: 9999 }));
    dispatch(fetchALLGroup({ page: 1, page_size: 9999 }));
    if (id) {
      getApprovalDetail();
      setDisableEdit(true);
    } else {
      setLoading(false);
    }
  }, [id]);

  useEffect(() => {
    const errs = validateInputs(editData);
    setErrors(errs);
  }, [editData]);

  const validateInputs = (data) => {
    const _errors = {};
    mandatoryFields.forEach((_field) => {
      if (["user_company", "user_group", "user_package"].indexOf(_field) > -1) {
        if (data[_field].length === 0) {
          _errors[_field] = "This field is required.";
        }
      } else {
        if (!data[_field]) {
          _errors[_field] = "This field is required.";
        }
      }
    });
    if (data["user_phone_number"]) {
      if (/\+65(6|8|9)\d{7}$/.test(data["user_phone_number"]) == false) {
        _errors["user_phone_number"] =
          "Please put in +65(9/8/6) followed by 7 digits.";
      }
    }
    if (data["user_designation"]) {
      if (data["user_designation"].match("^[a-zA-Z ]*$") === null) {
        _errors["user_designation"] =
          "Your designation should contain only letters.";
      }
    }
    if (data["user_department"]) {
      if (
        data["user_department"] === "Others" &&
        !data["user_other_department"]
      ) {
        _errors["user_other_department"] = "This field is required.";
      }
    }

    return _errors;
  };

  const handleSaveClicked = async () => {
    console.log("save button clicked.");

    if (editData.user_status === "approved") {
      return;
    }
    if (Object.keys(errors).length > 0) {
      return;
    }

    const currentLoginUser = await getCurrentLoggedInUserWithPromise();
    console.log("currentLoginUser:", currentLoginUser);

    const toSaveData = JSON.parse(JSON.stringify(editData));

    if (editData.id) {
      toSaveData["updated_by"] = currentLoginUser["sub"];
    } else {
      toSaveData["created_by"] = currentLoginUser["sub"];
    }

    console.log("save data:", toSaveData);

    setFullScreenLoading(true);
    const token = await getCurrentLoggedInSessionTokenWithPromise();
    api
      .post(GET_REGISTRATION_LIST, JSON.stringify(toSaveData), token)
      .then((res) => {
        console.log("save user result: ", res);
        setFullScreenLoading(false);
        if (res["status_code"] >= 200 && res["status_code"] < 300) {
          alert("User registration is successfully saved.");
          navigate(APPROVAL_MANAGEMENT_ROUTE);
        } else {
          alert("Unable to save the user registration.");
        }
      })
      .catch((err) => {
        console.log("save the user registration err", err);
        setFullScreenLoading(false);
      });
  };

  const handleCancelClicked = () => {
    console.log("cancel button clicked.");
    navigate(-1);
  };

  const handleApproveClicked = async () => {
    console.log("Approve Clicked.");

    setFullScreenLoading(true);
    const token = await getCurrentLoggedInSessionTokenWithPromise();
    const currentLoginUser = await getCurrentLoggedInUserWithPromise();
    console.log("currentLoginUser:", currentLoginUser);

    const toSaveData = {
      id: id,
      updated_by: currentLoginUser["sub"],
    };

    api
      .post(APPROVE_REGISTRATION, JSON.stringify(toSaveData), token)
      .then((res) => {
        console.log("approve the user registration result: ", res);
        setFullScreenLoading(false);
        if (res["status_code"] >= 200 && res["status_code"] < 300) {
          alert("User registration has been approved.");
          navigate(APPROVAL_MANAGEMENT_ROUTE);
        } else {
          alert("Unable to approve the user registration.");
        }
      })
      .catch((err) => {
        console.log("approve the user registration err", err);
        setFullScreenLoading(false);
      });
  };

  const handleResendVerifyOTP = async () => {
    console.log("Resend Verify OTP Clicked.");

    setFullScreenLoading(true);
    const token = await getCurrentLoggedInSessionTokenWithPromise();
    const currentLoginUser = await getCurrentLoggedInUserWithPromise();
    console.log("currentLoginUser:", currentLoginUser);

    const toAPIData = {
      id: id     
    };

    api
      .post(RESEND_VERIFY_OTP, JSON.stringify(toAPIData), token)
      .then((res) => {
        console.log("Resend Verify OTP result: ", res);
        setFullScreenLoading(false);
        if (res["status_code"] >= 200 && res["status_code"] < 300) {
          alert("Verification OTP has been resent.");
          navigate(APPROVAL_MANAGEMENT_ROUTE);
        } else {
          alert("Unable to resend OTP for verification.");
        }
      })
      .catch((err) => {
        console.log("Resend Verify OTP err", err);
        setFullScreenLoading(false);
      });
  };

  const handleDeleteClicked = async () => {
    console.log("Delete Clicked.");

    setFullScreenLoading(true);
    const token = await getCurrentLoggedInSessionTokenWithPromise();
    const currentLoginUser = await getCurrentLoggedInUserWithPromise();
    console.log("currentLoginUser:", currentLoginUser);

    const toSaveData = {
      id: id,
      updated_by: currentLoginUser["sub"],
    };

    api
      .post(DELETE_REGISTRATION, JSON.stringify(toSaveData), token)
      .then((res) => {
        console.log("delete the user registration result: ", res);
        setFullScreenLoading(false);
        if (res["status_code"] >= 200 && res["status_code"] < 300) {
          alert("User registration has been deleted.");
          navigate(APPROVAL_MANAGEMENT_ROUTE);
        } else {
          alert("Unable to delete the user registration.");
        }
      })
      .catch((err) => {
        console.log("delete the user registration err", err);
        setFullScreenLoading(false);
      });
  };

  const handleRejectClicked = async () => {
    console.log("Reject Clicked.");

    setFullScreenLoading(true);
    const token = await getCurrentLoggedInSessionTokenWithPromise();
    const currentLoginUser = await getCurrentLoggedInUserWithPromise();
    console.log("currentLoginUser:", currentLoginUser);

    const toSaveData = {
      id: id,
      updated_by: currentLoginUser["sub"],
    };

    api
      .post(REJECT_REGISTRATION, JSON.stringify(toSaveData), token)
      .then((res) => {
        console.log("reject the user registration result: ", res);
        setFullScreenLoading(false);
        if (res["status_code"] >= 200 && res["status_code"] < 300) {
          alert("User registration has been rejected.");
          navigate(APPROVAL_MANAGEMENT_ROUTE);
        } else {
          alert("Unable to reject the user registration.");
        }
      })
      .catch((err) => {
        console.log("reject the user registration err", err);
        setFullScreenLoading(false);
      });
  };

  const user_group_placeholder = { id: "Select", user_group_name: "Select" };

  return (
    <>
      {fullScreenLoading && <FullscreenLoader />}
      {loading ? (
        <Loader
          type="infinite-spinner"
          style={{
            display: "flex",
            alignItems: "center",
            width: "40px",
            margin: "auto",
            display: "flex",
            justifyContent: "center",
            height: "100vh",
          }}
        />
      ) : (
        <div style={{ background: "#FFFFFF" }}>
          <div className="Approval_form_toolbar">
            <div
              className="Approval_form_toolbar_back_icon"
              onClick={() => navigate(APPROVAL_MANAGEMENT_ROUTE)}
            >
              <img src={BackIcon} />
            </div>
            <div className="Approval_form_toolbar_title">Approval Details</div>
          </div>
          <div className="Approval_form_sec_toolbar">
            <div
              className={
                tab === 0
                  ? "Approval_form_sec_toolbar_item_active"
                  : "Approval_form_sec_toolbar_item_unactive"
              }
              onClick={() => setTab(0)}
            >
              Registration
            </div>
          </div>
          <div className="Approval_main_box">
            <div style={{ marginBottom: "40px" }}></div>
            <div className="Approval_detail_main">
              <div className="Approval_detail_main_box">
                <div className="Approval_detail_main_box_title">Name</div>
                {/* {editMode ? ( */}
                <Input
                  className="Approval_detail_main_box_desc_input"
                  value={editData.user_name}
                  onChange={(e) => {
                    setEditData({
                      ...editData,
                      user_name: e.target.value,
                    });
                  }}
                />
                {errors.user_name && <Error>{errors.user_name}</Error>}
              </div>
              <div className="Approval_detail_main_box">
                <div className="Approval_detail_main_box_title">
                  Business Email
                </div>
                <Input
                  className={
                    disableEdit
                      ? "Approval_detail_main_box_desc_input disabled"
                      : "Approval_detail_main_box_desc_input"
                  }
                  type="email"
                  value={editData.user_email}
                  onChange={(e) => {
                    setEditData({
                      ...editData,
                      user_email: e.target.value,
                    });
                  }}
                  readOnly={disableEdit ? "readonly" : ""}
                />
                {errors.user_email && <Error>{errors.user_email}</Error>}
              </div>
              <div className="Approval_detail_main_box">
                <div className="Approval_detail_main_box_title">
                  Business Contact Number
                </div>
                <Input
                  className="Approval_detail_main_box_desc_input"
                  type="tel"
                  value={editData.user_phone_number}
                  onChange={(e) => {
                    setEditData({
                      ...editData,
                      user_phone_number: e.target.value,
                    });
                  }}
                />
                {errors.user_phone_number && (
                  <Error>{errors.user_phone_number}</Error>
                )}
              </div>
              <div className="Approval_detail_main_box">
                <div className="Approval_detail_main_box_title">Company</div>
                <DropDownList
                  data={companyDropdown}
                  // label={"Please choose a package."}
                  defaultValue={
                    editData.user_company.length === 1
                      ? companyDropdown.find(
                          (x) => x.id === editData.user_company[0]
                        )
                      : ""
                  }
                  textField="company_name"
                  dataItemKey="id"
                  onChange={(e) => {
                    setEditData({
                      ...editData,
                      user_company: [e.target.value.id],
                    });
                  }}
                />
                {errors.user_company && <Error>{errors.user_company}</Error>}
              </div>
              <div className="Approval_detail_main_box">
                <div className="Approval_detail_main_box_title">
                  Designation
                </div>{" "}
                <Input
                  className="Approval_detail_main_box_desc_input"
                  value={editData.user_designation}
                  onChange={(e) => {
                    setEditData({
                      ...editData,
                      user_designation: e.target.value,
                    });
                  }}
                />
                {errors.user_designation && (
                  <Error>{errors.user_designation}</Error>
                )}
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div className="Approval_detail_main_box">
                  <div className="Approval_detail_main_box_title">
                    Department
                  </div>{" "}
                  <DropDownList
                    data={departmentDropdown}
                    value={editData.user_department}
                    onChange={(e) => {
                      setEditData({
                        ...editData,
                        user_department: e.target.value,
                      });
                    }}
                  />
                  {errors.user_department && (
                    <Error>{errors.user_department}</Error>
                  )}
                  {!errors.user_department && errors.user_other_department && (
                    <div style={{ height: "19px" }}></div>
                  )}
                </div>
                <div className="Approval_detail_main_box">
                  <div className="Approval_detail_main_box_title">
                    Other Department
                  </div>{" "}
                  <Input
                    className="Approval_detail_main_box_desc_input"
                    value={editData.user_other_department}
                    onChange={(e) => {
                      setEditData({
                        ...editData,
                        user_other_department: e.target.value,
                      });
                    }}
                  />
                  {errors.user_other_department && (
                    <Error>{errors.user_other_department}</Error>
                  )}
                  {errors.user_department && !errors.user_other_department && (
                    <div style={{ height: "19px" }}></div>
                  )}
                </div>
              </div>
              <div className="Approval_detail_main_box">
                <div className="Approval_detail_main_box_title">Package</div>
                <DropDownList
                  data={[
                    "All",
                    ...general_config_data.general_config_data.package,
                  ]}
                  value={"Select"}
                  onChange={(e) => {
                    if (editData.user_package.indexOf(e.target.value) === -1) {
                      editData.user_package.push(e.target.value);
                      setEditData({ ...editData });
                    }
                  }}
                />
                <div style={{}}>
                  {editData.user_package.map((da, id) => (
                    <div className="Approval_package_box" key={id}>
                      <div className="Approval_package_sec_box">{da}</div>
                      <div
                        style={{
                          verticalAlign: "middle",
                          height: "17px",
                          cursor: "pointer",
                          display: "inline-block",
                        }}
                        onClick={() => {
                          const filter = editData.user_package.filter(
                            (data) => data !== da
                          );
                          setEditData({ ...editData, user_package: filter });
                        }}
                      >
                        <img src={CrossIcon} />
                      </div>
                    </div>
                  ))}
                </div>
                {errors.user_package && <Error>{errors.user_package}</Error>}
              </div>
              <div className="Approval_detail_main_box">
                <div className="Approval_detail_main_box_title">Role</div>
                <DropDownList
                  data={groupDropdown}
                  value={user_group_placeholder}
                  textField="user_group_name"
                  dataItemKey="id"
                  onChange={(e) => {
                    if (editData.user_group.indexOf(e.target.value.id) === -1) {
                      editData.user_group.push(e.target.value.id);
                      setEditData({ ...editData });
                    }
                  }}
                />
                <div style={{}}>
                  {editData.user_group.map((da, id) => (
                    <div className="Approval_package_box" key={id}>
                      <div className="Approval_package_sec_box">
                        {groupDropdown.find((x) => da == x.id).user_group_name}
                      </div>
                      <div
                        style={{
                          verticalAlign: "middle",
                          height: "17px",
                          cursor: "pointer",
                          display: "inline-block",
                        }}
                        onClick={() => {
                          const filter = editData.user_group.filter(
                            (data) => data !== da
                          );
                          setEditData({ ...editData, user_group: filter });
                        }}
                      >
                        <img src={CrossIcon} />
                      </div>
                    </div>
                  ))}
                </div>
                {errors.user_group && <Error>{errors.user_group}</Error>}
              </div>
              <div className="Approval_detail_main_box">
                <div className="Approval_detail_main_box_title">
                  Has user verified email
                </div>{" "}
                {editData.has_verified ? "Yes" : "No"}
              </div>
              <div className="Approval_detail_main_box">
                <div className="Approval_detail_main_box_title">
                  Registration Status
                </div>{" "}
                {statusDict[editData.user_status]}
              </div>
            </div>
          </div>
          <div className="Approval_form_footer">
            <div style={{ display: "flex", gap: "12px" }}>
              <>
                {id && ["pending"].indexOf(editData.user_status) > -1 && (
                  <div
                    className="Approval_form_footer_rejected"
                    onClick={handleRejectClicked}
                  >
                    Reject
                  </div>
                )}
                {id &&
                  ["pending", "rejected"].indexOf(editData.user_status) >
                    -1 && (
                    <div
                      className="Approval_form_footer_approve"
                      onClick={handleApproveClicked}
                    >
                      Approve
                    </div>
                  )}
                {id && !editData.has_verified && (
                    <div
                      className="Approval_form_footer_approve"
                      onClick={handleResendVerifyOTP}
                    >
                      Resend Verfication OTP
                    </div>
                  )}
                {id &&
                  ["pending", "rejected"].indexOf(editData.user_status) >
                    -1 && (
                    <div
                      className="Approval_form_footer_approve"
                      onClick={handleDeleteClicked}
                    >
                      Delete
                    </div>
                  )}
              </>
            </div>
            <div className="Approval_form_footer_child_box">
              {id && (
                <div
                  className="Approval_form_footer_audit_trail"
                  onClick={() =>
                    navigate(`${APPROVAL_MANAGEMENT_AUDIT_TRAIL}?id=${id}`)
                  }
                >
                  View Audit Trail
                </div>
              )}
              <div
                className="Approval_form_footer_cancel"
                onClick={handleCancelClicked}
              >
                Cancel
              </div>
              {editData.user_status !== "approved" && (
                <div
                  className={
                    Object.keys(errors).length > 0
                      ? "Approval_form_footer_save disabled"
                      : "Approval_form_footer_save "
                  }
                  onClick={handleSaveClicked}
                >
                  Save
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
