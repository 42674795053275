import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// kendo
import { Loader } from "@progress/kendo-react-indicators";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Input } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import { DatePicker } from "@progress/kendo-react-dateinputs";
// libs
import moment from "moment";
// logo
import FilterIcon from "../../assets/images/Icon/FilterIcon.svg";
import SearchIcon from "../../assets/images/Icon/SearchIcon.svg";
import CrossIcon from "../../assets/images/Icon/Cross_black.svg";
// component
import { Table } from "../../components/table/table";
import { Pagination } from "../../components/pagination/pagination";
// store
import { fetchALLCompany, resetCompanyList } from "../../store/companyList";
// routes
import {
  COMPANY_MANAGEMENT_CREATE_ROUTE,
  COMPANY_MANAGEMENT_DETAIL_ROUTE,
} from "../../routes";
// css
import "./company.css";
import { getCurrentLoggedInSessionTokenWithPromise } from "../../config/userPool";
import api from "../../config/api";
import config from "../../config/config";
import { CustomDatePicker } from "../../components/datePicker/datePicker";

const header = [
  {
    name: "Company",
  },
  {
    name: "Member(s) in Group",
    width: "250px",
  },
  {
    name: "Created Date",
    width: "150px",
  },
  {
    name: "Last modified",
    width: "150px",
  },
];

export const CompanyManagement = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { GET_COMPANY_LIST } = config.api_endpoint;
  const [companyData, setCompanyData] = useState([]);
  const [activePages, setActivePages] = useState(1);
  const { companyList, loading } = useSelector((state) => state.companyList);

  const [showFilter, setShowFilter] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [filterSJD, setFilterSJD] = useState();
  const [filterEJD, setFilterEJD] = useState();
  const [filterText, setFilterText] = useState("");

  const [newPage, setNewPage] = useState(false);

  const handleSearchIconClicked = (e) => {
    setShowSearch(!showSearch);
  };

  const applyFilters = () => {
    setCompanyData([]);
    console.log("filterSJD:", filterSJD);
    console.log("filterEJD:", filterEJD);
    console.log("filterText:", filterText);

    dispatch(
      fetchALLCompany({
        page: 1,
        page_size: 20,
        start_created_date: filterSJD
          ? `${moment(filterSJD).format("YYYY-MM-DD")} ${new Date(
              filterSJD
            ).getHours()}:${new Date(filterSJD).getMinutes()}:${new Date(
              filterSJD
            ).getSeconds()}`
          : "",
        end_created_date: filterEJD
          ? `${moment(filterEJD).format("YYYY-MM-DD")} ${new Date(
              filterEJD
            ).getHours()}:${new Date(filterEJD).getMinutes()}:${new Date(
              filterEJD
            ).getSeconds()}`
          : "",
        text: encodeURIComponent(filterText),
      })
    );

    setShowFilter(false);
  };

  const resetFilterValues = () => {
    setFilterSJD("");
    setFilterEJD("");
  };

  const onToggleDialog = () => {
    setShowFilter(false);
  };

  const handleStartDateClicked = (e) => {
    console.log("start date clicked: ", e);
    let date = e;
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    setFilterSJD(date);
  };

  const handleEndDateClicked = (e) => {
    console.log("end date clicked: ", e);
    let date = e;
    date.setHours(23);
    date.setMinutes(59);
    date.setSeconds(59);
    setFilterEJD(date);
  };

  const handleTableClick = (id) => {
    navigate(`${COMPANY_MANAGEMENT_DETAIL_ROUTE}?id=${id}`);
  };

  const handlePageChange = async (page) => {
    // setLoading(true);
    // setCompanyData((userData) => []);
    setActivePages(page + 1);
    const token = await getCurrentLoggedInSessionTokenWithPromise();
    const results = await api.get(
      GET_COMPANY_LIST,
      {
        page: page + 1,
        page_size: 20,
        start_created_date: filterSJD
          ? `${moment(filterSJD).format("YYYY-MM-DD")} ${new Date(
              filterSJD
            ).getHours()}:${new Date(filterSJD).getMinutes()}:${new Date(
              filterSJD
            ).getSeconds()}`
          : "",
        end_created_date: filterEJD
          ? `${moment(filterEJD).format("YYYY-MM-DD")} ${new Date(
              filterEJD
            ).getHours()}:${new Date(filterEJD).getMinutes()}:${new Date(
              filterEJD
            ).getSeconds()}`
          : "",
        text: encodeURIComponent(filterText),
      },
      token
    );
    const _company_data = [];

    for (let i = 0; i < results.data.items.length; i++) {
      _company_data.push({
        id: results.data.items[i].id,
        item1: results.data.items[i].company_name,
        item2: results.data.items[i].member_count,
        item3: moment(results.data.items[i].created_date).format("DD MMM YYYY"),
        item4: results.data.items[i].updated_date
          ? moment(results.data.items[i].updated_date).format("DD MMM YYYY")
          : "-",
      });
    }
    setNewPage(results.data.newPage);
    setCompanyData([...companyData, ..._company_data]);

    // dispatch(
    //   fetchALLCompany({
    //     page: page,
    //     page_size: 50,
    //     start_created_date: filterSJD
    //       ? `${moment(filterSJD).format("YYYY-MM-DD")} ${new Date(
    //           filterSJD
    //         ).getHours()}:${new Date(filterSJD).getMinutes()}:${new Date(
    //           filterSJD
    //         ).getSeconds()}`
    //       : "",
    //     end_created_date: filterEJD
    //       ? `${moment(filterEJD).format("YYYY-MM-DD")} ${new Date(
    //           filterEJD
    //         ).getHours()}:${new Date(filterEJD).getMinutes()}:${new Date(
    //           filterEJD
    //         ).getSeconds()}`
    //       : "",
    //     text: encodeURIComponent(filterText),
    //   })
    // );
  };

  useEffect(() => {
    dispatch(
      fetchALLCompany({
        page: 1,
        page_size: 20,
      })
    );

    return () => {
      dispatch(resetCompanyList());
    };
  }, []);

  useEffect(() => {
    const _company_data = [];

    if (
      !loading &&
      companyList.companyList &&
      companyList.companyList.items.length > 0
    ) {
      for (let i = 0; i < companyList.companyList.items.length; i++) {
        _company_data.push({
          id: companyList.companyList.items[i].id,
          item1: companyList.companyList.items[i].company_name,
          item2: companyList.companyList.items[i].member_count,
          item3: moment(companyList.companyList.items[i].created_date).format(
            "DD MMM YYYY"
          ),
          item4: companyList.companyList.items[i].updated_date
            ? moment(companyList.companyList.items[i].updated_date).format(
                "DD MMM YYYY"
              )
            : "-",
        });
      }
      setNewPage(companyList.companyList.newPage);
    }
    setCompanyData([..._company_data]);
    console.log("_company_data:", _company_data);
  }, [companyList]);

  return (
    <>
      {showFilter && (
        <Dialog>
          <div className="company-filter-width_resize">
            <div
              style={{
                position: "absolute",
                top: "15px",
                right: "13px",
                cursor: "pointer",
              }}
              onClick={onToggleDialog}
            >
              <img src={CrossIcon} />
            </div>
            <div className="company-filter-title">Filter</div>
            <div className="company-filter-date-wrapper">
              <div>
                <span className="company-filter-date-label">
                  Created From Date
                </span>
                {/* <DatePicker
                  defaultValue={filterSJD}
                  defaultShow={false}
                  value={filterSJD}
                  format={"dd MMM yyyy"}
                  onChange={handleStartDateClicked}
                  className="company-filter-date-input"
                /> */}
                <CustomDatePicker
                  value={filterSJD}
                  onChange={handleStartDateClicked}
                  format={"DD MMM yyyy"}
                />
              </div>
              <div>
                <span className="company-filter-date-label">
                  Created To Date
                </span>
                {/* <DatePicker
                  defaultValue={filterEJD}
                  defaultShow={false}
                  value={filterEJD}
                  format={"dd MMM yyyy"}
                  onChange={handleEndDateClicked}
                  className="company-filter-date-input"
                /> */}
                <CustomDatePicker
                  value={filterEJD}
                  onChange={handleEndDateClicked}
                  format={"DD MMM yyyy"}
                />
              </div>
              <div></div>
            </div>
            <div className="company-filter-button-group">
              <Button
                className="approval-cancel-button"
                onClick={() => resetFilterValues()}
              >
                Clear
              </Button>
              <Button
                className="company-filter-button"
                onClick={() => {
                  applyFilters();
                }}
              >
                Apply
              </Button>
            </div>
          </div>
        </Dialog>
      )}
      {loading ? (
        <Loader
          type="infinite-spinner"
          style={{
            display: "flex",
            alignItems: "center",
            width: "40px",
            margin: "auto",
            display: "flex",
            justifyContent: "center",
            height: "100vh",
          }}
        />
      ) : (
        <div>
          <div className="Company_toolbar">
            <div className="Company_toolbar_title">Company Management</div>
            <div className="Company_toolbar_child_box">
              <div
                className="Company_toolbar_add_button"
                onClick={() => navigate(COMPANY_MANAGEMENT_CREATE_ROUTE)}
              >
                <div>+</div>
                <div>Add</div>
              </div>
              <div
                className="Company_toolbar_filter_button"
                onClick={() => setShowFilter(true)}
              >
                <img src={FilterIcon} />
              </div>
              <div
                className="Company_toolbar_filter_button"
                onClick={handleSearchIconClicked}
              >
                <img src={SearchIcon} />
              </div>
            </div>
          </div>
          {showSearch && (
            <div className="Company_selection_bar">
              <Input
                value={filterText}
                onChange={(e) => setFilterText(e.target.value)}
                placeholder="Search..."
                onKeyDown={(e) => e.key === "Enter" && applyFilters()}
              />
              <div
                className="Company_toolbar_add_button"
                onClick={() => {
                  applyFilters();
                }}
              >
                <div>Search</div>
              </div>
            </div>
          )}
          {companyData.length > 0 ? (
            <Table
              header={header}
              data={companyData}
              handleTableClick={handleTableClick}
              handlePageChange={handlePageChange}
              activePages={activePages}
              showSearch={showSearch}
              hasMore={newPage}
            />
          ) : (
            <p style={{ textAlign: "center" }}>No records found.</p>
          )}
          {/* <Pagination
            pageCount={
              companyList.companyList && companyList.companyList.total_pages
            }
          /> */}
        </div>
      )}
    </>
  );
};
