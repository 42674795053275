import React, { useRef, useState } from "react";
import "./OHS.css";
import UploadIcon from "../../assets/images/Icon/upload.svg";
import { Loader } from "@progress/kendo-react-all";
import config from "../../config/config";
import { getCurrentLoggedInSessionTokenWithPromise } from "../../config/userPool";
import api from "../../config/api";

export const OHS = () => {
  const OHSFIle = useRef();
  const { OHS_FILE_ENDPOINT, OHS_FILE_UPLOAD } = config.api_endpoint;
  const [fileUpload, setFileUpload] = useState(false);
  const [showPopup, setShowPopup] = useState(false);

  const uploadFile = () => {
    OHSFIle.current.click();
  };

  const onUploadOHSFile = async (e) => {
    console.log(e.target.files[0]);
    setFileUpload(true);
    const token = await getCurrentLoggedInSessionTokenWithPromise();
    const data = e.target.files[0];
    const formData = new FormData();
    const fileType = data.type.toLowerCase();
    let attachType =
      fileType.indexOf("image") === 0
        ? "image"
        : fileType.indexOf("video") === 0
        ? "video"
        : "file";

    api
      .post(
        OHS_FILE_UPLOAD,
        JSON.stringify({
          attach_type: attachType,
          filename: data.name,
        }),
        token
      )
      .then((res) => {
        console.log(res);
        const formDataUpload = new FormData();
        for (let k in res.data.payload) {
          formDataUpload.append(k, res.data.payload[k]);
        }
        formDataUpload.append("file", data);
        console.log(res);
        api
          .postDiff(res.data.bucket_url, formDataUpload)
          .then((res1) => {
            if (res1 === undefined) {
              console.log(res1);
              console.log(res.data.url.split("/").slice(-2).join("/"));
              api
                .post(
                  OHS_FILE_ENDPOINT,
                  JSON.stringify({
                    filename: res.data.url.split("/").slice(-2).join("/"),
                  }),
                  token
                )
                .then((res2) => {
                  console.log(res2);
                  setFileUpload(false);
                  setShowPopup(false);
                });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      });
  };
  return (
    <div>
      <div className="OHS_toolbar">
        <div className="OHS_toolbar_title">OHS Management</div>
        {/* <div className="ECM_toolbar_child_box">
          <div
            className="ECM_toolbar_add_button"
            onClick={() => navigate(ECM_CREATE_ROUTE)}
          >
            <div>+</div>
            <div>Add</div>
          </div>
          <div
            className="ECM_toolbar_filter_button"
            onClick={() => setShowFilter(true)}
          >
            <img src={FilterIcon} />
          </div>
          <div
            className="ECM_toolbar_filter_button"
            // onClick={handleSearchIconClicked}
          >
            <img src={SearchIcon} />
          </div>
        </div> */}
      </div>
      <div>
        <div
          onClick={() => {
            //   AddAttachment("MANDATORY", data.id, i.id);
            uploadFile();
          }}
          className="OHS_Upload_main_box"
          onMouseEnter={() => {
            setShowPopup(true);
          }}
          onMouseLeave={() => {
            setShowPopup(false);
          }}
        >
          <div>Upload OHS</div>
          <div>
            <input
              accept={[
                //   "image/jpeg",
                //   "image/png",
                //   "image/jpg",
                //   "application/pdf",
                //   "application/msword",
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                "application/vnd.ms-excel",
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                "application/vnd.openxmlformats-officedocument.presentationml.presentation",
              ]}
              ref={OHSFIle}
              type="file"
              hidden
              onClick={(e) => (e.target.value = "")}
              onChange={(e) => onUploadOHSFile(e)}
            />
            <>
              {fileUpload ? (
                <>
                  <div
                    style={{
                      position: "relative",
                    }}
                  >
                    {showPopup && (
                      <div className="OHS_popup_main_box">
                        <div className="OHS_popup_text_box">
                          File is uploading. Please wait!
                        </div>
                        <div className="OHS_popup_tips" />
                      </div>
                    )}
                    <Loader />
                  </div>
                </>
              ) : (
                <span
                  className="form-upload-btn"
                  style={{
                    cursor: "pointer",
                  }}
                >
                  <img src={UploadIcon} />
                </span>
              )}
            </>
          </div>
        </div>
      </div>
    </div>
  );
};
