import React, { useEffect, useState } from "react";
import FilterIcon from "../../assets/images/Icon/FilterIcon.svg";
import "./mapLayer.css";
import { useNavigate } from "react-router-dom";
import { MAP_LAYER_CREATE_ROUTE, MAP_LAYER_DETAIL_ROUTE } from "../../routes";
import { useDispatch, useSelector } from "react-redux";
import config from "../../config/config";
import { fetchAllMapLayer, resetMapLayerList } from "../../store/mapLayerSlice";
import { Table } from "../../components/table/table";
import { Dialog, DropDownList, Input, Loader } from "@progress/kendo-react-all";
import { getCurrentLoggedInSessionTokenWithPromise } from "../../config/userPool";
import api from "../../config/api";
import SearchIcon from "../../assets/images/Icon/SearchIcon.svg";
import CrossIcon from "../../assets/images/Icon/Cross_black.svg";
import moment from "moment";

const header = [
  {
    name: "Map Layer Name",
  },
  {
    name: "Image Name",
  },
  {
    name: "Required in Case report",
  },
  {
    name: "Required in work declaration",
  },
  {
    name: "Required in OHS",
  },
  {
    name: "Last Modified",
  },
];

export const MapLayerManagment = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { GET_MAPLAYER } = config.api_endpoint;
  const { mapLayerList, loading } = useSelector((state) => state.mapLayerList);
  const { general_config_data } = useSelector((state) => state.generalConfig);

  const [newPage, setNewPage] = useState(false);
  const [mapLayerData, setMapLayerData] = useState([]);
  const [activePages, setActivePages] = useState(1);
  const [showSearch, setShowSearch] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [filtered, setFiltered] = useState(false);
  const [filterLoading, setFilterLoading] = useState(false);
  const [filterData, setFilterData] = useState({
    is_casereport: "All",
    is_wdc: "All",
    is_ohs: "All",
    text: "",
    // source_available: false,
  });

  useEffect(() => {
    dispatch(
      fetchAllMapLayer({
        page: 1,
        page_size: 20,
      })
    );
    return () => {
      dispatch(resetMapLayerList());
    };
  }, []);

  useEffect(() => {
    const _maplayer_data = [];
    if (
      !loading &&
      mapLayerList.mapLayerList &&
      mapLayerList.mapLayerList.items.length > 0
    ) {
      for (let i = 0; i < mapLayerList.mapLayerList.items.length; i++) {
        _maplayer_data.push({
          id: mapLayerList.mapLayerList.items[i].id,
          item1: mapLayerList.mapLayerList.items[i].layer_name,
          item2: mapLayerList.mapLayerList.items[i].image_name,
          item3: mapLayerList.mapLayerList.items[i].require_in_casereport
            ? "Yes"
            : "No",
          item4: mapLayerList.mapLayerList.items[i].require_in_wdc
            ? "Yes"
            : "No",
          item5: mapLayerList.mapLayerList.items[i].require_in_ohs
            ? "Yes"
            : "No",
          item6: mapLayerList.mapLayerList.items[i].modified_at
            ? moment(mapLayerList.mapLayerList.items[i].modified_at).format(
                "DD MMM YYYY"
              )
            : mapLayerList.mapLayerList.items[i].created_at
            ? moment(mapLayerList.mapLayerList.items[i].created_at).format(
                "DD MMM YYYY"
              )
            : "-",
        });
      }
      setNewPage(mapLayerList.mapLayerList.newPage);
    }
    setMapLayerData(_maplayer_data);
  }, [mapLayerList]);

  const handleTableClick = (id) => {
    navigate(`${MAP_LAYER_DETAIL_ROUTE}?id=${id}`);
  };

  const handlePageChange = async (page) => {
    setActivePages(page + 1);
    console.log(page);
    const token = await getCurrentLoggedInSessionTokenWithPromise();
    let APIParams = {};
    if (filtered) {
      APIParams = {
        data_type: "MapLayers",
        statistics: {
          page: page + 1,
          pageSize: 20,
          //   filter_packages: filterData.filterPkg.join(","),
          //   filter_source_available: filterData.source_available ? 1 : 0,
        },
      };
    } else {
      APIParams = {
        data_type: "MapLayers",
        statistics: {
          page: page + 1,
          pageSize: 20,
        },
      };
    }
    const results = await api.post(
      GET_MAPLAYER,
      JSON.stringify(APIParams),
      token
    );
    const _maplayer_data = [];
    for (let i = 0; i < results.data.items.length; i++) {
      _maplayer_data.push({
        id: mapLayerList.mapLayerList.items[i].id,
        item1: mapLayerList.mapLayerList.items[i].layer_name,
        item2: mapLayerList.mapLayerList.items[i].image_name,
        item3: mapLayerList.mapLayerList.items[i].require_in_casereport
          ? "Yes"
          : "No",
        item4: mapLayerList.mapLayerList.items[i].require_in_wdc ? "Yes" : "No",
        item5: mapLayerList.mapLayerList.items[i].require_in_ohs ? "Yes" : "No",
        item6: mapLayerList.mapLayerList.items[i].modified_at
          ? moment(mapLayerList.mapLayerList.items[i].modified_at).format(
              "DD MMM YYYY"
            )
          : mapLayerList.mapLayerList.items[i].created_at
          ? moment(mapLayerList.mapLayerList.items[i].created_at).format(
              "DD MMM YYYY"
            )
          : "-",
      });
    }
    setNewPage(results.data.newPage);
    setMapLayerData([...mapLayerData, ..._maplayer_data]);
  };

  const FilterReset = () => {
    setShowFilter(false);
    setFilterData({
      ...filterData,
      is_casereport: "All",
      is_wdc: "All",
      is_ohs: "All",
      text: "",
    });
    setShowSearch(false);
    dispatch(
      fetchAllMapLayer({
        page: 1,
        page_size: 20,
      })
    );
  };

  const FilterApply = async () => {
    console.log(filterData);
    setShowFilter(false);
    setFilterLoading(true);
    const token = await getCurrentLoggedInSessionTokenWithPromise();
    let filterParams = {
      data_type: "MapLayers",
      statistics: {
        page: 1,
        pageSize: 99999,
      },
    };
    if (filterData.is_casereport !== "All") {
      filterParams = {
        ...filterParams,
        statistics: {
          ...filterParams.statistics,
          filter_require_in_casereport:
            filterData.is_casereport === "Yes" ? 1 : 0,
        },
      };
    }
    if (filterData.is_wdc !== "All") {
      filterParams = {
        ...filterParams,
        statistics: {
          ...filterParams.statistics,
          filter_require_in_wdc: filterData.is_wdc === "Yes" ? 1 : 0,
        },
      };
    }
    if (filterData.is_ohs !== "All") {
      filterParams = {
        ...filterParams,
        statistics: {
          ...filterParams.statistics,
          filter_require_in_ohs: filterData.is_ohs === "Yes" ? 1 : 0,
        },
      };
    }

    if (filterData.text !== "") {
      filterParams = {
        ...filterParams,
        statistics: {
          ...filterParams.statistics,
          filter_layer_name: filterData.text,
        },
      };
    }
    console.log(filterParams);
    const results = await api.post(
      GET_MAPLAYER,
      JSON.stringify(filterParams),
      token
    );

    const _maplayer_data = [];
    for (let i = 0; i < results.data.items.length; i++) {
      _maplayer_data.push({
        id: mapLayerList.mapLayerList.items[i].id,
        item1: mapLayerList.mapLayerList.items[i].layer_name,
        item2: mapLayerList.mapLayerList.items[i].image_name,
        item3: mapLayerList.mapLayerList.items[i].require_in_casereport
          ? "Yes"
          : "No",
        item4: mapLayerList.mapLayerList.items[i].require_in_wdc ? "Yes" : "No",
        item5: mapLayerList.mapLayerList.items[i].require_in_ohs ? "Yes" : "No",
        item6: mapLayerList.mapLayerList.items[i].modified_at
          ? moment(mapLayerList.mapLayerList.items[i].modified_at).format(
              "DD MMM YYYY"
            )
          : mapLayerList.mapLayerList.items[i].created_at
          ? moment(mapLayerList.mapLayerList.items[i].created_at).format(
              "DD MMM YYYY"
            )
          : "-",
      });
    }
    setNewPage(results.data.newPage);
    setMapLayerData([..._maplayer_data]);
    setFilterLoading(false);
    console.log(results);
  };

  console.log(mapLayerData);

  return (
    <>
      {showFilter && (
        <Dialog>
          <div className="MapLayer-filter-width_resize">
            <div
              style={{
                position: "absolute",
                top: "15px",
                right: "13px",
                cursor: "pointer",
              }}
              onClick={() => setShowFilter(false)}
            >
              <img src={CrossIcon} />
            </div>
            <div className="MapLayer-filter-title" style={{ width: "400px" }}>
              Filter
            </div>
            <div>
              <div className="MapLayer-filter-sub-title">
                Required Casereport
              </div>
              <div className="MapLayer-filter-box">
                <DropDownList
                  placeholder="Select"
                  data={["All", "Yes", "No"]}
                  className="MapLayer-dropdown"
                  value={filterData.is_casereport}
                  onChange={(e) =>
                    setFilterData({
                      ...filterData,
                      is_casereport: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div>
              <div className="MapLayer-filter-sub-title">
                Required Work Declaration
              </div>
              <div className="MapLayer-filter-box">
                <DropDownList
                  placeholder="Select"
                  data={["All", "Yes", "No"]}
                  className="MapLayer-dropdown"
                  value={filterData.is_wdc}
                  onChange={(e) =>
                    setFilterData({
                      ...filterData,
                      is_wdc: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div>
              <div className="MapLayer-filter-sub-title">Required OHS</div>
              <div className="MapLayer-filter-box">
                <DropDownList
                  placeholder="Select"
                  data={["All", "Yes", "No"]}
                  className="MapLayer-dropdown"
                  value={filterData.is_ohs}
                  onChange={(e) =>
                    setFilterData({
                      ...filterData,
                      is_ohs: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="MapLayer_Filter_button_main_box">
              <div
                className="MapLayer_Filter_clear_button"
                onClick={FilterReset}
              >
                Clear
              </div>
              <div
                className="MapLayer_Filter_apply_button"
                onClick={FilterApply}
              >
                Apply
              </div>
            </div>
          </div>
        </Dialog>
      )}
      {loading ? (
        <Loader
          type="infinite-spinner"
          style={{
            display: "flex",
            alignItems: "center",
            width: "40px",
            margin: "auto",
            justifyContent: "center",
            height: "100vh",
          }}
        />
      ) : (
        <div>
          <div className="MapLayer_toolbar">
            <div className="MapLayer_toolbar_title">Map Layer Management</div>
            <div className="MapLayer_toolbar_child_box">
              <div
                className="MapLayer_toolbar_add_button"
                onClick={() => navigate(MAP_LAYER_CREATE_ROUTE)}
              >
                <div>+</div>
                <div>Add</div>
              </div>
              <div
                className="MapLayer_toolbar_filter_button"
                onClick={() => setShowFilter(true)}
              >
                <img src={FilterIcon} />
              </div>
              <div
                className="MapLayer_toolbar_filter_button"
                onClick={() => setShowSearch(!showSearch)}
              >
                <img src={SearchIcon} />
              </div>
            </div>
          </div>
          {showSearch && (
            <div className="MapLayer_selection_bar">
              <Input
                value={filterData.text}
                onChange={(e) =>
                  setFilterData({ ...filterData, text: e.target.value })
                }
                placeholder="Search..."
                onKeyDown={(e) => e.key === "Enter" && FilterApply()}
              />
              <div
                className="MapLayer_toolbar_add_button"
                onClick={() => {
                  FilterApply();
                }}
              >
                <div>Search</div>
              </div>
            </div>
          )}
          {filterLoading ? (
            <Loader
              type="infinite-spinner"
              style={{
                display: "flex",
                alignItems: "center",
                width: "40px",
                margin: "auto",
                justifyContent: "center",
                height: "100vh",
              }}
            />
          ) : (
            <>
              {mapLayerData.length > 0 ? (
                <Table
                  header={header}
                  data={mapLayerData}
                  handleTableClick={handleTableClick}
                  handlePageChange={handlePageChange}
                  activePages={activePages}
                  // showSearch={showSearch}
                  hasMore={newPage}
                />
              ) : (
                <p style={{ textAlign: "center" }}>No records found.</p>
              )}
            </>
          )}
        </div>
      )}
    </>
  );
};
