import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import validator from "validator";
// react
import { Input } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
// api
import api from "../../config/api";
import config from "../../config/config";
// component
import FullscreenLoader from "../../components/fullScreenLoader";
// icon
import Hide from "../../assets/images/Icon/hide.svg";
import Unhide from "../../assets/images/Icon/unhide.svg";
// css
import "./auth.css";
import {
  getCurrentLoggedInSessionTokenWithPromise,
  getCurrentLoggedInUserWithPromise,
  signout,
} from "../../config/userPool";

const ChangePassword = () => {
  const navigate = useNavigate();
  const passwordValidationMessage =
    "Please enter at least 8 chars of a mix of lower/upper case letters, numbers and special chars";
  const [loading, setLoading] = useState(false);
  const { CHANGE_PASSWORD, SAVE_USER_DEVICE_TOKEN_ENDPOINT, CHECK_PASSWORD } =
    config.api_endpoint;

  const [errorText, setErrorText] = useState(null);
  const [email, setEmail] = useState("");
  const [hideCurrentPassword, setHideCurrentPassword] = useState(true);
  const [hideNewPassword, setHideNewPassword] = useState(true);
  const [hideCfmNewPassword, setHideCfmNewPassword] = useState(true);
  const [newPassword, setNewPassword] = useState("");
  const [newCfmPassword, setNewCfmPassword] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [popupMsg, setPopupMsg] = useState("");
  const [open, setOpen] = useState(false);
  const [errors, setErrors] = useState({});

  const logout = async () => {
    const token = await getCurrentLoggedInSessionTokenWithPromise();
    signout();

    localStorage.removeItem("login");
    localStorage.removeItem("username");
    localStorage.removeItem("session_id");

    if (localStorage.getItem("deviceToken_id")) {
      api
        .remove(
          SAVE_USER_DEVICE_TOKEN_ENDPOINT,
          JSON.stringify({
            device_id: localStorage.getItem("deviceToken_id"),
          }),
          token
        )
        .then((res) => {
          console.log("delete device token result: ", res);
          localStorage.removeItem("deviceToken_id");
        })
        .catch((err) => {
          console.log("delete device token err", err);
          localStorage.removeItem("deviceToken_id");
        });
    }

    localStorage.removeItem("shouldLogout");
    window.location.href = "/";
    localStorage.setItem("redirect_to", window.location.href);
  };

  const validateInputs = (data) => {
    const _errors = {};
    if (data["password"] && data["new_password"]) {
      if (data["password"] != data["new_password"]) {
        _errors["password"] = "Passwords do not match.";
        _errors["new_password"] = "Passwords do not match.";
      } else {
        if (
          !validator.isStrongPassword(data["password"], {
            minLength: 8,
            minLowercase: 1,
            minUppercase: 1,
            minNumbers: 1,
            minSymbols: 1,
          })
        ) {
          _errors["password"] =
            "Please enter at least 8 chars of a mix of lower/upper case letters, numbers and special chars";
        }

        if (
          !validator.isStrongPassword(data["new_password"], {
            minLength: 8,
            minLowercase: 1,
            minUppercase: 1,
            minNumbers: 1,
            minSymbols: 1,
          })
        ) {
          _errors["new_password"] =
            "Please enter at least 8 chars of a mix of lower/upper case letters, numbers and special chars";
        }
      }
    } else if (data["password"] && !data["new_password"]) {
      if (
        !validator.isStrongPassword(data["password"], {
          minLength: 8,
          minLowercase: 1,
          minUppercase: 1,
          minNumbers: 1,
          minSymbols: 1,
        })
      ) {
        _errors["password"] =
          "Please enter at least 8 chars of a mix of lower/upper case letters, numbers and special chars";
      }
      _errors["new_password"] = "This field is required.";
    } else if (!data["password"] && data["new_password"]) {
      if (
        !validator.isStrongPassword(data["new_password"], {
          minLength: 8,
          minLowercase: 1,
          minUppercase: 1,
          minNumbers: 1,
          minSymbols: 1,
        })
      ) {
        _errors["new_password"] =
          "Please enter at least 8 chars of a mix of lower/upper case letters, numbers and special chars";
      }
      _errors["password"] = "This field is required.";
    } else if (data["password"]) {
      if (
        !validator.isStrongPassword(data["password"], {
          minLength: 8,
          minLowercase: 1,
          minUppercase: 1,
          minNumbers: 1,
          minSymbols: 1,
        })
      ) {
        _errors["password"] =
          "Please enter at least 8 chars of a mix of lower/upper case letters, numbers and special chars";
      }
    } else if (data["new_password"]) {
      if (
        !validator.isStrongPassword(data["new_password"], {
          minLength: 8,
          minLowercase: 1,
          minUppercase: 1,
          minNumbers: 1,
          minSymbols: 1,
        })
      ) {
        _errors["new_password"] =
          "Please enter at least 8 chars of a mix of lower/upper case letters, numbers and special chars";
      }
    }

    return _errors;
  };

  const handleChangePassword = async () => {
    console.log("Reset Password Clicked.");
    setErrorText(null);
    const errs = validateInputs({
      password: newPassword,
      new_password: newCfmPassword,
    });
    setErrors(errs);
    console.log(errs);
    if (errs.password || errs.new_password) return;
    if (!email || !newPassword || !newCfmPassword || !currentPassword) {
      setErrorText("Please enter all the required information");
    }
    // else if (newPassword != newCfmPassword) {
    //   setErrorText("Password mismatched");
    // } else if (
    //   !validator.isStrongPassword(newPassword, {
    //     minLength: 8,
    //     minLowercase: 1,
    //     minUppercase: 1,
    //     minNumbers: 1,
    //     minSymbols: 1,
    //   })
    // ) {
    //   setErrorText(passwordValidationMessage);
    // }
    else {
      setLoading(true);

      const results = await api.post(
        CHECK_PASSWORD,
        JSON.stringify({
          username: email,
          password: newPassword,
        })
      );
      console.log("Check Password Result:", results);
      if (results.data === false) {
        setLoading(false);
        setPopupMsg(
          "Please choose a password that you have not used before. To help protect your account, choose a new password every time you reset it."
        );
        setOpen(true);
        setTimeout(() => {
          setOpen(false);
        }, 3000);
      } else {
        let token = await getCurrentLoggedInSessionTokenWithPromise();
        let currentLoginUser = await getCurrentLoggedInUserWithPromise();
        api
          .post(
            CHANGE_PASSWORD,
            JSON.stringify({
              username: currentLoginUser["sub"],
              new_password: newPassword,
              old_password: currentPassword,
            }),
            token
          )
          .then((res) => {
            console.log("change password result", res);
            setLoading(false);
            if (res["status_code"] >= 200 && res["status_code"] < 300) {
              setPopupMsg(
                "Successfully changed password. You will need to login again."
              );
              setOpen(true);
              setTimeout(() => {
                setOpen(false);
                logout();
              }, 3000);
            } else {
              setPopupMsg(res["details"]);
              setOpen(true);
              //            setTimeout(() => {
              //              setOpen(false);
              //            }, 3000);
            }
          })
          .catch((err) => {
            console.log("change password err", err);
            setLoading(false);
            setPopupMsg("Error encountered.");
            setOpen(true);
            //          setTimeout(() => {
            //            setOpen(false);
            //          }, 3000);
          });
      }
    }
  };

  useEffect(() => {
    async function getData() {
      let currentLoginUser = await getCurrentLoggedInUserWithPromise();
      if (currentLoginUser) {
        setEmail(currentLoginUser["email"]);
      }
    }
    getData();
  }, []);

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      {loading && <FullscreenLoader />}
      {open && (
        <Dialog title={""} onClose={() => setOpen(false)}>
          <div
            style={{
              textAlign: "center",
              marginLeft: "30px",
              marginRight: 30,
            }}
          >
            <h4>{popupMsg}</h4>
            <p>
              <button
                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                style={{ width: "300px" }}
                onClick={() => setOpen(false)}
              >
                Close
              </button>
            </p>
          </div>
        </Dialog>
      )}
      <div className="forgot_media_screen">
        <div
          style={{ padding: "0px 16px", marginTop: "26px" }}
          className="forgot_detail_box"
        >
          <p className="Forgot_text">
            Please enter your new password twice so we can verify you typed it
            in correctly.
          </p>
          <div
            className="mb-3"
            style={{ position: "relative", marginTop: "32px" }}
          >
            <p className="input_label">Email</p>
            <Input
              name="email"
              style={{
                width: "100%",
                borderRadius: "8px",
                border: "1px solid #E4E1DE",
              }}
              placeholder="Enter your email here"
              pattern={"[A-Za-z]+"}
              minLength={2}
              required={true}
              value={email}
              onChange={(e) => setEmail(e.target.value.replaceAll(" ", ""))}
              disabled={true}
            />
          </div>
          <div
            className="mb-3"
            style={{ position: "relative", marginTop: "32px" }}
          >
            <div
              className="icon"
              onClick={() => {
                setHideCurrentPassword(!hideCurrentPassword);
              }}
              style={{
                backgroundImage: hideCurrentPassword
                  ? `url(${Unhide})`
                  : `url(${Hide})`,
                cursor: "pointer",
              }}
            ></div>
            <div>
              <p className="input_label">Current Password</p>
              <Input
                name="verification_code"
                type={hideCurrentPassword ? "password" : "text"}
                style={{
                  width: "100%",
                  borderRadius: "8px",
                  border: "1px solid #E4E1DE",
                }}
                placeholder="Enter current password here"
                required={true}
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
              />
            </div>
          </div>
          <div
            className="mb-3"
            style={{ position: "relative", marginTop: "32px" }}
          >
            <div
              className="icon"
              onClick={() => {
                setHideNewPassword(!hideNewPassword);
              }}
              style={{
                backgroundImage: hideNewPassword
                  ? `url(${Unhide})`
                  : `url(${Hide})`,
                cursor: "pointer",
              }}
            ></div>
            <div>
              <p className="input_label">New Password</p>
              <Input
                name="new_password"
                type={hideNewPassword ? "password" : "text"}
                style={{
                  width: "100%",
                  borderRadius: "8px",
                  border: "1px solid #E4E1DE",
                }}
                // label="Password"
                placeholder="Enter new password"
                required={true}
                minLength={8}
                validationMessage={passwordValidationMessage}
                autocomplete="off"
                onChange={(e) => {
                  setErrors(
                    validateInputs({
                      password: e.target.value,
                      new_password: newCfmPassword,
                    })
                  );
                  setNewPassword(e.target.value);
                }}
              />
              {errors && (
                <p className="error_text" style={{ textAlign: "left" }}>
                  {errors.password}
                </p>
              )}
            </div>
          </div>
          <div
            className="mb-3"
            style={{ position: "relative", marginTop: "32px" }}
          >
            <div
              className="icon"
              onClick={() => {
                setHideCfmNewPassword(!hideCfmNewPassword);
              }}
              style={{
                backgroundImage: hideCfmNewPassword
                  ? `url(${Unhide})`
                  : `url(${Hide})`,
                cursor: "pointer",
              }}
            ></div>
            <div>
              <p className="input_label">New Password Confirmation</p>
              <Input
                name="new_cfm_password"
                type={hideCfmNewPassword ? "password" : "text"}
                style={{
                  width: "100%",
                  borderRadius: "8px",
                  border: "1px solid #E4E1DE",
                }}
                // label="Password"
                placeholder="Enter new password"
                required={true}
                minLength={8}
                validationMessage={passwordValidationMessage}
                autocomplete="off"
                onChange={(e) => {
                  setErrors(
                    validateInputs({
                      password: newPassword,
                      new_password: e.target.value,
                    })
                  );
                  setNewCfmPassword(e.target.value);
                }}
              />
              {errors && (
                <p className="error_text" style={{ textAlign: "left" }}>
                  {errors.new_password}
                </p>
              )}
            </div>
          </div>
        </div>
        {errorText && <p className="error_text">{errorText}</p>}
        <div className="Button_group" style={{ justifyContent: "center" }}>
          <Button className="Button_submit" onClick={handleChangePassword}>
            Change My Password
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
