import { Input } from "@progress/kendo-react-inputs";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Delete from "../../../assets/images/Icon/delete.svg";
import "./work_activity.css";
import { getCurrentLoggedInSessionTokenWithPromise } from "../../../config/userPool";
import { Loader } from "@progress/kendo-react-all";
import api from "../../../config/api";

export const WorkActivityManagement = () => {
  // const { general_config_data } = useSelector((state) => state.generalConfig);
  // const { EDIT_PACKAGE } = config.api_endpoint;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [all_work_activity, setAll_work_activity] = useState([]);
  const [editMode, setEditMode] = useState(false);

  const filterWorkActivity = (value, id) => {
    all_work_activity.splice(id, 1);
    setAll_work_activity([...all_work_activity]);
  };

  const editWorkActivity = (value, id) => {
    all_work_activity[id] = value;
    setAll_work_activity([...all_work_activity]);
  };

  const callAPI = async () => {
    setLoading(true);
    const token = await getCurrentLoggedInSessionTokenWithPromise();
    api
      .put(
        "/api-admin/update-selection-list-partial",
        JSON.stringify({
          ["Type_of_Work_Activity"]: [
            ...all_work_activity
              .filter((i) => i !== "Others")
              .filter((j) => j !== null)
              .sort((a, b) => (a.toLowerCase() > b.toLowerCase() ? 1 : -1)),
            "Others",
          ],
        }),
        token
      )
      .then((res) => {
        if (res.status_code === 200) {
          setAll_work_activity([...res?.["Type of Work Activity"]]);
          setLoading(false);
        }
      });

    setEditMode(false);
  };

  const handleEdit = () => {
    if (editMode) {
      callAPI();
    }
    setEditMode(!editMode);
  };

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      const token = await getCurrentLoggedInSessionTokenWithPromise();
      api.get("/api-admin/get-selection-list", {}, token).then((res) => {
        console.log(res);
        if (
          res?.["Type of Work Activity"] &&
          res?.["Type of Work Activity"].length > 0
        ) {
          if (res?.["Type of Work Activity"]) {
            setAll_work_activity(res?.["Type of Work Activity"]);
            setLoading(false);
          }
        } else {
          setLoading(false);
          alert("Error getting hazards");
        }
      });
    };
    getData();
  }, []);

  return (
    <>
      {loading ? (
        <Loader
          type="infinite-spinner"
          style={{
            height: "80vh",
            display: "flex",
            alignItems: "center",
            width: "40px",
            margin: "auto",
            display: "flex",
            justifyContent: "center",
            minHeight: "100%",
          }}
        />
      ) : (
        <div className="work_activity_main_box">
          <div className="work_activity_title">Type of Work Activity</div>
          <div className="work_activity_child_box">
            {all_work_activity.map((i, id) => {
              if (editMode && i === "Others") {
                return "";
              } else {
                return (
                  <div key={id} className="work_activity_input_box">
                    <Input
                      style={{ borderRadius: "8px" }}
                      value={i}
                      disabled={editMode ? false : true}
                      onChange={(e) => editWorkActivity(e.target.value, id)}
                    />
                    {editMode && (
                      <div
                        className="work_activity_delete_icon"
                        onClick={() => filterWorkActivity(i, id)}
                      >
                        <img src={Delete} />
                      </div>
                    )}
                  </div>
                );
              }
            })}
          </div>
          <div style={{ position: "relative" }}>
            <div className="work_activity_footer_box">
              {editMode && (
                <div
                  className="work_activity_footer_button work_activity_footer_add_button"
                  onClick={() =>
                    setAll_work_activity([...all_work_activity, ""])
                  }
                >
                  Add
                </div>
              )}
              <div
                className="work_activity_footer_button work_activity_footer_edit_button"
                onClick={handleEdit}
                style={{
                  pointerEvents:
                    editMode &&
                    all_work_activity.find((i) => i === "") !== undefined
                      ? "none"
                      : "",
                  opacity:
                    editMode &&
                    all_work_activity.find((i) => i === "") !== undefined
                      ? "0.5"
                      : "",
                }}
              >
                {editMode ? "Save" : "Edit"}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
