import {
  Button,
  ComboBox,
  DatePicker,
  DropDownList,
  Loader,
  MultiSelect,
} from "@progress/kendo-react-all";
import { Dialog } from "@progress/kendo-react-dialogs";
import React, { useState } from "react";
import CrossIcon from "../../assets/images/Icon/cross_orange_icon.png";
import { useSelector } from "react-redux";
import { CustomDatePicker } from "../../components/datePicker/datePicker";

const UserExport = ({
  role,
  Package,
  closeExport,
  handleExport,
  exportData,
  handleExportAPI,
  exportLoading,
  exportError,
}) => {
  console.log(role);
  const [tempFilter, setTempFilter] = useState({
    package: [],
    user_group_name: [],
  });
  const [isTempFilter, setIsTempFilter] = useState({
    package: false,
    user_group_name: false,
  });
  const [loading, setLoading] = useState({
    package: false,
    user_group_name: false,
  });

  const filtering = (data, target, filterValue) => {
    return data.filter((i) =>
      target === "package"
        ? i.replace(/ /g, "").toLowerCase().includes(filterValue.toLowerCase())
        : i[target]
            .replace(/ /g, "")
            .toLowerCase()
            .includes(filterValue.toLowerCase())
    );
  };

  const filterData = (filter, data) => {
    const targetedField = filter.field === undefined ? "package" : filter.field;
    return filtering(data, targetedField, filter.value);
  };
  const timeout = React.useRef();
  const handleFilterData = (event, data) => {
    const targetedField =
      event.filter.field === undefined ? "package" : event.filter.field;
    console.log(event.filter);
    clearTimeout(timeout.current);
    timeout.current = setTimeout(() => {
      console.log(filterData(event.filter, data));
      setTempFilter({
        ...tempFilter,
        [targetedField]: filterData(event.filter, data),
      });
      // setLoading(false);
      setLoading({
        ...loading,
        [targetedField]: false,
      });
      setIsTempFilter({
        ...isTempFilter,
        [targetedField]: true,
      });
    }, 500);
    // setLoading(true);
    setLoading({
      ...loading,
      [targetedField]: true,
    });
  };
  return (
    <Dialog>
      <div className="user_export_export_main_box">
        <div className="user_export_cross_icon_box">
          <div
            className="user_export_cross_icon_button"
            onClick={() => (exportLoading ? "" : closeExport(false))}
            style={{ cursor: exportLoading && "progress" }}
          >
            <img src={CrossIcon} />
          </div>
        </div>
        <div className="user_export_title user_export_margin">Export</div>
        <div className="user_export_date_box">
          <div
            className="user_export_date_child_box"
            style={{
              marginBottom:
                exportData.start_joined_date !== "" ||
                exportData.end_joined_date !== ""
                  ? "22px"
                  : "",
            }}
          >
            <div className="user_export_title_child">
              Account Joined From Date
            </div>
            <div>
              {/* <DatePicker
                defaultValue={new Date()}
                defaultShow={false}
                format={"dd MMM yyyy"}
                className="user_export-date-input"
                disabled={exportLoading && true}
                onChange={(e) =>
                  handleExport(e.target.value, "start_joined_date")
                }
              /> */}
              <CustomDatePicker
                value={exportData.start_joined_date}
                disabled={exportLoading && true}
                onChange={(e) => handleExport(e, "start_joined_date")}
                format={"DD MMM yyyy"}
              />
              {exportError.startDate && (
                <div className="Work_error_text">
                  Start date should not be later than End Date!
                </div>
              )}
            </div>
          </div>
          <div
            className="user_export_date_child_box"
            style={{
              marginBottom:
                exportData.start_joined_date !== "" ||
                exportData.end_joined_date !== ""
                  ? "22px"
                  : "",
            }}
          >
            <div className="user_export_title_child">
              Account Joined To Date
            </div>
            <div>
              {/* <DatePicker
                defaultValue={new Date()}
                defaultShow={false}
                format={"dd MMM yyyy"}
                className="user_export-date-input"
                disabled={exportLoading && true}
                onChange={(e) =>
                  handleExport(e.target.value, "end_joined_date")
                }
              /> */}
              <CustomDatePicker
                value={exportData.end_joined_date}
                disabled={exportLoading && true}
                onChange={(e) => handleExport(e, "end_joined_date")}
                format={"DD MMM yyyy"}
              />
              {exportError.endDate && (
                <div className="Work_error_text">
                  End date should not be earlier than Start Date!
                </div>
              )}
            </div>
          </div>
        </div>
        <div
          // className="user_export_margin"
          style={{
            marginBottom: exportData.package.length !== 0 && "22px",
          }}
        >
          <div className="user_export_title_child">Package</div>
          <div>
            <MultiSelect
              onChange={(e) => handleExport(e.target.value, "package")}
              style={{
                width: "100%",
                marginTop: "10px",
              }}
              // data={[...Package]}
              data={isTempFilter.package ? tempFilter.package : [...Package]}
              value={exportData.package}
              disabled={exportLoading && true}
              filterable={true}
              loading={loading.package}
              onFilterChange={(e) => handleFilterData(e, [...Package])}
            />
            {exportError.package && (
              <div className="Work_error_text">Package field needed!</div>
            )}
          </div>
        </div>
        <div
          // className="user_export_margin"
          style={{
            marginBottom: exportData.user_group_name.length !== 0 && "22px",
          }}
        >
          <div className="user_export_title_child">Role</div>
          <div>
            <MultiSelect
              onChange={(e) => handleExport(e.target.value, "user_group_name")}
              style={{
                width: "100%",
                marginTop: "10px",
              }}
              // data={role}
              data={
                isTempFilter.user_group_name ? tempFilter.user_group_name : role
              }
              value={exportData.user_group_name}
              dataItemKey="id"
              textField="user_group_name"
              disabled={exportLoading && true}
              filterable={true}
              loading={loading.company_name}
              onFilterChange={(e) => handleFilterData(e, role)}
            />
            {exportError.user_group_name && (
              <div className="Work_error_text">Role field needed!</div>
            )}
          </div>
        </div>
        {exportLoading && (
          <div style={{ textAlign: "center" }}>
            <p style={{ fontStyle: "italic" }}>
              Preparing the file to download. Please wait...
            </p>
            <Loader type="infinite-spinner" />
          </div>
        )}
        <div className="user_export_margin user_export_button_box">
          <Button
            className="user_export_button"
            onClick={handleExportAPI}
            disabled={
              exportLoading
                ? // exportData.start_joined_date === "" ||
                  // exportData.end_joined_date === "" ||
                  // exportData.package.length === 0 ||
                  // exportData.role.length === 0 ||
                  // exportData.start_joined_date > exportData.end_joined_date
                  true
                : false
            }
          >
            Export
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default UserExport;
