// redux
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../config/api";
import config from "../config/config";

const { GET_CONFIGURATION_ENDPOINT } = config.api_endpoint;
const initialState = {
  general_config_loading: true,
  general_config_data: {},
  general_config_error: null,
};

export const fetchGeneralConfig = createAsyncThunk(
  "config/fetchGeneralConfig",
  async (_, { rejectWithValue }) => {
    try {
      const results = await api.get(
        GET_CONFIGURATION_ENDPOINT,
        {}
      );
      console.log('General Config Json:', results);
      return {
        general_config_data: results.data,
      };
    } catch (error) {
      return rejectWithValue(error.details);
    }
  }
);

const generalConfigSlice = createSlice({
  name: "config",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchGeneralConfig.pending, (state) => {
        state.general_config_loading = true;
      })
      .addCase(fetchGeneralConfig.fulfilled, (state, action) => {
        console.log(action);
        state.general_config_loading = false;
        state.general_config_data = action.payload;
      })
      .addCase(fetchGeneralConfig.rejected, (state, action) => {
        state.general_config_loading = false;
        state.general_config_error = action.error.message;
      });
  },
});

const generalConfigReducer = generalConfigSlice.reducer;
export default generalConfigReducer;
