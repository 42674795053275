import React, { useState } from "react";
import { Checkbox } from "@progress/kendo-react-inputs";
// icon
import TableFilter from "../../assets/images/Icon/unsorted.svg";
// css
import "./table.css";

export const SelectTable = (props) => {
  const [data, setData] = useState(props.data ? props.data : []);
  const [filtered, setFiltered] = useState(null);

  console.log("Table Items:", props.data);
  console.log("props.height:", props.height);
  console.log("props.cutHeaderHeight:", props.cutHeaderHeight);

  const handleFilter = (id) => {
    const sorting = data.sort((a, b) =>
      a[`item${id + 1}`] > b[`item${id + 1}`] ? 1 : -1
    );
    setData(() => [...sorting]);
  };
  return (
    <div
      style={{
        overflowY: "hidden",
        maxHeight:
          props.maxHeight > 0 && props.cutHeaderHeight == true
            ? "calc(100vh - " + props.maxHeight + ")"
            : props.maxHeight
            ? props.maxHeight
            : "calc(100vh - 320px)",
        overflowY: "scroll",
        width: props.width ? props.width : "initial",
        maxWidth: props.maxWidth ? props.maxWidth : "calc(100vw - 260px)",
        overflowX: "auto",
      }}
      className="table-select-custom"
    >
      {props.header && (
        <table>
          <thead
            style={{
              position: "sticky",
              top: "0",
              background: "#EFEFEF",
            }}
          >
            <tr style={{ height: "40px" }}>
              <th style={{ height: "40px", width: "40px" }}>
                <Checkbox
                  defaultChecked={data.headerCheckboxFlag}
                  onClick={props.headerCheckboxClicked}
                />
              </th>
              {props.header &&
                props.header.map((data, id) => (
                  <th
                    key={id}
                    style={{ cursor: "pointer", width: data.width }}
                    onClick={() => handleFilter(id)}
                  >
                    <div className="Table_header">
                      {data.name}
                      {data.filter !== undefined ? (
                        data.filter === true ? (
                          <img src={TableFilter} />
                        ) : (
                          ""
                        )
                      ) : (
                        <img src={TableFilter} />
                      )}
                    </div>
                  </th>
                ))}
            </tr>
          </thead>
          <tbody>
            {data.length > 0
              ? data.map((_data, id) => (
                  <tr
                    className="TableHandle"
                    key={Math.floor(Math.random() * 10000000)}
                    onClick={() =>
                      props.handleTableClick && props.handleTableClick(_data.id)
                    }
                  >
                    <td>
                      <Checkbox
                        defaultChecked={_data.selected}
                        id={`${_data.id ? _data.id : ""}`}
                        // data-id={`${_data.id}`}
                        onClick={props.rowCheckboxClicked}
                      />
                    </td>
                    {props.header &&
                      props.header.map((dat, i) => {
                        if (props.button) {
                          if (props.header.length - 1 > i)
                            return <td key={i}>{_data[`item${i + 1}`]}</td>;
                        } else {
                          return <td key={i}>{_data[`item${i + 1}`]}</td>;
                        }
                      })}
                    {props.button && (
                      <td style={{ minWidth: "150px", maxWidth: "155px" }}>
                        <div
                          className="Table_button"
                          onClick={() => {
                            if (props.buttonClick) props.buttonClick(_data.id);
                          }}
                        >
                          {props.button}
                        </div>
                      </td>
                    )}
                  </tr>
                ))
              : ""}
          </tbody>
        </table>
      )}
    </div>
  );
};
