// redux

import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";

import api from "../config/api";
import config from "../config/config";
import { getCurrentLoggedInSessionTokenWithPromise } from "../config/userPool";

const { GET_REGISTRATION_LIST } = config.api_endpoint;

const initialState = {
  loading: false,
  registrationList: [],
  error: null,
};

export const resetRegistrationList = createAction("RESET_ALL");

export const fetchALLRegistration = createAsyncThunk(
  "registration/fetchALLRegistration",
  async (_, { rejectWithValue }) => {
    try {
      const token = await getCurrentLoggedInSessionTokenWithPromise();
      const results = await api.get(
        GET_REGISTRATION_LIST,
        {
          page: _.page || 1,
          page_size: _.page_size || 10,
          start_created_date: _.start_created_date || "",
          end_created_date: _.end_created_date || "",
          package: _.package || "",
          role: _.role || "",
          status: _.status || "",
          text: _.text || "",
        },
        token
      );
      console.log(results);
      return {
        registrationList: results.data,
      };
    } catch (error) {
      return rejectWithValue(error.details);
    }
  }
);

const RegistrationSlice = createSlice({
  name: "registration",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchALLRegistration.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchALLRegistration.fulfilled, (state, action) => {
        console.log(action);
        state.loading = false;
        state.registrationList = action.payload;
      })
      .addCase(fetchALLRegistration.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(resetRegistrationList, () => initialState);
  },
});

const RegistrationReducer = RegistrationSlice.reducer;
export default RegistrationReducer;
