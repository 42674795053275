import React, { useEffect, useState } from "react";
import {
  CCTV_AUDIT_TRAIL,
  CCTV_DETAIL_ROUTE,
  CCTV_MANAGMENT,
} from "../../routes";
import { useLocation, useNavigate } from "react-router-dom";
import BackIcon from "../../assets/images/Icon/PreviousIcon.svg";
import "./cctv.css";
import { Input, RadioButton, TextArea } from "@progress/kendo-react-inputs";
import { CustomDatePicker } from "../../components/datePicker/datePicker";
import { CustomTimePicker } from "../../components/timePicker/timePicker";
import {
  getCurrentLoggedInSessionTokenWithPromise,
  getCurrentLoggedInUserWithPromise,
} from "../../config/userPool";
import config from "../../config/config";
import api from "../../config/api";
import { Dialog, DropDownList, Loader } from "@progress/kendo-react-all";
import { useDispatch, useSelector } from "react-redux";
import { fetchGeneralConfig } from "../../store/generalConfigSlice";
import CrossIcon from "../../assets/images/Icon/Cross_black.svg";
import moment from "moment";
import ConfirmMsg from "../../components/confirmMsg";

export const CCTVForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { general_config_data } = useSelector((state) => state.generalConfig);
  const { GET_CCTV, CREATE_UPDATE_CCTV } = config.api_endpoint;
  let { search } = useLocation();
  const query = new URLSearchParams(search);
  const id = query.get("id");
  const [all_packages, setAll_packages] = useState([
    ...general_config_data.general_config_data.package,
  ]);
  const [showDelete, setShowDelete] = useState(false);
  const [loading, setLoading] = useState(true);
  const [editMode, setEditMode] = useState(false);
  const [confirmBox, setConfirmBox] = useState(false);
  const [editData, setEditData] = useState({
    camera_no: "",
    camera_name: "",
    stream_name: "",
    package: "",
    camera_position_lat: "",
    camera_position_lng: "",
    description: "",
    location: "",
    map_id: "",
    nvr_id: "",
    nvr_ip: "",
    nvr_port: "",
    nvr_username: "",
    nvr_pwrd: "",
    cctv_url: "",
    remarks: "",
    // source_available: false,
    codec: "",
  });
  const [sourceAvailable, setSourceAvailable] = useState(false);
  const [modifiedData, setModifiedData] = useState({
    modified_at: "",
    modified_by: "",
  });
  const [showMessage, setShowMessage] = useState(false);

  useEffect(() => {
    // dispatch(fetchGeneralConfig());

    if (id) {
      getCCTVDetail();
    } else {
      setEditMode(true);
      setLoading(false);
    }
  }, [id]);

  const getCCTVDetail = async () => {
    const token = await getCurrentLoggedInSessionTokenWithPromise();
    const result = await api.post(
      GET_CCTV,
      JSON.stringify({
        data_type: "CCTV",
        statistics: {
          filter_camera_no: id,
        },
      }),
      token
    );
    console.log(result);
    if (result.status_code === 200) {
      setSourceAvailable(result.data.items[0].source_available);
      setEditData({
        ...editData,
        id: result.data.items[0].camera_no,
        camera_no: result.data.items[0].camera_no,
        camera_name: result.data.items[0].camera_name,
        package: result.data.items[0].package,
        camera_position_lat: result.data.items[0].lat,
        camera_position_lng: result.data.items[0].long,
        description: result.data.items[0].description,
        stream_name: result.data.items[0].stream_name
          ? result.data.items[0].stream_name
          : "",
        location: result.data.items[0].location
          ? result.data.items[0].location
          : "",
        map_id: result.data.items[0].map_id ? result.data.items[0].map_id : "",
        nvr_id: result.data.items[0].nvr_id ? result.data.items[0].nvr_id : "",
        nvr_ip: result.data.items[0].nvr_ip ? result.data.items[0].nvr_ip : "",
        nvr_port: result.data.items[0].nvr_port
          ? result.data.items[0].nvr_port
          : "",
        nvr_username: result.data.items[0].nvr_username
          ? result.data.items[0].nvr_username
          : "",
        nvr_pwrd: result.data.items[0].nvr_pwrd
          ? result.data.items[0].nvr_pwrd
          : "",
        cctv_url: result.data.items[0].cctv_url
          ? result.data.items[0].cctv_url
          : "",
        remarks: result.data.items[0].remarks
          ? result.data.items[0].remarks
          : "",

        codec: result.data.items[0].codec ? result.data.items[0].codec : "",
      });
      setModifiedData({
        modified_at: result.data.items[0].modified_at
          ? moment(new Date(result.data.items[0].modified_at)).format(
              "YYYY-MM-DD"
            )
          : "-",
        modified_time: result.data.items[0].modified_at
          ? moment(new Date(result.data.items[0].modified_at)).format("h:mm a")
          : "-",
        modifier_name: result.data.items[0].modifier_name
          ? result.data.items[0].modifier_name
          : "-",
        creator_name: result.data.items[0].creator_name
          ? result.data.items[0].creator_name
          : "",
      });
      setLoading(false);
    }
  };

  const handleSaveClicked = () => {
    setConfirmBox(true);
  };

  const handleSaveToAPI = async () => {
    setShowMessage(false);
    setLoading(true);
    console.log(editData);
    const token = await getCurrentLoggedInSessionTokenWithPromise();
    const currentLoginUser = await getCurrentLoggedInUserWithPromise();
    const uploadData = {
      camera_name: editData.camera_name,
      camera_no: editData.camera_no,
      package: editData.package,
      description: editData.description,
      location: editData.location,
      map_id: editData.map_id,
      nvr_id: editData.nvr_id,
      nvr_ip: editData.nvr_ip,
      nvr_port: editData.nvr_port,
      nvr_username: editData.nvr_username,
      nvr_pwrd: editData.nvr_pwrd,
      cctv_url: editData.cctv_url,
      remarks: editData.remarks,
      codec: editData.codec,
      stream_name: editData.stream_name,
      camera_position: [
        editData.camera_position_lat,
        editData.camera_position_lng,
      ],
    };
    const result = await api.post(
      CREATE_UPDATE_CCTV,
      JSON.stringify({
        data_type: "CCTV",
        action: id ? "update" : "create",
        data: id
          ? { ...uploadData, modified_by: currentLoginUser["sub"] }
          : { ...uploadData, created_by: currentLoginUser["sub"] },
      }),
      token
    );
    console.log(result);
    if (result.status_code === 200) {
      if (id) {
        getCCTVDetail();
        setEditMode(false);
      } else {
        setEditMode(false);
        navigate(`${CCTV_DETAIL_ROUTE}?id=${result.data.camera_no}`);
      }
    } else {
      alert(result.error);
    }
  };

  const handleEditMode = () => {
    setEditMode(true);
  };

  const validator = () => {
    if (
      editData.camera_name === "" ||
      editData.camera_no === "" ||
      editData.cctv_url === "" ||
      editData.package === "" ||
      editData.codec === ""
      // ||
      // editData.description === "" ||
      // editData.camera_position.length < 2 ||
      // editData.camera_position[0].length < 3 ||
      // editData.camera_position[1].length < 3 ||
      // editData.location === "" ||
      // editData.map_id === "" ||
      // editData.nvr_id === "" ||
      // editData.nvr_ip === "" ||
      // editData.nvr_port === "" ||
      // editData.nvr_pwrd === "" ||
      // editData.nvr_username === "" ||
      // editData.remarks === ""
    ) {
      return true;
    } else {
      return false;
    }
  };

  const DeleteCCTV = () => {
    setShowDelete(true);
  };

  const ConfrimDelete = async () => {
    setLoading(true);
    setShowDelete(false);
    const token = await getCurrentLoggedInSessionTokenWithPromise();
    const result = await api.post(
      CREATE_UPDATE_CCTV,
      JSON.stringify({
        data_type: "CCTV",
        action: "delete",
        data: {
          camera_name: editData.camera_name,
          camera_no: editData.camera_no,
        },
      }),
      token
    );
    if (result.status_code === 200) {
      console.log(result);
      navigate(CCTV_MANAGMENT);
    } else {
      alert(result.message);
    }
  };

  return (
    <>
      {showDelete && (
        <Dialog>
          <div className="CCTV-filter-width_resize">
            <div
              style={{
                position: "absolute",
                top: "15px",
                right: "13px",
                cursor: "pointer",
              }}
              onClick={() => setShowDelete(false)}
            >
              <img src={CrossIcon} />
            </div>
            <div className="CCTV_Delete_confirm_title">Delete!</div>
            <div className="CCTV_Delete_confirm_text">
              Are you sure? You want to delete this CCTV!
            </div>
            <div className="CCTV_Filter_button_main_box">
              <div
                className="CCTV_Filter_clear_button"
                onClick={() => setShowDelete(false)}
              >
                Cancel
              </div>
              <div className="CCTV_Filter_apply_button" onClick={ConfrimDelete}>
                Confirm
              </div>
            </div>
          </div>
        </Dialog>
      )}
      {confirmBox && (
        <ConfirmMsg
          onCloseDialog={() => {
            setConfirmBox(false);
          }}
          msgTitle={`Are you sure you really want to change the cctv details?`}
          okBtnMsg={"Yes, proceed"}
          okBtnClicked={() => {
            setConfirmBox(false);
            setShowMessage(true);
          }}
          cancelBtnMsg={"No, cancel"}
          cancelBtnClicked={() => {
            setConfirmBox(false);
          }}
        />
      )}
      {showMessage && (
        <Dialog>
          <div className="CCTV-filter-width_resize">
            <div
              style={{
                position: "absolute",
                top: "15px",
                right: "13px",
                cursor: "pointer",
              }}
              onClick={() => setShowMessage(false)}
            >
              <img src={CrossIcon} />
            </div>
            <div className="CCTV_Delete_confirm_title">
              New CCTV Configured.
            </div>
            <div className="CCTV_Delete_confirm_text">
              Please note that the CCTV will only be available to be streamed
              after 2 days. If you need immediate stream, please contact CEreal
              support.
            </div>
            <div className="CCTV_Filter_button_main_box">
              {/* <div
                className="CCTV_Filter_clear_button"
                onClick={() => setShowDelete(false)}
              >
                Cancel
              </div> */}
              <div
                className="CCTV_Filter_apply_button"
                onClick={handleSaveToAPI}
              >
                Confirm
              </div>
            </div>
          </div>
        </Dialog>
      )}
      {loading ? (
        <Loader
          type="infinite-spinner"
          style={{
            display: "flex",
            alignItems: "center",
            width: "40px",
            margin: "auto",
            justifyContent: "center",
            height: "640px",
          }}
        />
      ) : (
        <div style={{ background: "white" }}>
          <div className="CCTV_form_toolbar">
            <div
              className="CCTV_form_toolbar_back_icon"
              onClick={() => navigate(CCTV_MANAGMENT)}
            >
              <img src={BackIcon} />
            </div>
            <div className="CCTV_form_toolbar_title">CCTV Details</div>
          </div>
          <div className="CCTV_create_scroll_box">
            <div className="CCTV_create_main_box">
              <div className="CCTV_create_editable_main_box">
                <div className="CCTV_create_editable_title">
                  Camera No * (This is an unique Identifier. It cannot be
                  changed after save. This will appear in the Website)
                </div>
                <div>
                  <Input
                    className="CCTV_form_input_editable"
                    defaultValue={editData.camera_no}
                    type="text"
                    // disabled={!editMode}
                    disabled={id ? true : false}
                    onChange={(e) => {
                      setEditData({
                        ...editData,
                        camera_no: e.target.value,
                      });
                    }}
                  />
                  {editMode && editData.camera_no === "" && (
                    <div className="CCTV_error_text">
                      This field is required!
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="CCTV_create_main_box">
              <div className="CCTV_create_editable_main_box">
                <div className="CCTV_create_editable_title">
                  Camera Name * (This is an unique Identifier. It cannot be
                  changed after save. This will appear in the Website)
                </div>
                <div>
                  <Input
                    className="CCTV_form_input_editable"
                    defaultValue={editData.camera_name}
                    type="text"
                    // disabled={!editMode}
                    disabled={id ? true : false}
                    onChange={(e) => {
                      setEditData({
                        ...editData,
                        camera_name: e.target.value,
                      });
                    }}
                  />
                  {editMode && editData.camera_name === "" && (
                    <div className="CCTV_error_text">
                      This field is required!
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="CCTV_create_main_box">
              <div className="CCTV_create_editable_main_box">
                <div className="CCTV_create_editable_title">
                  Package ID * (Appear in Website)
                </div>
                <div>
                  <DropDownList
                    className="CCTV_form_input_editable"
                    style={{
                      opacity: !editMode && "0.6",
                      background: "white",
                      height: "48px",
                    }}
                    data={[
                      "All",
                      ...general_config_data.general_config_data.package,
                    ]}
                    defaultValue={"Select"}
                    value={editData.package}
                    onChange={(e) => {
                      setEditData({
                        ...editData,
                        package: e.target.value,
                      });
                    }}
                    disabled={!editMode}
                  />
                  {editMode && editData.package === "" && (
                    <div className="CCTV_error_text">
                      This field is required!
                    </div>
                  )}
                </div>
              </div>
              <div className="CCTV_create_editable_main_box">
                <div className="CCTV_create_editable_title">Stream Name *</div>
                <div>
                  <Input
                    className="CCTV_form_input_editable"
                    defaultValue={editData.stream_name}
                    type="text"
                    disabled={!editMode}
                    onChange={(e) => {
                      setEditData({
                        ...editData,
                        stream_name: e.target.value,
                      });
                    }}
                  />
                  {editMode && editData.stream_name === "" && (
                    <div className="CCTV_error_text">
                      This field is required!
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="CCTV_create_main_box">
              <div className="CCTV_create_editable_main_box">
                <div className="CCTV_create_editable_title">
                  Description (Appear in Website)
                </div>
                <div>
                  <Input
                    className="CCTV_form_input_editable"
                    defaultValue={editData.description}
                    disabled={!editMode}
                    type="text"
                    onChange={(e) => {
                      setEditData({
                        ...editData,
                        description: e.target.value,
                      });
                    }}
                  />
                  {/* {editMode && editData.description === "" && (
                    <div className="CCTV_error_text">
                      This field is required!
                    </div>
                  )} */}
                </div>
              </div>
            </div>
            <div className="CCTV_create_main_box">
              <div className="CCTV_create_editable_main_box">
                <div className="CCTV_create_editable_title">
                  Camera Position Lat (E.g:1.3412)
                </div>
                <div>
                  <Input
                    className="CCTV_form_input_editable"
                    defaultValue={editData.camera_position_lat}
                    // defaultValue={editData.camera_position.join(", ")}
                    disabled={!editMode}
                    type="text"
                    onChange={(e) => {
                      setEditData({
                        ...editData,
                        // camera_position: e.target.value.split(", "),
                        camera_position_lat: e.target.value,
                      });
                    }}
                  />
                  {/* {editMode ? (
                    editData.camera_position.length < 2 ||
                    editData.camera_position[0].length < 3 ||
                    editData.camera_position[1].length < 3 ? (
                      <div className="CCTV_error_text">
                        This field is required! Required format (1.3412,
                        103.21231)
                      </div>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )} */}
                </div>
              </div>
              <div className="CCTV_create_editable_main_box">
                <div className="CCTV_create_editable_title">
                  Camera Position Long (E.g:103.21231)
                </div>
                <div>
                  <Input
                    className="CCTV_form_input_editable"
                    defaultValue={editData.camera_position_lng}
                    disabled={!editMode}
                    type="text"
                    onChange={(e) => {
                      setEditData({
                        ...editData,
                        camera_position_lng: e.target.value,
                        // camera_position: e.target.value.split(", "),
                      });
                    }}
                  />
                  {/* {editMode ? (
                    editData.camera_position.length < 2 ||
                    editData.camera_position[0].length < 3 ||
                    editData.camera_position[1].length < 3 ? (
                      <div className="CCTV_error_text">
                        This field is required! Required format (1.3412,
                        103.21231)
                      </div>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )} */}
                </div>
              </div>
            </div>
            <div className="CCTV_create_main_box">
              <div className="CCTV_create_editable_main_box">
                <div className="CCTV_create_editable_title">
                  Camera Location Description
                </div>
                <div>
                  <Input
                    className="CCTV_form_input_editable"
                    defaultValue={editData.location}
                    disabled={!editMode}
                    type="text"
                    onChange={(e) => {
                      setEditData({
                        ...editData,
                        location: e.target.value,
                      });
                    }}
                  />
                  {/* {editMode && editData.location === "" && (
                    <div className="CCTV_error_text">
                      This field is required!
                    </div>
                  )} */}
                </div>
              </div>
            </div>
            <div className="CCTV_create_main_box">
              <div className="CCTV_create_editable_main_box">
                <div className="CCTV_create_editable_title">Camera Map ID</div>
                <div>
                  <Input
                    className="CCTV_form_input_editable"
                    defaultValue={editData.map_id}
                    disabled={!editMode}
                    type="text"
                    onChange={(e) => {
                      setEditData({
                        ...editData,
                        map_id: e.target.value,
                      });
                    }}
                  />
                  {/* {editMode && editData.map_id === "" && (
                    <div className="CCTV_error_text">
                      This field is required!
                    </div>
                  )} */}
                </div>
              </div>
              <div className="CCTV_create_editable_main_box">
                <div className="CCTV_create_editable_title">
                  NVR ID(See note 2)
                </div>
                <div>
                  <Input
                    className="CCTV_form_input_editable"
                    defaultValue={editData.nvr_id}
                    disabled={!editMode}
                    type="text"
                    onChange={(e) => {
                      setEditData({
                        ...editData,
                        nvr_id: e.target.value,
                      });
                    }}
                  />
                  {/* {editMode && editData.nvr_id === "" && (
                    <div className="CCTV_error_text">
                      This field is required!
                    </div>
                  )} */}
                </div>
              </div>
            </div>
            <div className="CCTV_create_main_box">
              <div className="CCTV_create_editable_main_box">
                <div className="CCTV_create_editable_title">
                  NVR Public IP Address{" "}
                </div>
                <div>
                  <Input
                    className="CCTV_form_input_editable"
                    defaultValue={editData.nvr_ip}
                    disabled={!editMode}
                    type="text"
                    onChange={(e) => {
                      setEditData({
                        ...editData,
                        nvr_ip: e.target.value,
                      });
                    }}
                  />
                  {/* {editMode && editData.nvr_ip === "" && (
                    <div className="CCTV_error_text">
                      This field is required!
                    </div>
                  )} */}
                </div>
              </div>
              <div className="CCTV_create_editable_main_box">
                <div className="CCTV_create_editable_title">NVR Port No.</div>
                <div>
                  <Input
                    className="CCTV_form_input_editable"
                    defaultValue={editData.nvr_port}
                    disabled={!editMode}
                    type="text"
                    onChange={(e) => {
                      setEditData({
                        ...editData,
                        nvr_port: e.target.value,
                      });
                    }}
                  />
                  {/* {editMode && editData.nvr_port === "" && (
                    <div className="CCTV_error_text">
                      This field is required!
                    </div>
                  )} */}
                </div>
              </div>
            </div>
            <div className="CCTV_create_main_box">
              <div className="CCTV_create_editable_main_box">
                <div className="CCTV_create_editable_title">
                  NVR Log-in credentials ID
                </div>
                <div>
                  <Input
                    className="CCTV_form_input_editable"
                    defaultValue={editData.nvr_username}
                    disabled={!editMode}
                    type="text"
                    onChange={(e) => {
                      setEditData({
                        ...editData,
                        nvr_username: e.target.value,
                      });
                    }}
                  />
                  {/* {editMode && editData.nvr_username === "" && (
                    <div className="CCTV_error_text">
                      This field is required!
                    </div>
                  )} */}
                </div>
              </div>
              <div className="CCTV_create_editable_main_box">
                <div className="CCTV_create_editable_title">
                  NVR Log-in credentials PWD
                </div>
                <div>
                  <Input
                    className="CCTV_form_input_editable"
                    defaultValue={editData.nvr_pwrd}
                    disabled={!editMode}
                    type="text"
                    onChange={(e) => {
                      setEditData({
                        ...editData,
                        nvr_pwrd: e.target.value,
                      });
                    }}
                  />
                  {/* {editMode && editData.nvr_pwrd === "" && (
                    <div className="CCTV_error_text">
                      This field is required!
                    </div>
                  )} */}
                </div>
              </div>
            </div>
            <div className="CCTV_create_main_box">
              <div className="CCTV_create_editable_main_box">
                <div className="CCTV_create_editable_title">URL *</div>
                <div>
                  <Input
                    className="CCTV_form_input_editable"
                    defaultValue={editData.cctv_url}
                    disabled={!editMode}
                    type="text"
                    onChange={(e) => {
                      setEditData({
                        ...editData,
                        cctv_url: e.target.value,
                      });
                    }}
                  />
                  {editMode && editData.cctv_url === "" && (
                    <div className="CCTV_error_text">
                      This field is required!
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="CCTV_create_main_box">
              <div className="CCTV_create_editable_main_box">
                <div className="CCTV_create_editable_title">CODEC *</div>
                <div>
                  <Input
                    className="CCTV_form_input_editable"
                    defaultValue={editData.codec}
                    disabled={!editMode}
                    type="text"
                    onChange={(e) => {
                      setEditData({
                        ...editData,
                        codec: e.target.value,
                      });
                    }}
                  />
                  {editMode && editData.codec === "" && (
                    <div className="CCTV_error_text">
                      This field is required!
                    </div>
                  )}
                </div>
              </div>
            </div>
            {id && !editMode ? (
              <div className="CCTV_create_main_box">
                <div className="CCTV_create_editable_main_box">
                  <div className="CCTV_create_editable_title">
                    Source Available
                  </div>
                  <div style={{ display: "flex", gap: "20px" }}>
                    <div className="CCTV_last_modified">
                      {modifiedData.is_available ? "Yes" : "No"}
                    </div>
                    {/* <div style={{ flex: 1 }}>
                      <RadioButton
                        className="details-radio"
                        label={"Yes"}
                        disabled={true}
                        name="source_available"
                        // value={v}
                        // onChange={(e) =>
                        //   setEditData({
                        //     ...editData,
                        //     source_available: true,
                        //   })
                        // }
                        checked={sourceAvailable && true}
                      />
                    </div>
                    <div style={{ flex: 1 }}>
                      <RadioButton
                        className="details-radio"
                        label={"No"}
                        disabled={true}
                        name="source_available"
                        // value={v}
                        // onChange={(e) =>
                        //   setEditData({
                        //     ...editData,
                        //     source_available: false,
                        //   })
                        // }
                        checked={!sourceAvailable && true}
                      />
                    </div> */}
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            {id && !editMode ? (
              <div className="CCTV_create_main_box">
                <div className="CCTV_create_editable_main_box">
                  <div className="CCTV_create_editable_title">
                    Last Modified Date
                  </div>
                  <div className="CCTV_last_modified">
                    {modifiedData.modified_at}
                    {/* <CustomDatePicker
                      value={modifiedData.modified_at}
                      // onChange={handleStartDateClicked}
                      disabled={!editMode}
                      format={"DD MMM yyyy"}
                      cctv={true}
                    /> */}
                  </div>
                </div>
                <div className="CCTV_create_editable_main_box">
                  <div className="CCTV_create_editable_title">
                    Last Modified Time
                  </div>
                  <div className="CCTV_last_modified">
                    {modifiedData.modified_time}
                    {/* <CustomTimePicker
                      disabled={!editMode}
                      value={modifiedData.modified_at}
                      // onChange={(e) => {
                      //   setEditData({
                      //     ...editData,
                      //     start_time: e,
                      //   });
                      // }}
                    /> */}
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            {id && !editMode ? (
              <div className="CCTV_create_main_box">
                <div className="CCTV_create_editable_main_box">
                  <div className="CCTV_create_editable_title">
                    Last Modified by
                  </div>
                  <div className="CCTV_last_modified">
                    {modifiedData.modifier_name}
                    {/* <Input
                      className="CCTV_form_input_editable"
                      defaultValue={modifiedData.modifier_name}
                      disabled={!editMode}
                      type="text"
                      //   onChange={(e) => {
                      //     setEditData({
                      //       ...editData,
                      //       user_group_name: e.target.value,
                      //     });
                      //   }}
                    /> */}
                  </div>
                </div>
                <div className="CCTV_create_editable_main_box">
                  <div className="CCTV_create_editable_title">Created by</div>
                  <div className="CCTV_last_modified">
                    {modifiedData.creator_name}
                    {/* <Input
                      className="CCTV_form_input_editable"
                      defaultValue={modifiedData.modifier_name}
                      disabled={!editMode}
                      type="text"
                      //   onChange={(e) => {
                      //     setEditData({
                      //       ...editData,
                      //       user_group_name: e.target.value,
                      //     });
                      //   }}
                    /> */}
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}

            <div className="CCTV_create_main_box">
              <div className="CCTV_create_editable_main_box">
                <div className="CCTV_create_editable_title">Remarks</div>
                <div>
                  <TextArea
                    className="remark-textarea"
                    style={{ height: "150px" }}
                    disabled={!editMode}
                    rows={6}
                    autoSize={true}
                    value={editData.remarks}
                    onChange={(e) =>
                      setEditData({
                        ...editData,
                        remarks: e.target.value,
                      })
                    }
                  />
                  {/* {editMode && editData.remarks === "" && (
                    <div className="CCTV_error_text">
                      This field is required!
                    </div>
                  )} */}
                </div>
              </div>
            </div>
          </div>
          <div className="CCTV_form_footer">
            <div style={{ display: "flex", gap: "12px" }}></div>
            <div className="CCTV_form_footer_child_box">
              {id && !editMode ? (
                <div className="CCTV_form_footer_save" onClick={DeleteCCTV}>
                  Delete
                </div>
              ) : (
                ""
              )}
              {id && !editMode ? (
                <div
                  className="CCTV_form_footer_audit_trail"
                  onClick={() => navigate(`${CCTV_AUDIT_TRAIL}?id=${id}`)}
                >
                  View Audit Trail
                </div>
              ) : (
                ""
              )}
              <div
                className="CCTV_form_footer_cancel"
                // onClick={handleCancelClicked}
                onClick={() => navigate(CCTV_MANAGMENT)}
              >
                Cancel
              </div>
              <div
                // className={
                //   Object.keys(errors).length > 0
                //     ? "CCTV_form_footer_save disabled"
                //     : "CCTV_form_footer_save "
                // }
                className="CCTV_form_footer_save "
                style={{
                  pointerEvents: id
                    ? editMode
                      ? validator()
                        ? "none"
                        : ""
                      : ""
                    : validator()
                    ? "none"
                    : "",
                  // pointerEvents: validator() ? "none" : "",
                  // opacity: validator() ? "0.5" : "1",
                  opacity: id
                    ? editMode
                      ? validator()
                        ? "0.5"
                        : "1"
                      : "1"
                    : validator()
                    ? "0.5"
                    : "1",
                }}
                onClick={
                  id
                    ? editMode
                      ? handleSaveClicked
                      : handleEditMode
                    : handleSaveClicked
                }
              >
                {editMode ? "Save" : "Edit"}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
