import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
// kendo
import { Loader } from "@progress/kendo-react-all";
// route
import { COMPANY_MANAGEMENT_DETAIL_ROUTE } from "../../routes";
// api
import { getCurrentLoggedInSessionTokenWithPromise } from "../../config/userPool";
import api from "../../config/api";
import config from "../../config/config";
import { fetchALLAudit } from "../../store/auditTrail";
// component
import { Table } from "../../components/table/table";
// icon
import BackIcon from "../../assets/images/Icon/PreviousIcon.svg";

import moment from "moment";
// css
import "./company.css";

const header = [
  {
    name: "Timestamp",
  },
  {
    name: "Changed By",
  },
  {
    name: "Description of Change",
  },
];

export const CompanyAuditTrail = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let { search } = useLocation();
  const query = new URLSearchParams(search);
  const [MainLoading, setMainLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [auditData, setAuditData] = useState([]);
  const id = query.get("id");
  const { GET_COMPANY_DETAIL } = config.api_endpoint;
  const { audit, loading } = useSelector((state) => state.audit);

  const getCompany = async () => {
    const token = await getCurrentLoggedInSessionTokenWithPromise();
    const result = await api.get(
      GET_COMPANY_DETAIL,
      {
        id: id,
      },
      token
    );
    console.log(result);
    if (result.status_code === 200) {
      setUser(result.data);
      setMainLoading(false);
    }
  };

  useEffect(() => {
    dispatch(fetchALLAudit(id));
  }, [id]);

  useEffect(() => {
    if (!loading && audit.audit && audit.audit.length > 0) {
      let audit_data = [];
      for (let i = 0; i < audit.audit.length; i++) {
        audit_data.push({
          id: audit.audit[i].id,
          item1: `${moment(audit.audit[i].created_date).format(
            "DD-MM-YYYY"
          )}, ${("0" + new Date(audit.audit[i].created_date).getHours()).slice(
            -2
          )}:${("0" + new Date(audit.audit[i].created_date).getMinutes()).slice(
            -2
          )}`,
          item2: audit.audit[i].audit_user
            ? audit.audit[i].audit_user.name
            : "Self",
          item3: audit.audit[i].description,
        });
      }
      console.log("audit_data:", audit_data);
      setAuditData(audit_data);
      getCompany();
    } else if (!loading && audit.audit && audit.audit.length == 0) {
      let audit_data = [];
      setAuditData(audit_data);
      getCompany();
    }
  }, [audit]);

  return (
    <>
      {MainLoading || loading ? (
        <Loader
          type="infinite-spinner"
          style={{
            display: "flex",
            alignItems: "center",
            width: "40px",
            margin: "auto",
            display: "flex",
            justifyContent: "center",
            height: "100vh",
          }}
        />
      ) : (
        <div>
          <div className="Company_form_toolbar">
            <div
              className="Company_form_toolbar_back_icon"
              onClick={() =>
                navigate(`${COMPANY_MANAGEMENT_DETAIL_ROUTE}?id=${id}`)
              }
            >
              <img src={BackIcon} />
            </div>
            <div className="Company_form_toolbar_title">Audit Trail</div>
          </div>
          <div className="Company_audit_trail_title">
            Audit trail for {user.company_name}
          </div>
          <Table header={header} data={auditData} />
          {auditData.length === 0 && (
            <p className="Company_detail_main_box_desc">
              No available audit records.
            </p>
          )}
        </div>
      )}
    </>
  );
};
