import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// kendo
import { Loader } from "@progress/kendo-react-indicators";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Checkbox, RadioButton } from "@progress/kendo-react-inputs";
// libs
import moment from "moment";
// logo
import FilterIcon from "../../assets/images/Icon/FilterIcon.svg";
import SearchIcon from "../../assets/images/Icon/SearchIcon.svg";
import CrossIcon from "../../assets/images/Icon/Cross_black.svg";
// component
import { Table } from "../../components/table/table";
import { Pagination } from "../../components/pagination/pagination";
//css
import "./ecm.css";
import { ECM_CREATE_ROUTE, ECM_DETAIL_ROUTE } from "../../routes";
import { fetchAllECM, resetECMList } from "../../store/ecmList";
import { getCurrentLoggedInSessionTokenWithPromise } from "../../config/userPool";
import api from "../../config/api";
import config from "../../config/config";

const header = [
  {
    name: "ECM Name",
  },
  {
    name: "Package",
    width: "250px",
  },
  {
    name: "Sensor ID",
    width: "150px",
  },
  {
    name: "ECM Location Description",
    width: "250px",
  },
  {
    name: "Is Alert",
    width: "150px",
  },
  {
    name: "Last Modified Date",
    width: "200px",
  },
  {
    name: "Last Modified By",
    width: "150px",
  },
];

export const ECMManagment = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { general_config_data } = useSelector((state) => state.generalConfig);
  const all_packages_dict = {};
  ["All", ...general_config_data.general_config_data.package].forEach(
    (element) => {
      all_packages_dict[element] = false;
    }
  );
  const { GET_ECM } = config.api_endpoint;
  const { ecmList, loading } = useSelector((state) => state.ecmList);
  const [newPage, setNewPage] = useState(false);
  const [ecmData, setECMData] = useState([]);
  const [activePages, setActivePages] = useState(1);
  const [showSearch, setShowSearch] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [filtered, setFiltered] = useState(false);
  const [filterLoading, setFilterLoading] = useState(false);
  const [filterData, setFilterData] = useState({
    filterPkg: [],
    // source_available: false,
  });

  useEffect(() => {
    dispatch(
      fetchAllECM({
        page: 1,
        page_size: 20,
      })
    );
    return () => {
      dispatch(resetECMList());
    };
  }, []);

  useEffect(() => {
    const _ecm_data = [];
    if (!loading && ecmList.ecmList && ecmList.ecmList.items.length > 0) {
      for (let i = 0; i < ecmList.ecmList.items.length; i++) {
        _ecm_data.push({
          id: ecmList.ecmList.items[i].ecm_no,
          item1: ecmList.ecmList.items[i].ecm_no,
          item2: ecmList.ecmList.items[i].package,
          item3: ecmList.ecmList.items[i].sensor_id,
          item4: ecmList.ecmList.items[i].ecm_location_description,
          item5: ecmList.ecmList.items[i].is_alert ? "Yes" : "No",
          item6: ecmList.ecmList.items[i].modified_at
            ? moment(ecmList.ecmList.items[i].modified_at).format(
                "DD MMM YYYY, hh:mm:ss"
              )
            : "-",
          item7: ecmList.ecmList.items[i].modified_by
            ? ecmList.ecmList.items[i].modified_by
            : "-",
        });
      }
      setNewPage(ecmList.ecmList.newPage);
    }
    setECMData(_ecm_data);
  }, [ecmList]);

  const handlePageChange = async (page) => {
    console.log("this one doing");
    setActivePages(page + 1);
    console.log(page);
    const token = await getCurrentLoggedInSessionTokenWithPromise();
    let APIParams = {};
    if (filtered) {
      APIParams = {
        data_type: "ECM",
        statistics: {
          page: page + 1,
          pageSize: 20,
          //   filter_packages: filterData.filterPkg.join(","),
          //   filter_source_available: filterData.source_available ? 1 : 0,
        },
      };
    } else {
      APIParams = {
        data_type: "ECM",
        statistics: {
          page: page + 1,
          pageSize: 20,
        },
      };
    }
    const results = await api.post(GET_ECM, JSON.stringify(APIParams), token);
    const _ecm_data = [];
    for (let i = 0; i < results.data.items.length; i++) {
      _ecm_data.push({
        id: results.data.items[i].ecm_no,
        item1: results.data.items[i].ecm_no,
        item2: results.data.items[i].package,
        item3: results.data.items[i].sensor_id,
        item4: results.data.items[i].ecm_location_description,
        item5: results.data.items[i].is_alert ? "Yes" : "No",
        item6: results.data.items[i].modified_at
          ? moment(results.data.items[i].modified_at).format(
              "DD MMM YYYY, hh:mm:ss"
            )
          : "-",
        item7: results.data.items[i].modified_by
          ? results.data.items[i].modified_by
          : "-",
      });
    }
    setNewPage(results.data.newPage);
    setECMData([...ecmData, ..._ecm_data]);
  };

  const handleTableClick = (id) => {
    navigate(`${ECM_DETAIL_ROUTE}?id=${id}`);
  };

  const handlePkg = (pkg) => {
    console.log(pkg);
    if (filterData.filterPkg.find((i) => i === pkg)) {
      const filtered = filterData.filterPkg.filter((j) => j !== pkg);
      setFilterData({
        ...filterData,
        filterPkg: filtered,
      });
    } else {
      filterData.filterPkg.push(pkg);
      setFilterData({
        ...filterData,
      });
    }
  };

  const FilterApply = async () => {
    setFilterLoading(true);
    setFiltered(true);
    setShowFilter(false);
    setActivePages(1);
    const token = await getCurrentLoggedInSessionTokenWithPromise();
    const results = await api.post(
      GET_ECM,
      JSON.stringify({
        data_type: "ECM",
        statistics: {
          page: 1,
          pageSize: 20,
          filter_packages: filterData.filterPkg.join(","),
          //  filter_source_available: filterData.source_available ? 1 : 0,
        },
      }),
      token
    );
    console.log(results);
    const _ecm_data = [];
    for (let i = 0; i < results.data.items.length; i++) {
      _ecm_data.push({
        id: results.data.items[i].ecm_no,
        item1: results.data.items[i].ecm_no,
        item2: results.data.items[i].package,
        item3: results.data.items[i].sensor_id,
        item4: results.data.items[i].ecm_location_description,
        item5: results.data.items[i].is_alert ? "Yes" : "No",
        item6: results.data.items[i].modified_at
          ? moment(results.data.items[i].modified_at).format(
              "DD MMM YYYY, hh:mm:ss"
            )
          : "-",
        item7: results.data.items[i].modified_by
          ? results.data.items[i].modified_by
          : "-",
      });
      setNewPage(results.data.newPage);
    }
    setECMData(_ecm_data);
    setFilterLoading(false);
  };

  const FilterReset = () => {
    setActivePages(1);
    setShowFilter(false);
    setFiltered(false);
    setFilterData({
      ...filterData,
      filterPkg: [],
    });
    dispatch(
      fetchAllECM({
        page: 1,
        page_size: 20,
      })
    );
  };

  console.log(ecmList);

  return (
    <>
      {showFilter && (
        <Dialog>
          <div className="CCTV-filter-width_resize">
            <div
              style={{
                position: "absolute",
                top: "15px",
                right: "13px",
                cursor: "pointer",
              }}
              onClick={() => setShowFilter(false)}
            >
              <img src={CrossIcon} />
            </div>
            <div className="CCTV-filter-title">Filter</div>
            <div>
              <div className="CCTV-filter-sub-title">Package</div>
              <div className="CCTV-filter-box">
                {Object.entries(all_packages_dict).map(([key, value]) => (
                  <div
                    className="CCTV-filter-item"
                    key={1 + Math.random() * (100 - 1)}
                  >
                    <Checkbox
                      // defaultChecked={value}
                      checked={
                        filterData.filterPkg.find((i) => i === key)
                          ? true
                          : false
                      }
                      label={key}
                      onClick={() => handlePkg(key)}
                    />
                  </div>
                ))}
              </div>
            </div>
            <div className="CCTV_Filter_button_main_box">
              <div className="CCTV_Filter_clear_button" onClick={FilterReset}>
                Clear
              </div>
              <div className="CCTV_Filter_apply_button" onClick={FilterApply}>
                Apply
              </div>
            </div>
          </div>
        </Dialog>
      )}
      {loading || filterLoading ? (
        <Loader
          type="infinite-spinner"
          style={{
            display: "flex",
            alignItems: "center",
            width: "40px",
            margin: "auto",
            justifyContent: "center",
            height: "100vh",
          }}
        />
      ) : (
        <div>
          <div className="ECM_toolbar">
            <div className="ECM_toolbar_title">ECM Management</div>
            <div className="ECM_toolbar_child_box">
              <div
                className="ECM_toolbar_add_button"
                onClick={() => navigate(ECM_CREATE_ROUTE)}
              >
                <div>+</div>
                <div>Add</div>
              </div>
              <div
                className="ECM_toolbar_filter_button"
                onClick={() => setShowFilter(true)}
              >
                <img src={FilterIcon} />
              </div>
              {/* <div
                className="ECM_toolbar_filter_button"
                // onClick={handleSearchIconClicked}
                >
                <img src={SearchIcon} />
            </div> */}
            </div>
          </div>
          {/* {showSearch && (
          <div className="ECM_selection_bar">
          <Input
          value={filterText}
          onChange={(e) => setFilterText(e.target.value)}
          placeholder="Search..."
          onKeyDown={(e) => e.key === "Enter" && applyFilters()}
          />
          <div
          className="ECM_toolbar_add_button"
          onClick={() => {
              applyFilters();
            }}
            >
            <div>Search</div>
            </div>
            </div>
        )} */}
          {ecmData.length > 0 ? (
            <Table
              header={header}
              data={ecmData}
              handleTableClick={handleTableClick}
              handlePageChange={handlePageChange}
              activePages={activePages}
              showSearch={showSearch}
              hasMore={newPage}
            />
          ) : (
            <p style={{ textAlign: "center" }}>No records found.</p>
          )}
        </div>
      )}
    </>
  );
};
