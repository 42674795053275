import { Dialog, DropDownList, Loader } from "@progress/kendo-react-all";
import { Input, TextArea } from "@progress/kendo-react-inputs";
import React, { useEffect, useState } from "react";
import { ECM_AUDIT_TRAIL, ECM_DETAIL_ROUTE, ECM_MANAGMENT } from "../../routes";
import { useLocation, useNavigate } from "react-router-dom";
import BackIcon from "../../assets/images/Icon/PreviousIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import CrossIcon from "../../assets/images/Icon/Cross_black.svg";
import {
  getCurrentLoggedInSessionToken,
  getCurrentLoggedInSessionTokenWithPromise,
  getCurrentLoggedInUserWithPromise,
} from "../../config/userPool";
import api from "../../config/api";
import config from "../../config/config";
import moment from "moment";
import ConfirmMsg from "../../components/confirmMsg";

export const ECMForm = () => {
  const navigate = useNavigate();
  let { search } = useLocation();
  const { CREATE_UPDATE_ECM, GET_ECM } = config.api_endpoint;
  const query = new URLSearchParams(search);
  const id = query.get("id");
  const { general_config_data } = useSelector((state) => state.generalConfig);
  const [all_packages, setAll_packages] = useState([
    ...general_config_data.general_config_data.package,
  ]);
  const [showDelete, setShowDelete] = useState(false);
  const [loading, setLoading] = useState(true);
  const [confirmBox, setConfirmBox] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [editData, setEditData] = useState({
    ecm_no: "",
    package: "",
    ecm_name: "",
    lat: "",
    long: "",
    ecm_location_description: "",
    ecm_id: "",
    ecm_name_original: "",
    ecm_map_id: "",
    sensor_id: "",
    tss_source_url: "",
    cctv_img_url: "",
    remarks: "",
  });
  const [modifiedData, setModifiedData] = useState({
    modified_at: "",
    modified_by: "",
  });

  const validator = () => {
    if (
      editData.ecm_no === "" ||
      editData.package === "" ||
      editData.ecm_name === "" ||
      editData.lat === "" ||
      editData.long === "" ||
      editData.ecm_location_description === "" ||
      editData.ecm_id === "" ||
      editData.ecm_name_original === "" ||
      editData.ecm_map_id === "" ||
      editData.sensor_id === "" ||
      editData.tss_source_url === "" ||
      editData.cctv_img_url === "" ||
      editData.remarks === ""
    ) {
      return true;
    } else {
      return false;
    }
  };

  const getECMDetail = async () => {
    const token = await getCurrentLoggedInSessionTokenWithPromise();
    const result = await api.post(
      GET_ECM,
      JSON.stringify({
        data_type: "ECM",
        statistics: {
          filter_ecm_no: id,
        },
      }),
      token
    );
    console.log(result);
    if (result.status_code === 200) {
      setEditData({
        ...editData,
        id: result.data.items[0].ecm_id,
        ecm_id: result.data.items[0].ecm_id,
        ecm_no: result.data.items[0].ecm_no,
        package: result.data.items[0].package,
        lat: result.data.items[0].lat,
        long: result.data.items[0].long,
        ecm_location_description: result.data.items[0].ecm_location_description,
        ecm_name: result.data.items[0].ecm_name
          ? result.data.items[0].ecm_name
          : "",
        ecm_name_original: result.data.items[0].ecm_name_original
          ? result.data.items[0].ecm_name_original
          : "",
        ecm_map_id: result.data.items[0].ecm_map_id
          ? result.data.items[0].ecm_map_id
          : "",
        sensor_id: result.data.items[0].sensor_id
          ? result.data.items[0].sensor_id
          : "",
        tss_source_url: result.data.items[0].tss_source_url
          ? result.data.items[0].tss_source_url
          : "",
        cctv_img_url: result.data.items[0].cctv_img_url
          ? result.data.items[0].cctv_img_url
          : "",
        remarks: result.data.items[0].remarks
          ? result.data.items[0].remarks
          : "",
      });
      setModifiedData({
        modified_at: result.data.items[0].modified_at
          ? moment(new Date(result.data.items[0].modified_at)).format(
              "YYYY-MM-DD"
            )
          : "-",
        modified_time: result.data.items[0].modified_at
          ? moment(new Date(result.data.items[0].modified_at)).format("h:mm a")
          : "-",
        modifier_name: result.data.items[0].modifier_name
          ? result.data.items[0].modifier_name
          : "-",
        creator_name: result.data.items[0].creator_name
          ? result.data.items[0].creator_name
          : "",
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    // dispatch(fetchGeneralConfig());

    if (id) {
      getECMDetail();
    } else {
      setEditMode(true);
      setLoading(false);
    }
  }, [id]);

  const handleSaveClicked = () => {
    setConfirmBox(true);
  };

  const handleSaveToAPI = async () => {
    setConfirmBox(false);
    setLoading(true);

    console.log(editData);
    const token = await getCurrentLoggedInSessionTokenWithPromise();
    const currentLoginUser = await getCurrentLoggedInUserWithPromise();
    const result = await api.post(
      CREATE_UPDATE_ECM,
      JSON.stringify({
        data_type: "ECM",
        action: id ? "update" : "create",
        data: id
          ? { ...editData, modified_by: currentLoginUser["sub"] }
          : { ...editData, created_by: currentLoginUser["sub"] },
      }),
      token
    );
    console.log(result);
    if (result.status_code === 200) {
      if (id) {
        setEditMode(false);
        getECMDetail();
      } else {
        setEditMode(false);
        navigate(`${ECM_DETAIL_ROUTE}?id=${result.data[0].ecm_no}`);
      }
    } else {
      alert(result.error);
      setEditMode(false);
      navigate(ECM_MANAGMENT);
    }
  };

  const handleEditMode = () => {
    setEditMode(true);
  };
  const DeleteCCTV = () => {
    setShowDelete(true);
  };
  const ConfrimDelete = async () => {
    setLoading(true);
    setShowDelete(false);
    const token = await getCurrentLoggedInSessionTokenWithPromise();
    const result = await api.post(
      CREATE_UPDATE_ECM,
      JSON.stringify({
        data_type: "ECM",
        action: "delete",
        data: {
          ecm_no: editData.ecm_no,
          ecm_name: editData.ecm_name,
        },
      }),
      token
    );
    if (result.status_code === 200) {
      console.log(result);
      navigate(ECM_MANAGMENT);
    } else {
      alert(result.message);
    }
  };

  return (
    <>
      {showDelete && (
        <Dialog>
          <div className="CCTV-filter-width_resize">
            <div
              style={{
                position: "absolute",
                top: "15px",
                right: "13px",
                cursor: "pointer",
              }}
              onClick={() => setShowDelete(false)}
            >
              <img src={CrossIcon} />
            </div>
            <div className="CCTV_Delete_confirm_title">Delete!</div>
            <div className="CCTV_Delete_confirm_text">
              Are you sure? You want to delete this ECM!
            </div>
            <div className="CCTV_Filter_button_main_box">
              <div
                className="CCTV_Filter_clear_button"
                onClick={() => setShowDelete(false)}
              >
                Cancel
              </div>
              <div className="CCTV_Filter_apply_button" onClick={ConfrimDelete}>
                Confirm
              </div>
            </div>
          </div>
        </Dialog>
      )}
      {confirmBox && (
        <ConfirmMsg
          onCloseDialog={() => {
            setConfirmBox(false);
          }}
          msgTitle={`Are you sure you really want to change the ecm details?`}
          okBtnMsg={"Yes, proceed"}
          okBtnClicked={handleSaveToAPI}
          cancelBtnMsg={"No, cancel"}
          cancelBtnClicked={() => {
            setConfirmBox(false);
          }}
        />
      )}
      {loading ? (
        <Loader
          type="infinite-spinner"
          style={{
            display: "flex",
            alignItems: "center",
            width: "40px",
            margin: "auto",
            justifyContent: "center",
            height: "640px",
          }}
        />
      ) : (
        <div style={{ background: "white" }}>
          <div className="ECM_form_toolbar">
            <div
              className="ECM_form_toolbar_back_icon"
              onClick={() => navigate(ECM_MANAGMENT)}
            >
              <img src={BackIcon} />
            </div>
            <div className="ECM_form_toolbar_title">ECM Details</div>
          </div>
          <div className="ECM_create_scroll_box">
            <div className="ECM_create_main_box">
              <div className="ECM_create_editable_main_box">
                <div className="ECM_create_editable_title">
                  ECM Display Name in Digital Twin No. * (This is an unique
                  Identifier. It cannot be changed after save. This will appear
                  in the Website)
                </div>
                <div>
                  <Input
                    className="ECM_form_input_editable"
                    type="text"
                    defaultValue={editData.ecm_no}
                    disabled={id ? true : false}
                    onChange={(e) => {
                      setEditData({
                        ...editData,
                        ecm_no: e.target.value,
                      });
                    }}
                  />
                  {editMode && editData.ecm_no === "" && (
                    <div className="ECM_error_text">
                      This field is required!
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="ECM_create_main_box">
              <div className="ECM_create_editable_main_box">
                <div className="ECM_create_editable_title">
                  ECM Display Name in Digital Twin Description. * (This is an
                  unique Identifier. It cannot be changed after save. This will
                  appear in the Website)
                </div>
                <div>
                  <Input
                    className="ECM_form_input_editable"
                    type="text"
                    defaultValue={editData.ecm_name}
                    disabled={id ? true : false}
                    onChange={(e) => {
                      setEditData({
                        ...editData,
                        ecm_name: e.target.value,
                      });
                    }}
                  />
                  {editMode && editData.ecm_name === "" && (
                    <div className="ECM_error_text">
                      This field is required!
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="ECM_create_main_box">
              <div className="ECM_create_editable_main_box">
                <div className="ECM_create_editable_title">Package ID *</div>
                <div>
                  <DropDownList
                    className="ECM_form_input_editable"
                    style={{
                      opacity: !editMode && "0.6",
                      background: "white",
                      height: "48px",
                    }}
                    data={[
                      "All",
                      ...general_config_data.general_config_data.package,
                    ]}
                    defaultValue={"Select"}
                    value={editData.package}
                    onChange={(e) => {
                      setEditData({
                        ...editData,
                        package: e.target.value,
                      });
                    }}
                    disabled={!editMode}
                  />
                  {editMode && editData.package === "" && (
                    <div className="ECM_error_text">
                      This field is required!
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="ECM_create_main_box">
              <div className="ECM_create_editable_main_box">
                <div className="ECM_create_editable_title">
                  Camera Position Lat (E.g: 1.3412) *
                </div>
                <div>
                  <Input
                    className="ECM_form_input_editable"
                    defaultValue={editData.lat}
                    // defaultValue={editData.camera_position.join(", ")}
                    disabled={!editMode}
                    type="text"
                    onChange={(e) => {
                      setEditData({
                        ...editData,
                        // camera_position: e.target.value.split(", "),
                        lat: e.target.value,
                      });
                    }}
                  />
                  {editMode ? (
                    editData.lat === "" ? (
                      <div className="ECM_error_text">
                        This field is required! Required format (1.3412,
                        103.21231)
                      </div>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="ECM_create_editable_main_box">
                <div className="ECM_create_editable_title">
                  Camera Position Long (E.g: 103.21231) *
                </div>
                <div>
                  <Input
                    className="ECM_form_input_editable"
                    defaultValue={editData.long}
                    disabled={!editMode}
                    type="text"
                    onChange={(e) => {
                      setEditData({
                        ...editData,
                        long: e.target.value,
                        // camera_position: e.target.value.split(", "),
                      });
                    }}
                  />
                  {editMode ? (
                    editData.long === "" ? (
                      <div className="ECM_error_text">
                        This field is required! Required format (1.3412,
                        103.21231)
                      </div>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            <div className="ECM_create_main_box">
              <div className="ECM_create_editable_main_box">
                <div className="ECM_create_editable_title">
                  ECM Location Description *
                </div>
                <div>
                  <Input
                    className="ECM_form_input_editable"
                    defaultValue={editData.ecm_location_description}
                    disabled={!editMode}
                    type="text"
                    onChange={(e) => {
                      setEditData({
                        ...editData,
                        ecm_location_description: e.target.value,
                      });
                    }}
                  />
                  {editMode && editData.ecm_location_description === "" && (
                    <div className="ECM_error_text">
                      This field is required!
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="ECM_create_main_box">
              <div className="ECM_create_editable_main_box">
                <div className="ECM_create_editable_title">ECM Map ID *</div>
                <div>
                  <Input
                    className="ECM_form_input_editable"
                    defaultValue={editData.ecm_map_id}
                    disabled={!editMode}
                    type="text"
                    onChange={(e) => {
                      setEditData({
                        ...editData,
                        ecm_map_id: e.target.value,
                      });
                    }}
                  />
                  {editMode && editData.ecm_map_id === "" && (
                    <div className="ECM_error_text">
                      This field is required!
                    </div>
                  )}
                </div>
              </div>
              <div className="ECM_create_editable_main_box">
                <div className="ECM_create_editable_title">
                  ECM Name(Original)
                </div>
                <div>
                  <Input
                    className="ECM_form_input_editable"
                    disabled={!editMode}
                    type="text"
                    defaultValue={editData.ecm_name_original}
                    onChange={(e) => {
                      setEditData({
                        ...editData,
                        ecm_name_original: e.target.value,
                      });
                    }}
                  />
                  {editMode && editData.ecm_name_original === "" && (
                    <div className="ECM_error_text">
                      This field is required!
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="ECM_create_main_box">
              <div className="ECM_create_editable_main_box">
                <div className="ECM_create_editable_title">ECM ID *</div>
                <div>
                  <Input
                    className="ECM_form_input_editable"
                    type="text"
                    disabled={!editMode}
                    defaultValue={editData.ecm_id}
                    onChange={(e) => {
                      setEditData({
                        ...editData,
                        ecm_id: e.target.value,
                      });
                    }}
                  />
                  {editMode && editData.ecm_id === "" && (
                    <div className="ECM_error_text">
                      This field is required!
                    </div>
                  )}
                </div>
              </div>
              <div className="ECM_create_editable_main_box">
                <div className="ECM_create_editable_title">Sensor ID *</div>
                <div>
                  <Input
                    className="ECM_form_input_editable"
                    type="text"
                    disabled={!editMode}
                    defaultValue={editData.sensor_id}
                    onChange={(e) => {
                      setEditData({
                        ...editData,
                        sensor_id: e.target.value,
                      });
                    }}
                  />
                  {editMode && editData.sensor_id === "" && (
                    <div className="ECM_error_text">
                      This field is required!
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="ECM_create_main_box">
              <div className="ECM_create_editable_main_box">
                <div className="ECM_create_editable_title">TSS *</div>
                <div>
                  <Input
                    className="ECM_form_input_editable"
                    type="text"
                    disabled={!editMode}
                    defaultValue={editData.tss_source_url}
                    onChange={(e) => {
                      setEditData({
                        ...editData,
                        tss_source_url: e.target.value,
                      });
                    }}
                  />
                  {editMode && editData.tss_source_url === "" && (
                    <div className="ECM_error_text">
                      This field is required!
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="ECM_create_main_box">
              <div className="ECM_create_editable_main_box">
                <div className="ECM_create_editable_title">CCTV *</div>
                <div>
                  <Input
                    className="ECM_form_input_editable"
                    type="text"
                    disabled={!editMode}
                    defaultValue={editData.cctv_img_url}
                    onChange={(e) => {
                      setEditData({
                        ...editData,
                        cctv_img_url: e.target.value,
                      });
                    }}
                  />
                  {editMode && editData.cctv_img_url === "" && (
                    <div className="ECM_error_text">
                      This field is required!
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="ECM_create_main_box">
              <div className="ECM_create_editable_main_box">
                <div className="ECM_create_editable_title">Remarks *</div>
                <div>
                  <TextArea
                    className="remark-textarea"
                    style={{ height: "150px" }}
                    disabled={!editMode}
                    rows={6}
                    autoSize={true}
                    value={editData.remarks}
                    onChange={(e) =>
                      setEditData({
                        ...editData,
                        remarks: e.target.value,
                      })
                    }
                  />
                  {editMode && editData.remarks === "" && (
                    <div className="ECM_error_text">
                      This field is required!
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="ECM_form_footer">
            <div style={{ display: "flex", gap: "12px" }}></div>
            <div className="ECM_form_footer_child_box">
              {id && !editMode ? (
                <div className="ECM_form_footer_save" onClick={DeleteCCTV}>
                  Delete
                </div>
              ) : (
                ""
              )}
              {id && !editMode ? (
                <div
                  className="ECM_form_footer_audit_trail"
                  onClick={() => navigate(`${ECM_AUDIT_TRAIL}?id=${id}`)}
                >
                  View Audit Trail
                </div>
              ) : (
                ""
              )}
              <div
                className="ECM_form_footer_cancel"
                // onClick={handleCancelClicked}
                onClick={() => navigate(ECM_MANAGMENT)}
              >
                Cancel
              </div>
              <div
                className="ECM_form_footer_save"
                style={{
                  pointerEvents: id
                    ? editMode
                      ? validator()
                        ? "none"
                        : ""
                      : ""
                    : validator()
                    ? "none"
                    : "",
                  opacity: id
                    ? editMode
                      ? validator()
                        ? "0.5"
                        : "1"
                      : "1"
                    : validator()
                    ? "0.5"
                    : "1",
                }}
                onClick={
                  id
                    ? editMode
                      ? handleSaveClicked
                      : handleEditMode
                    : handleSaveClicked
                }
              >
                {editMode ? "Save" : "Edit"}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
