import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Input } from "@progress/kendo-react-inputs";
import { Loader } from "@progress/kendo-react-all";
import { Dialog } from "@progress/kendo-react-dialogs";
// components
import FullscreenLoader from "../../components/fullScreenLoader";
import { SelectTable } from "../../components/table/selectTable";
import { Pagination } from "../../components/pagination/pagination";
import { Error } from "@progress/kendo-react-labels";
// api
import config from "../../config/config";
import api from "../../config/api";
import {
  getCurrentLoggedInSessionTokenWithPromise,
  getCurrentLoggedInUserWithPromise,
} from "../../config/userPool";
import { fetchALLCompany } from "../../store/companyList";
// icon
import BackIcon from "../../assets/images/Icon/PreviousIcon.svg";
import CrossIcon from "../../assets/images/Icon/Cross_black.svg";
// route
import {
  GROUP_MANAGEMENT_ROUTE,
  GROUP_MANAGEMENT_AUDIT_TRAIL,
} from "../../routes";

import moment from "moment";
// css
import "./group.css";

const header = [
  {
    name: "Name",
  },
  {
    name: "Company",
    width: "200px",
  },
  {
    name: "Package",
  },
  {
    name: "Roles",
  },
  {
    name: "Join Date",
    width: "120px",
  },
];

const userListHeaders = [
  {
    name: "Name",
    width: "150px",
  },
  {
    name: "Company",
    width: "150px",
  },
  {
    name: "Package",
  },
  {
    name: "Roles",
  },
];

export const GroupManagement_form = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let { search } = useLocation();
  const query = new URLSearchParams(search);
  const id = query.get("id");
  const mandatoryFields = ["user_group_name", "permission_ids"];
  const [tab, setTab] = useState(0);
  const [editData, setEditData] = useState({
    created_date: moment(new Date()).format("DD MMM YYYY"),
    user_group_name: "",
    permission_ids: [],
  });
  const [allCompanies, setAllCompanies] = useState([]);
  const { companyList, loading: companyLoading } = useSelector(
    (state) => state.companyList
  );
  const [errors, setErrors] = useState({});
  const [groupMember, setGroupMember] = useState([]);
  const [permission, setPermission] = useState([]);
  const [loading, setLoading] = useState(true);
  const [fullScreenLoading, setFullScreenLoading] = useState(false);
  const [groupUser, setGroupUser] = useState({});
  const [activePages, setActivePages] = useState(1);
  const [membersToRemove, setMembersToRemove] = useState([]);
  const [headerCheckboxFlag, setHeaderCheckboxFlag] = useState(false);
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const {
    GET_GROUP_DETAIL,
    GET_PERMISSION_LIST,
    GET_GROUP_MEMBER_LIST,
    GET_USER_LIST,
    DELETE_GROUP_ENDPOINT,
  } = config.api_endpoint;

  const [showAddMemberDialog, setShowAddMemberDialog] = useState(false);
  const [showAddMemberLoading, setShowAddMemberLoading] = useState(false);
  const [activePageAddMember, setActivePageAddMember] = useState(1);
  const [addUser, setAddUser] = useState({});
  const [addUserList, setAddUserList] = useState([]);
  const [membersToAdd, setMembersToAdd] = useState([]);
  const [headerAddMemberCheckboxFlag, setHeaderAddMemberCheckboxFlag] =
    useState(false);

  const handleAddMemberPageChange = (page) => {
    setShowAddMemberLoading(true);
    setAddUserList([]);
    setActivePageAddMember(page);
    getAddMembersList(page);
  };

  const handlePageChange = (page) => {
    setLoading(true);
    setGroupMember([]);
    setActivePages(page);
    callGroupUser(page);
  };

  const callGroupUser = async (page) => {
    const token = await getCurrentLoggedInSessionTokenWithPromise();
    const group_user = await api.get(
      GET_GROUP_MEMBER_LIST,
      {
        id: id,
        page: page,
        page_size: 10,
      },
      token
    );
    if (group_user.status_code === 200) {
      console.log(group_user);
      setGroupUser(group_user);
      const members = [];
      for (let i = 0; i < group_user.data.items.length; i++) {
        let company = [];
        for (let j = 0; j < group_user.data.items[i].company.length; j++) {
          company.push(group_user.data.items[i].company[j].company_name);
        }
        members.push({
          id: group_user.data.items[i].sub,
          item1: group_user.data.items[i].name,
          item2: company.join(", "),
          item3: group_user.data.items[i].package.join(", "),
          item4: group_user.data.items[i].role.replaceAll(",", ", "),
          item5: moment(group_user.data.items[i].joined_date).format(
            "DD MMM YYYY"
          ),
          selected:
            membersToRemove.indexOf(group_user.data.items[i].sub) > -1
              ? true
              : false,
        });
      }
      setGroupMember([...members]);
      setLoading(false);
    }
  };

  const handleCancelClicked = () => {
    console.log("cancel button clicked.");
    navigate(-1);
  };

  const validateInputs = (data) => {
    const _errors = {};
    mandatoryFields.forEach((_field) => {
      if (["user_group_name", "permission_ids"].indexOf(_field) > -1) {
        if (data[_field].length === 0) {
          _errors[_field] = "This field is required.";
        }
      } else {
        if (!data[_field]) {
          _errors[_field] = "This field is required.";
        }
      }
    });

    return _errors;
  };

  const handleRemoveMemberClicked = async () => {
    console.log("Remove Member Clicked.");
    console.log("Members to remove:", membersToRemove);

    if (membersToRemove.length == 0) {
      return;
    }

    const currentLoginUser = await getCurrentLoggedInUserWithPromise();
    console.log("currentLoginUser:", currentLoginUser);

    const toSaveData = {
      id,
      user_ids: membersToRemove,
      updated_by: currentLoginUser["sub"],
    };
    console.log("remove member data:", toSaveData);

    setFullScreenLoading(true);
    const token = await getCurrentLoggedInSessionTokenWithPromise();
    api
      .remove(GET_GROUP_MEMBER_LIST, JSON.stringify(toSaveData), token)
      .then((res) => {
        console.log("remove member result: ", res);
        setFullScreenLoading(false);
        if (res["status_code"] >= 200 && res["status_code"] < 300) {
          alert("Group members removed.");
          setHeaderCheckboxFlag(false);
          setGroupMember([]);
          setMembersToRemove([]);
          handlePageChange(1);
        } else {
          alert("Unable to remove the group members.");
        }
      })
      .catch((err) => {
        console.log("remove the group members err", err);
        setFullScreenLoading(false);
      });
  };

  const handleSaveClicked = async () => {
    console.log("save button clicked.");
    if (Object.keys(errors).length > 0) {
      return;
    }

    const currentLoginUser = await getCurrentLoggedInUserWithPromise();
    console.log("currentLoginUser:", currentLoginUser);

    const toSaveData = JSON.parse(JSON.stringify(editData));

    if (editData.id) {
      toSaveData["updated_by"] = currentLoginUser["sub"];
    } else {
      toSaveData["created_by"] = currentLoginUser["sub"];
    }

    console.log("save data:", toSaveData);

    setFullScreenLoading(true);
    const token = await getCurrentLoggedInSessionTokenWithPromise();
    api
      .post(GET_GROUP_DETAIL, JSON.stringify(toSaveData), token)
      .then((res) => {
        console.log("save group result: ", res);
        setFullScreenLoading(false);
        if (res["status_code"] >= 200 && res["status_code"] < 300) {
          alert("Group is successfully saved.");
          navigate(GROUP_MANAGEMENT_ROUTE);
        } else {
          alert("Unable to save the group.");
        }
      })
      .catch((err) => {
        console.log("save the group err", err);
        setFullScreenLoading(false);
      });
  };

  const handleHeaderCheckboxClicked = (row) => {
    console.log("header checkbox clicked.");
    const flag = headerCheckboxFlag ? false : true;
    console.log("flag:", flag);
    setHeaderCheckboxFlag(!headerCheckboxFlag);

    let members = [...groupMember];
    for (let i = 0; i < members.length; i++) {
      members[i].selected = flag;
    }
    setGroupMember(members);

    let mtr = [...membersToRemove];
    let toRemove = flag ? members.map((x) => x.id) : [];
    console.log("toRemove:", toRemove);
    if (toRemove.length > 0) {
      mtr.push(...toRemove);
    } else {
      let mem = members.map((x) => x.id);
      console.log("mem:", mem);
      mtr = membersToRemove.filter((x) => mem.indexOf(x) < 0);
    }
    setMembersToRemove(mtr);
    console.log("membersToRemove:", mtr);
    console.log("groupMember:", members);
  };

  const handleRowCheckboxClicked = (row) => {
    console.log(
      "row clicked:",
      row,
      row.target.value,
      row.target.getAttribute("data-id")
    );
    console.log("group members:", groupMember);

    let _id = row.target.id;
    let _val = row.target.value;

    let members = [...groupMember];
    let flag = true;
    if (membersToRemove.indexOf(_id) > -1) {
      flag = false;
    }
    for (let i = 0; i < members.length; i++) {
      if (members[i].id == _id) {
        members[i].selected = flag;
      }
    }
    setGroupMember(members);

    let toRemove = [...membersToRemove];
    if (flag) {
      toRemove.push(_id);
    } else {
      toRemove = toRemove.filter((x) => x !== _id);
    }
    console.log("toRemove:", toRemove);
    setMembersToRemove(toRemove);
  };

  const getGroupDetail = async () => {
    const token = await getCurrentLoggedInSessionTokenWithPromise();
    const result = await api.get(
      GET_GROUP_DETAIL,
      {
        id: id,
      },
      token
    );
    if (result.status_code === 200) {
      setEditData({
        ...editData,
        id: result.data.id,
        user_group_name: result.data.user_group_name,
        created_date: moment(result.data.created_date).format("DD MMM YYYY"),
        permission_ids: result.data.permission_ids,
      });
      const permissionss = await api.get(GET_PERMISSION_LIST, {}, token);
      if (permissionss.status_code === 200) {
        let allPermissions = permissionss.data.items;
        allPermissions.forEach((element) => {
          element["selected"] =
            result.data.permission_ids.indexOf(element.permission_code) > -1
              ? true
              : false;
        });

        setPermission(allPermissions);
      }
      setLoading(false);
    }
  };

  const getPermission = async () => {
    const token = await getCurrentLoggedInSessionTokenWithPromise();
    const permission = await api.get(GET_PERMISSION_LIST, {}, token);
    if (permission.status_code === 200) {
      let allPermissions = permission.data.items;
      allPermissions.forEach((element) => {
        element["selected"] = false;
      });

      setPermission(allPermissions);
      setLoading(false);
    }
  };

  useEffect(() => {
    console.log("companyList:", companyList);
    if (companyList.companyList && companyList.companyList.items.length > 0) {
      let _items = [];
      companyList.companyList.items.forEach((element) => {
        _items.push(element);
      });
      setAllCompanies(_items);
    }
  }, [companyList]);

  useEffect(() => {
    if (id) {
      dispatch(fetchALLCompany({ page: 1, page_size: 9999 }));
      getGroupDetail();
    } else {
      getPermission();
    }
  }, [id]);

  useEffect(() => {
    const errs = validateInputs(editData);
    setErrors(errs);
  }, [editData]);

  const onToggleDialog = () => {
    setShowAddMemberDialog(false);
    setMembersToAdd([]);
    setMembersToRemove([]);
    setAddUser({});
    setActivePageAddMember(1);
    setHeaderAddMemberCheckboxFlag(false);
    setHeaderCheckboxFlag(false);
  };

  const handleAddMemberClicked = () => {
    setMembersToAdd([]);
    setAddUser({});
    setActivePageAddMember(1);
    setShowAddMemberDialog(true);
    setShowAddMemberLoading(true);
    getAddMembersList(activePageAddMember);
  };

  const getAddMembersList = async (page) => {
    const token = await getCurrentLoggedInSessionTokenWithPromise();
    api
      .get(
        GET_USER_LIST,
        {
          exclude_id: id,
          exclude_field: "user_group",
          page: page,
          page_size: 10,
        },
        token
      )
      .then((res) => {
        setShowAddMemberLoading(false);
        console.log("get excluded users:", res);
        setAddUser(res.data);
        const members = [];
        for (let i = 0; i < res.data.items.length; i++) {
          let company = [];
          for (let j = 0; j < res.data.items[i].company.length; j++) {
            let tmp = allCompanies.find(
              (x) => x.id === res.data.items[i].company[j]
            );
            if (tmp) {
              company.push(tmp.company_name);
            }
          }
          members.push({
            id: res.data.items[i].id,
            item1: res.data.items[i].name,
            item2: company.join(", "),
            item3: res.data.items[i].package.join(", "),
            item4: res.data.items[i].role.replaceAll(",", ", "),
            selected:
              membersToAdd.indexOf(res.data.items[i].id) > -1 ? true : false,
          });
        }
        setAddUserList(members);
      })
      .catch((err) => {
        console.log("get excluded users err", err);
        setShowAddMemberLoading(false);
      });
  };

  const handleAddMemberHeaderCheckboxClicked = (row) => {
    console.log("header checkbox clicked.");
    const flag = headerAddMemberCheckboxFlag ? false : true;
    console.log("flag:", flag);
    setHeaderAddMemberCheckboxFlag(!headerAddMemberCheckboxFlag);

    let members = [...addUserList];
    for (let i = 0; i < members.length; i++) {
      members[i].selected = flag;
    }
    setAddUserList(members);

    let mtr = [...membersToAdd];
    let toAdd = flag ? members.map((x) => x.id) : [];
    console.log("toAdd:", toAdd);
    if (toAdd.length > 0) {
      mtr.push(...toAdd);
    } else {
      let mem = members.map((x) => x.id);
      console.log("mem:", mem);
      mtr = membersToAdd.filter((x) => mem.indexOf(x) < 0);
    }
    setMembersToAdd(mtr);
    console.log("membersToAdd:", mtr);
    console.log("addUserList:", members);
  };

  const handleAddMemberRowCheckboxClicked = (row) => {
    console.log(
      "row clicked:",
      row,
      row.target.value,
      row.target.getAttribute("data-id")
    );
    console.log("add members:", addUserList);

    let _id = row.target.id;
    let _val = row.target.value;

    let members = [...addUserList];
    let flag = true;
    if (membersToAdd.indexOf(_id) > -1) {
      flag = false;
    }
    for (let i = 0; i < members.length; i++) {
      if (members[i].id == _id) {
        members[i].selected = flag;
      }
    }
    setAddUserList(members);

    let toAdd = [...membersToAdd];
    if (flag) {
      toAdd.push(_id);
    } else {
      toAdd = toAdd.filter((x) => x !== _id);
    }
    console.log("toAdd:", toAdd);
    setMembersToAdd(toAdd);
  };

  const handleAddMemberCancelClicked = () => {
    console.log("cancel button on add member dialog clicked.");
    setShowAddMemberDialog(false);
    setMembersToAdd([]);
    setMembersToRemove([]);
    setAddUser({});
    setActivePageAddMember(1);
    setHeaderAddMemberCheckboxFlag(false);
    setHeaderCheckboxFlag(false);
  };

  const handleAddMemberSaveClicked = async () => {
    console.log("save button on add member dialog clicked.");
    console.log("membersToAdd:", membersToAdd);

    if (membersToAdd.length == 0) {
      return;
    }

    const currentLoginUser = await getCurrentLoggedInUserWithPromise();
    console.log("currentLoginUser:", currentLoginUser);

    const toSaveData = {
      id,
      user_ids: membersToAdd,
      created_by: currentLoginUser["sub"],
    };
    console.log("add member data:", toSaveData);

    setFullScreenLoading(true);
    const token = await getCurrentLoggedInSessionTokenWithPromise();
    api
      .post(GET_GROUP_MEMBER_LIST, JSON.stringify(toSaveData), token)
      .then((res) => {
        console.log("add member result: ", res);
        setFullScreenLoading(false);
        if (res["status_code"] >= 200 && res["status_code"] < 300) {
          alert("Group members added.");
          setShowAddMemberDialog(false);
          setAddUser([]);
          setMembersToAdd([]);
          setMembersToRemove([]);
          handlePageChange(1);
        } else {
          alert("Unable to add the group members.");
        }
      })
      .catch((err) => {
        console.log("add the group members err", err);
        setFullScreenLoading(false);
      });
  };

  const handleDeleteGroup = async () => {
    const token = await getCurrentLoggedInSessionTokenWithPromise();
    const currentLoginUser = await getCurrentLoggedInUserWithPromise();
    setLoading(true);
    setDeleteConfirm(false);
    api
      .remove(
        DELETE_GROUP_ENDPOINT,
        JSON.stringify({
          id: id,
          // user_ids: memberIds,
          removed_by: currentLoginUser["sub"],
        }),
        token
      )
      .then((res) => {
        console.log(res);
        if (res.status_code === 200) {
          navigate(GROUP_MANAGEMENT_ROUTE);
        }
      });
  };

  return (
    <>
      {showAddMemberDialog && (
        <Dialog>
          <div className="group-add-member-width_resize">
            <div
              style={{
                position: "absolute",
                top: "15px",
                right: "13px",
                cursor: "pointer",
              }}
              onClick={onToggleDialog}
            >
              <img src={CrossIcon} />
            </div>
            <div className="group-add-member-title">Add Member</div>
            {showAddMemberLoading ? (
              <Loader
                type="infinite-spinner"
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "50vw",
                  margin: "auto",
                  display: "flex",
                  justifyContent: "center",
                  height: "50vh",
                }}
              />
            ) : (
              <>
                <SelectTable
                  header={userListHeaders}
                  data={addUserList}
                  class={"minHeight"}
                  width={"1000px"}
                  maxHeight={"350px"}
                  cutHeaderHeight={false}
                  rowCheckboxClicked={handleAddMemberRowCheckboxClicked}
                  headerCheckboxFlag={headerCheckboxFlag}
                  headerCheckboxClicked={handleAddMemberHeaderCheckboxClicked}
                />
                <Pagination
                  pageCount={addUser && addUser.total_pages}
                  handlePageChange={handleAddMemberPageChange}
                  activePages={activePageAddMember}
                />
                {addUserList.length === 0 && (
                  <p className="Group_detail_main_box_desc">
                    No available users.
                  </p>
                )}
                <div class="Group_add_member_dialog_footer">
                  <div
                    className="Group_form_footer_cancel"
                    onClick={handleAddMemberCancelClicked}
                  >
                    Cancel
                  </div>
                  <div
                    className={
                      membersToAdd.length <= 0
                        ? "Group_form_footer_save disabled"
                        : "Group_form_footer_save "
                    }
                    onClick={handleAddMemberSaveClicked}
                  >
                    Submit
                  </div>
                </div>
              </>
            )}
          </div>
        </Dialog>
      )}
      {deleteConfirm && (
        <Dialog>
          <div style={{ position: "relative" }}>
            <div
              className="group-delete-close-button"
              onClick={() => setDeleteConfirm(false)}
            >
              X
            </div>
          </div>
          <div className="group-delete-main-box ">
            <div className="group-delete-title">Delete !</div>
            <div className="group-delete-detail">
              Are you sure? Do you want to delete group?
            </div>
            <div className="group-delete-button-main-box">
              <div
                className="group-delete-button-confirm"
                onClick={handleDeleteGroup}
              >
                Yes
              </div>
              <div
                className="group-delete-button-cancel"
                onClick={() => setDeleteConfirm(false)}
              >
                Cancel
              </div>
            </div>
          </div>
        </Dialog>
      )}
      {fullScreenLoading && <FullscreenLoader />}
      {loading ? (
        <Loader
          type="infinite-spinner"
          style={{
            display: "flex",
            alignItems: "center",
            width: "40px",
            margin: "auto",
            display: "flex",
            justifyContent: "center",
            height: "100vh",
          }}
        />
      ) : (
        <div style={{ background: "white" }}>
          <div className="Group_form_toolbar">
            <div
              className="Group_form_toolbar_back_icon"
              onClick={() => navigate(GROUP_MANAGEMENT_ROUTE)}
            >
              <img src={BackIcon} />
            </div>
            <div className="Group_form_toolbar_title">Group Details</div>
          </div>
          <div className="Group_form_sec_toolbar">
            <div
              className={
                tab === 0
                  ? "Group_form_sec_toolbar_item_active"
                  : "Group_form_sec_toolbar_item_unactive"
              }
              onClick={() => setTab(0)}
            >
              Details
            </div>
            {id && (
              <div
                className={
                  tab === 1
                    ? "Group_form_sec_toolbar_item_active"
                    : "Group_form_sec_toolbar_item_unactive"
                }
                onClick={() => {
                  setTab(1);
                  handlePageChange(1);
                }}
              >
                Member List
              </div>
            )}
            {tab === 1 && (
              <div
                className="Group_memberList_add_button"
                onClick={handleAddMemberClicked}
              >
                <div>+</div>
                <div>Add Member</div>
              </div>
            )}
          </div>
          {tab === 0 ? (
            <div className="Group_scroll">
              <div className="Group_form_main_box">
                {id && (
                  <div style={{ marginTop: "24px" }}>
                    <div className="Group_form_input_title">
                      Group created on
                    </div>
                    <div>
                      <Input
                        className="Group_form_input"
                        defaultValue={editData.created_date}
                        type="text"
                        disabled
                      />
                    </div>
                  </div>
                )}
                <div style={{ marginTop: "24px" }}>
                  <div className="Group_form_input_title">Group Name</div>
                  <div>
                    <Input
                      className="Group_form_input_editable"
                      defaultValue={editData.user_group_name}
                      type="text"
                      onChange={(e) => {
                        setEditData({
                          ...editData,
                          user_group_name: e.target.value,
                        });
                      }}
                    />
                    {errors.user_group_name && (
                      <Error>{errors.user_group_name}</Error>
                    )}
                  </div>
                </div>
                <div style={{ marginTop: "24px" }}>
                  <div className="Group_form_input_title">Permission</div>
                </div>
                <div style={{ marginBottom: "50px" }}>
                  {errors.permission_ids && (
                    <Error>{errors.permission_ids}</Error>
                  )}
                  {permission.length > 0 &&
                    permission.map((j) => (
                      <div
                        key={Math.floor(Math.random() * 10000000)}
                        style={{
                          marginTop: "24px",
                          display: "flex",
                          alignItems: "center",
                          gap: 10,
                        }}
                      >
                        <div style={{ width: "24px" }}>
                          <label className="container">
                            <input
                              type="checkbox"
                              id={Math.floor(Math.random() * 10000000)}
                              data-id={`${j.permission_code}`}
                              defaultChecked={j.selected}
                              onChange={(e) => {
                                let perm_code =
                                  e.target.getAttribute("data-id");
                                console.log("Permission Clicked:", perm_code);

                                let copied = JSON.parse(
                                  JSON.stringify(editData)
                                );
                                if (
                                  copied.permission_ids.find(
                                    (da) => da === perm_code
                                  )
                                ) {
                                  copied.permission_ids =
                                    copied.permission_ids.filter(
                                      (x) => x != perm_code
                                    );
                                } else {
                                  copied.permission_ids.push(perm_code);
                                }
                                setEditData(copied);

                                permission.forEach((element) => {
                                  if (element.permission_code == perm_code) {
                                    element.selected = !element.selected;
                                  }
                                });
                                setPermission(permission);
                              }}
                            />
                            <span className="checkmark"></span>
                          </label>
                        </div>
                        <div className="Group_form_check_title">
                          {j.permission_name}
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          ) : (
            <>
              {loading ? (
                <Loader
                  type="infinite-spinner"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "40px",
                    margin: "auto",
                    display: "flex",
                    justifyContent: "center",
                    height: "640px",
                  }}
                />
              ) : (
                <>
                  <SelectTable
                    header={header}
                    data={groupMember}
                    class={"minHeight"}
                    maxHeight={"381px"}
                    cutHeaderHeight={true}
                    rowCheckboxClicked={handleRowCheckboxClicked}
                    headerCheckboxFlag={headerCheckboxFlag}
                    headerCheckboxClicked={handleHeaderCheckboxClicked}
                  />
                  {groupMember.length === 0 && (
                    <p className="Group_detail_main_box_desc">
                      No available group members.
                    </p>
                  )}
                </>
              )}
              <Pagination
                pageCount={groupUser && groupUser.total_pages}
                handlePageChange={handlePageChange}
                activePages={activePages}
              />
            </>
          )}
          <div className="Group_form_footer">
            <div style={{ display: "flex", gap: "12px" }}></div>
            <div className="Group_form_footer_child_box">
              {id && (
                <div
                  className="Group_form_footer_audit_trail"
                  onClick={() =>
                    navigate(`${GROUP_MANAGEMENT_AUDIT_TRAIL}?id=${id}`)
                  }
                >
                  View Audit Trail
                </div>
              )}
              <div
                className="Group_form_footer_cancel"
                onClick={handleCancelClicked}
              >
                Cancel
              </div>
              {tab === 0 && (
                <div
                  className={
                    Object.keys(errors).length > 0
                      ? "Group_form_footer_save disabled"
                      : "Group_form_footer_save "
                  }
                  onClick={handleSaveClicked}
                >
                  Save
                </div>
              )}
              {tab === 0 && (
                <div
                  className={
                    Object.keys(errors).length > 0
                      ? "Group_form_footer_save disabled"
                      : "Group_form_footer_save "
                  }
                  onClick={() => setDeleteConfirm(true)}
                >
                  Delete
                </div>
              )}
              {tab === 1 && (
                <div
                  className={
                    membersToRemove.length == 0
                      ? "Group_form_footer_remove disabled"
                      : "Group_form_footer_remove "
                  }
                  onClick={handleRemoveMemberClicked}
                >
                  Remove Member
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
