// redux
import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";

import api from "../config/api";
import config from "../config/config";
import { getCurrentLoggedInSessionTokenWithPromise } from "../config/userPool";

const { GET_COMPANY_LIST } = config.api_endpoint;

const initialState = {
  loading: false,
  companyList: [],
  error: null,
};

export const resetCompanyList = createAction("RESET_ALL");

export const fetchALLCompany = createAsyncThunk(
  "company/fetchALLCompany",
  async (_, { rejectWithValue }) => {
    try {
      const token = await getCurrentLoggedInSessionTokenWithPromise();
      const results = await api.get(
        GET_COMPANY_LIST,
        {
          page: _.page || 1,
          page_size: _.page_size || 10,
          start_created_date: _.start_created_date || "",
          end_created_date: _.end_created_date || "",
          text: _.text || "",
        },
        token
      );
      console.log(results);
      return {
        companyList: results.data,
      };
    } catch (error) {
      return rejectWithValue(error.details);
    }
  }
);

const CompanySlice = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchALLCompany.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchALLCompany.fulfilled, (state, action) => {
        console.log(action);
        state.loading = false;
        state.companyList = action.payload;
      })
      .addCase(fetchALLCompany.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(resetCompanyList, () => initialState);
  },
});

const CompanyReducer = CompanySlice.reducer;
export default CompanyReducer;
