import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import BackIcon from "../../assets/images/Icon/PreviousIcon.svg";
import {
  MAP_LAYER_AUDIT_TRAIL,
  MAP_LAYER_DETAIL_ROUTE,
  MAP_LAYER_MANAGMENT,
} from "../../routes";
import { Input, RadioButton } from "@progress/kendo-react-inputs";
import { useSelector } from "react-redux";
import api from "../../config/api";
import {
  getCurrentLoggedInSessionTokenWithPromise,
  getCurrentLoggedInUserWithPromise,
} from "../../config/userPool";
import config from "../../config/config";
import moment from "moment";
import { Dialog, Loader } from "@progress/kendo-react-all";
import CrossIcon from "../../assets/images/Icon/Cross_black.svg";
import ConfirmMsg from "../../components/confirmMsg";

export const MapLayerForm = () => {
  const navigate = useNavigate();
  const uploadImage = useRef();
  let { search } = useLocation();
  const { CREATE_UPDATE_MAPLAYER, UPLOAD_IMAGE, GET_MAPLAYER } =
    config.api_endpoint;
  const query = new URLSearchParams(search);
  const id = query.get("id");
  const { general_config_data } = useSelector((state) => state.generalConfig);
  const [all_packages, setAll_packages] = useState([
    ...general_config_data.general_config_data.package,
  ]);
  const [showDelete, setShowDelete] = useState(false);
  const [confirmBox, setConfirmBox] = useState(false);
  const [loading, setLoading] = useState(true);
  const [editMode, setEditMode] = useState(false);
  const [editData, setEditData] = useState({
    layer_name: "",
    image_name: "",
    first_lat: "",
    first_lng: "",
    sec_lat: "",
    sec_lng: "",
    thr_lat: "",
    thr_lng: "",
    fou_lat: "",
    fou_lng: "",
    is_casereport: false,
    is_wdc: false,
    is_ohs: false,
    image_url: "",
  });
  const [modifiedData, setModifiedData] = useState({
    modified_at: "",
    modified_by: "",
  });

  const validator = () => {
    if (
      editData.layer_name === "" ||
      editData.image_name === "" ||
      editData.first_lat === "" ||
      editData.first_lng === "" ||
      editData.sec_lat === "" ||
      editData.sec_lng === "" ||
      editData.thr_lat === "" ||
      editData.thr_lng === "" ||
      editData.fou_lat === "" ||
      editData.fou_lng === "" ||
      editData.image_url === ""
    ) {
      return true;
    } else {
      return false;
    }
  };

  const getMapLayerDetail = async () => {
    const token = await getCurrentLoggedInSessionTokenWithPromise();
    const result = await api.post(
      GET_MAPLAYER,
      JSON.stringify({
        data_type: "MapLayers",
        statistics: {
          filter_by_id: id,
        },
      }),
      token
    );

    console.log(result);

    if (result.status_code === 200) {
      setEditData({
        ...editData,
        id: result.data.items[0].id,
        layer_name: result.data.items[0].layer_name,
        image_name: result.data.items[0].image_name,
        first_lat: result.data.items[0].corner_points[0][0],
        first_lng: result.data.items[0].corner_points[0][1],
        sec_lat: result.data.items[0].corner_points[1][0],
        sec_lng: result.data.items[0].corner_points[1][1],
        thr_lat: result.data.items[0].corner_points[2][0],
        thr_lng: result.data.items[0].corner_points[2][1],
        fou_lat: result.data.items[0].corner_points[3][0],
        fou_lng: result.data.items[0].corner_points[3][1],
        is_casereport: result.data.items[0].require_in_casereport,
        is_wdc: result.data.items[0].require_in_wdc,
        is_ohs: result.data.items[0].require_in_ohs,
        image_url: result.data.items[0].image_url,
      });
      setModifiedData({
        modified_at: result.data.items[0].modified_at
          ? moment(new Date(result.data.items[0].modified_at)).format(
              "YYYY-MM-DD"
            )
          : "-",
        modified_time: result.data.items[0].modified_at
          ? moment(new Date(result.data.items[0].modified_at)).format("h:mm a")
          : "-",
        modifier_name: result.data.items[0].modifier_name
          ? result.data.items[0].modifier_name
          : "-",
        creator_name: result.data.items[0].creator_name
          ? result.data.items[0].creator_name
          : "",
      });
      setLoading(false);
    }
  };

  const onUploadImage = (e) => {
    console.log(e.target.files[0]);
    setEditData({
      ...editData,
      image_url: e.target.files[0],
      image_name: e.target.files[0].name,
    });
  };

  const handleSaveClicked = () => {
    setConfirmBox(true);
  };

  const handleSaveToAPI = async () => {
    setConfirmBox(false);
    setLoading(true);
    console.log(editData);
    const token = await getCurrentLoggedInSessionTokenWithPromise();
    const currentLoginUser = await getCurrentLoggedInUserWithPromise();
    const upload_Image =
      typeof editData.image_url !== "string" &&
      (await api.post(
        UPLOAD_IMAGE,
        JSON.stringify({
          filename: editData.image_name,
        }),
        token
      ));

    console.log(upload_Image);

    const formDataUpload = new FormData();
    if (typeof editData.image_url !== "string") {
      for (let k in upload_Image.data.payload) {
        formDataUpload.append(k, upload_Image.data.payload[k]);
      }
      formDataUpload.append("file", editData.image_url);
    }
    const image_url =
      typeof editData.image_url !== "string"
        ? await api.postDiff(upload_Image.data.bucket_url, formDataUpload)
        : undefined;

    const uploadData = {
      image_name: editData.image_name,
      layer_name: editData.layer_name,
      corner_points: [
        [editData.first_lat, editData.first_lng],
        [editData.sec_lat, editData.sec_lng],
        [editData.thr_lat, editData.thr_lng],
        [editData.fou_lat, editData.fou_lng],
      ],
      require_in_casereport: editData.is_casereport,
      require_in_wdc: editData.is_wdc,
      require_in_ohs: editData.is_ohs,
      image_url:
        typeof editData.image_url !== "string"
          ? upload_Image.data.url
          : editData.image_url,
    };
    console.log(uploadData);
    if (image_url !== undefined) {
      alert("Error uploadin image!");
      return;
    }
    // {

    const result = await api.post(
      CREATE_UPDATE_MAPLAYER,
      JSON.stringify({
        data_type: "MapLayers",
        action: id ? "update" : "create",
        data: id
          ? {
              ...uploadData,
              id: editData.id,
              modified_by: currentLoginUser["sub"],
            }
          : { ...uploadData, created_by: currentLoginUser["sub"] },
      }),
      token
    );
    console.log(result);
    if (result.status_code === 200) {
      if (id) {
        setEditMode(false);
        getMapLayerDetail();
      } else {
        navigate(`${MAP_LAYER_DETAIL_ROUTE}?id=${result.data.id}`);
        setEditMode(false);
      }
    } else {
      alert(result.error);
      setEditMode(false);
      getMapLayerDetail();
    }
    // } else {
    //   alert("Error uploading image!");
    // }

    console.log(image_url);
  };

  useEffect(() => {
    // dispatch(fetchGeneralConfig());

    if (id) {
      getMapLayerDetail();
    } else {
      setEditMode(true);
      setLoading(false);
    }
  }, [id]);

  const DownloadFile = () => {
    window.open(editData.image_url);
  };

  const handleEditMode = () => {
    setEditMode(true);
  };

  const DeleteCCTV = () => {
    setShowDelete(true);
  };
  const ConfrimDelete = async () => {
    setLoading(true);
    setShowDelete(false);
    const token = await getCurrentLoggedInSessionTokenWithPromise();
    const result = await api.post(
      CREATE_UPDATE_MAPLAYER,
      JSON.stringify({
        data_type: "MapLayers",
        action: "delete",
        data: {
          id: id,
        },
      }),
      token
    );
    if (result.status_code === 200) {
      console.log(result);
      navigate(MAP_LAYER_MANAGMENT);
    } else {
      alert(result.message);
    }
  };

  return (
    <>
      {showDelete && (
        <Dialog>
          <div className="MapLayer-filter-width_resize">
            <div
              style={{
                position: "absolute",
                top: "15px",
                right: "13px",
                cursor: "pointer",
              }}
              onClick={() => setShowDelete(false)}
            >
              <img src={CrossIcon} />
            </div>
            <div className="MapLayer_Delete_confirm_title">Delete!</div>
            <div className="MapLayer_Delete_confirm_text">
              Are you sure? You want to delete this Map Layer!
            </div>
            <div className="MapLayer_Filter_button_main_box">
              <div
                className="MapLayer_Filter_clear_button"
                onClick={() => setShowDelete(false)}
              >
                Cancel
              </div>
              <div
                className="MapLayer_Filter_apply_button"
                onClick={ConfrimDelete}
              >
                Confirm
              </div>
            </div>
          </div>
        </Dialog>
      )}
      {confirmBox && (
        <ConfirmMsg
          onCloseDialog={() => {
            setConfirmBox(false);
          }}
          msgTitle={`Are you sure you really want to change the map layer details?`}
          okBtnMsg={"Yes, proceed"}
          okBtnClicked={handleSaveToAPI}
          cancelBtnMsg={"No, cancel"}
          cancelBtnClicked={() => {
            setConfirmBox(false);
          }}
        />
      )}
      {loading ? (
        <Loader
          type="infinite-spinner"
          style={{
            display: "flex",
            alignItems: "center",
            width: "40px",
            margin: "auto",
            justifyContent: "center",
            height: "640px",
          }}
        />
      ) : (
        <div style={{ background: "white" }}>
          <div className="MapLayer_form_toolbar">
            <div
              className="MapLayer_form_toolbar_back_icon"
              onClick={() => navigate(MAP_LAYER_MANAGMENT)}
            >
              <img src={BackIcon} />
            </div>
            <div className="MapLayer_form_toolbar_title">Map Layer Details</div>
          </div>
          <div className="MapLayer_create_scroll_box">
            <div className="MapLayer_create_main_box">
              <div className="MapLayer_create_editable_main_box">
                <div className="MapLayer_create_editable_title">
                  Name of Map Layer *
                </div>
                <div>
                  <Input
                    className="MapLayer_form_input_editable"
                    type="text"
                    defaultValue={editData.layer_name}
                    disabled={!editMode}
                    onChange={(e) => {
                      setEditData({
                        ...editData,
                        layer_name: e.target.value,
                      });
                    }}
                  />
                  {editMode && editData.layer_name === "" && (
                    <div className="ECM_error_text">
                      This field is required!
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="MapLayer_create_main_box">
              <div className="MapLayer_create_editable_main_box">
                <div className="MapLayer_create_editable_title">
                  1st Latitude (E.g: 1.3412) *
                </div>
                <div>
                  <Input
                    className="MapLayer_form_input_editable"
                    type="text"
                    defaultValue={editData.first_lat}
                    disabled={!editMode}
                    onChange={(e) => {
                      setEditData({
                        ...editData,
                        first_lat: e.target.value,
                      });
                    }}
                  />
                  {editMode && editData.first_lat === "" && (
                    <div className="ECM_error_text">
                      This field is required!
                    </div>
                  )}
                </div>
              </div>
              <div className="MapLayer_create_editable_main_box">
                <div className="MapLayer_create_editable_title">
                  1st Longitude (E.g: 103.21231) *
                </div>
                <div>
                  <Input
                    className="MapLayer_form_input_editable"
                    type="text"
                    defaultValue={editData.first_lng}
                    disabled={!editMode}
                    onChange={(e) => {
                      setEditData({
                        ...editData,
                        first_lng: e.target.value,
                      });
                    }}
                  />
                  {editMode && editData.first_lng === "" && (
                    <div className="ECM_error_text">
                      This field is required!
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="MapLayer_create_main_box">
              <div className="MapLayer_create_editable_main_box">
                <div className="MapLayer_create_editable_title">
                  2nd Latitude (E.g: 1.3412) *
                </div>
                <div>
                  <Input
                    className="MapLayer_form_input_editable"
                    type="text"
                    defaultValue={editData.sec_lat}
                    disabled={!editMode}
                    onChange={(e) => {
                      setEditData({
                        ...editData,
                        sec_lat: e.target.value,
                      });
                    }}
                  />
                  {editMode && editData.sec_lat === "" && (
                    <div className="ECM_error_text">
                      This field is required!
                    </div>
                  )}
                </div>
              </div>
              <div className="MapLayer_create_editable_main_box">
                <div className="MapLayer_create_editable_title">
                  2nd Longitude (E.g: 103.21231) *
                </div>
                <div>
                  <Input
                    className="MapLayer_form_input_editable"
                    type="text"
                    defaultValue={editData.sec_lng}
                    disabled={!editMode}
                    onChange={(e) => {
                      setEditData({
                        ...editData,
                        sec_lng: e.target.value,
                      });
                    }}
                  />
                  {editMode && editData.sec_lng === "" && (
                    <div className="ECM_error_text">
                      This field is required!
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="MapLayer_create_main_box">
              <div className="MapLayer_create_editable_main_box">
                <div className="MapLayer_create_editable_title">
                  3th Latitude (E.g: 1.3412) *
                </div>
                <div>
                  <Input
                    className="MapLayer_form_input_editable"
                    type="text"
                    defaultValue={editData.thr_lat}
                    disabled={!editMode}
                    onChange={(e) => {
                      setEditData({
                        ...editData,
                        thr_lat: e.target.value,
                      });
                    }}
                  />
                  {editMode && editData.thr_lat === "" && (
                    <div className="ECM_error_text">
                      This field is required!
                    </div>
                  )}
                </div>
              </div>
              <div className="MapLayer_create_editable_main_box">
                <div className="MapLayer_create_editable_title">
                  3th Longitude (E.g: 103.21231) *
                </div>
                <div>
                  <Input
                    className="MapLayer_form_input_editable"
                    type="text"
                    defaultValue={editData.thr_lng}
                    disabled={!editMode}
                    onChange={(e) => {
                      setEditData({
                        ...editData,
                        thr_lng: e.target.value,
                      });
                    }}
                  />
                  {editMode && editData.thr_lng === "" && (
                    <div className="ECM_error_text">
                      This field is required!
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="MapLayer_create_main_box">
              <div className="MapLayer_create_editable_main_box">
                <div className="MapLayer_create_editable_title">
                  4th Latitude (E.g: 1.3412) *
                </div>
                <div>
                  <Input
                    className="MapLayer_form_input_editable"
                    type="text"
                    defaultValue={editData.fou_lat}
                    disabled={!editMode}
                    onChange={(e) => {
                      setEditData({
                        ...editData,
                        fou_lat: e.target.value,
                      });
                    }}
                  />
                  {editMode && editData.fou_lat === "" && (
                    <div className="ECM_error_text">
                      This field is required!
                    </div>
                  )}
                </div>
              </div>
              <div className="MapLayer_create_editable_main_box">
                <div className="MapLayer_create_editable_title">
                  4th Longitude (E.g: 103.21231) *
                </div>
                <div>
                  <Input
                    className="MapLayer_form_input_editable"
                    type="text"
                    defaultValue={editData.fou_lng}
                    disabled={!editMode}
                    onChange={(e) => {
                      setEditData({
                        ...editData,
                        fou_lng: e.target.value,
                      });
                    }}
                  />
                  {editMode && editData.fou_lng === "" && (
                    <div className="ECM_error_text">
                      This field is required!
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div
              className="MapLayer_create_main_box"
              style={{ flexDirection: "column", gap: "16px" }}
            >
              <div className="MapLayer_create_editable_title">
                Is image required in case report?
              </div>
              <div>
                <div className="detail-item">
                  <div className="detail-radio-div">
                    <div className="width-50">
                      <RadioButton
                        className="details-radio"
                        label={"Yes"}
                        name="is_casereport"
                        disabled={!editMode}
                        //   value={v}
                        onChange={(e) =>
                          setEditData({
                            ...editData,
                            is_casereport: true,
                          })
                        }
                        checked={editData.is_casereport ? true : false}
                      />
                    </div>
                    <div className="width-50">
                      <RadioButton
                        className="details-radio"
                        label={"No"}
                        disabled={!editMode}
                        name="is_casereport"
                        onChange={(e) =>
                          setEditData({
                            ...editData,

                            is_casereport: false,
                          })
                        }
                        checked={editData.is_casereport ? false : true}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="MapLayer_create_main_box"
              style={{ flexDirection: "column", gap: "16px" }}
            >
              <div className="MapLayer_create_editable_title">
                Is image required in work declaration?
              </div>
              <div>
                <div className="detail-item">
                  <div className="detail-radio-div">
                    <div className="width-50">
                      <RadioButton
                        className="details-radio"
                        label={"Yes"}
                        name="is_wdc"
                        disabled={!editMode}
                        //   value={v}
                        onChange={(e) =>
                          setEditData({
                            ...editData,
                            is_wdc: true,
                          })
                        }
                        checked={editData.is_wdc ? true : false}
                      />
                    </div>
                    <div className="width-50">
                      <RadioButton
                        className="details-radio"
                        label={"No"}
                        name="is_wdc"
                        disabled={!editMode}
                        onChange={(e) =>
                          setEditData({
                            ...editData,
                            is_wdc: false,
                          })
                        }
                        checked={editData.is_wdc ? false : true}
                      />
                    </div>
                  </div>
                </div>
                {/* {editMode && editData.ecm_no === "" && (
                    <div className="ECM_error_text">
                      This field is required!
                    </div>
                  )} */}
              </div>
            </div>
            <div
              className="MapLayer_create_main_box"
              style={{ flexDirection: "column", gap: "16px" }}
            >
              <div className="MapLayer_create_editable_title">
                Is image required in OHS?
              </div>
              <div>
                <div className="detail-item">
                  <div className="detail-radio-div">
                    <div className="width-50">
                      <RadioButton
                        className="details-radio"
                        label={"Yes"}
                        name="is_ohs"
                        disabled={!editMode}
                        //   value={v}
                        onChange={(e) =>
                          setEditData({
                            ...editData,
                            is_ohs: true,
                          })
                        }
                        checked={editData.is_ohs ? true : false}
                      />
                    </div>
                    <div className="width-50">
                      <RadioButton
                        className="details-radio"
                        label={"No"}
                        name="is_ohs"
                        disabled={!editMode}
                        onChange={(e) =>
                          setEditData({
                            ...editData,
                            is_ohs: false,
                          })
                        }
                        checked={editData.is_ohs ? false : true}
                      />
                    </div>
                  </div>
                </div>
                {/* {editMode && editData.ecm_no === "" && (
                    <div className="ECM_error_text">
                      This field is required!
                    </div>
                  )} */}
              </div>
            </div>
            <div
              className="MapLayer_create_main_box"
              style={{
                gap: "16px",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div
                className="MapLayer_create_editable_title"
                style={{ flex: 1 }}
              >
                Upload image
              </div>
              <div
                onClick={() => uploadImage.current.click()}
                className="MapLayer_upload_button"
                style={{
                  cursor: !editMode && "",
                  pointerEvents: !editMode && "none",
                  opacity: !editMode && "0.5",
                }}
              >
                Upload
              </div>
              <div>
                <input
                  accept={[
                    "image/jpeg",
                    "image/png",
                    "image/jpg",
                    // "application/pdf",
                    // "application/msword",
                    // "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                    // "application/vnd.ms-excel",
                    // "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                    // "application/vnd.openxmlformats-officedocument.presentationml.presentation",
                  ]}
                  ref={uploadImage}
                  type="file"
                  hidden
                  onClick={(e) => (e.target.value = "")}
                  onChange={(e) => onUploadImage(e)}
                />
              </div>
            </div>
            <div className="MapLayer_create_main_box">
              {editData.image_url !== "" &&
                typeof editData.image_url !== "string" && (
                  <div
                    className="MapLayer_file_preview_main_box"
                    style={{ cursor: id && !editMode ? "pointer" : "" }}
                  >
                    <div className="MapLayer_file_preview_file_type">
                      {editData.image_url.name.split(".").pop()}
                    </div>
                    <div className="MapLayer_file_preview_detail_box">
                      <div className="MapLayer_file_preview_detail_text">
                        {editData.image_url.name.split(".").slice(0, -1)}
                      </div>
                      <div className="MapLayer_file_preview_detail_text">
                        {(
                          Math.round(editData.image_url.size) /
                          (1024 * 1024)
                        ).toFixed(2)}
                        MB
                      </div>
                    </div>
                  </div>
                )}
              {editData.image_url !== "" &&
                typeof editData.image_url === "string" && (
                  <div
                    className="MapLayer_file_preview_main_box"
                    style={{ cursor: id && !editMode ? "pointer" : "" }}
                    onClick={id && !editMode ? DownloadFile : ""}
                  >
                    <div className="MapLayer_file_preview_file_type">
                      {editData.image_name.split(".").pop()}
                    </div>
                    <div className="MapLayer_file_preview_detail_box">
                      <div className="MapLayer_file_preview_detail_text">
                        {editData.image_name.split(".").slice(0, -1)}
                      </div>
                      {/* <div className="MapLayer_file_preview_detail_text">
                        {(
                          Math.round(editData.image_url.size) /
                          (1024 * 1024)
                        ).toFixed(2)}
                        MB
                      </div> */}
                    </div>
                  </div>
                )}
            </div>
          </div>
          <div className="MapLayer_form_footer">
            <div style={{ display: "flex", gap: "12px" }}></div>
            <div className="MapLayer_form_footer_child_box">
              {id && !editMode ? (
                <div className="MapLayer_form_footer_save" onClick={DeleteCCTV}>
                  Delete
                </div>
              ) : (
                ""
              )}
              {id && !editMode ? (
                <div
                  className="MapLayer_form_footer_audit_trail"
                  onClick={() => navigate(`${MAP_LAYER_AUDIT_TRAIL}?id=${id}`)}
                >
                  View Audit Trail
                </div>
              ) : (
                ""
              )}
              <div
                className="MapLayer_form_footer_cancel"
                // onClick={handleCancelClicked}
                onClick={() => navigate(MAP_LAYER_MANAGMENT)}
              >
                Cancel
              </div>
              <div
                className="MapLayer_form_footer_save"
                style={{
                  pointerEvents: id
                    ? editMode
                      ? validator()
                        ? "none"
                        : ""
                      : ""
                    : validator()
                    ? "none"
                    : "",
                  opacity: id
                    ? editMode
                      ? validator()
                        ? "0.5"
                        : "1"
                      : "1"
                    : validator()
                    ? "0.5"
                    : "1",
                }}
                onClick={
                  id
                    ? editMode
                      ? handleSaveClicked
                      : handleEditMode
                    : handleSaveClicked
                }
              >
                {editMode ? "Save" : "Edit"}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
