// redux

import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";

import api from "../config/api";
import config from "../config/config";
import { getCurrentLoggedInSessionTokenWithPromise } from "../config/userPool";

const { GET_USER_LIST } = config.api_endpoint;

const initialState = {
  loading: false,
  userList: [],
  error: null,
};

export const resetUserList = createAction("RESET_ALL");

export const fetchALLUser = createAsyncThunk(
  "user/fetchALLUser",
  async (_, { rejectWithValue }) => {
    try {
      const token = await getCurrentLoggedInSessionTokenWithPromise();
      const results = await api.get(
        GET_USER_LIST,
        {
          page: _.page || 1,
          page_size: _.page_size || 10,
          start_joined_date: _.start_joined_date || "",
          end_joined_date: _.end_joined_date || "",
          package: _.package || "",
          role: _.role || "",
          status: _.status || "",
          text: _.text || "",
        },
        token
      );
      console.log(results);
      return {
        userList: results.data,
      };
    } catch (error) {
      return rejectWithValue(error.details);
    }
  }
);

const UserSlice = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchALLUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchALLUser.fulfilled, (state, action) => {
        console.log(action);
        state.loading = false;
        state.userList = action.payload;
      })
      .addCase(fetchALLUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(resetUserList, () => initialState);
  },
});

const UserReducer = UserSlice.reducer;
export default UserReducer;
