import { combineReducers } from "redux";
import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { createBrowserHistory } from "history";
import { createReduxHistoryContext } from "redux-first-history";
import UserReducer from "./userList";
import CompanyReducer from "./companyList";
import GroupReducer from "./groupList";
import AuditReducer from "./auditTrail";
import RegistrationReducer from "./registrationList";
import generalConfigReducer from "./generalConfigSlice";
import CCTVReducer from "./cctvList";
import ECMReducer from "./ecmList";
import MapLayerReducer from "./mapLayerSlice";

const { createReduxHistory, routerMiddleware, routerReducer } =
  createReduxHistoryContext({ history: createBrowserHistory() });

export const store = configureStore({
  reducer: combineReducers({
    router: routerReducer,
    userList: UserReducer,
    companyList: CompanyReducer,
    groupList: GroupReducer,
    cctvList: CCTVReducer,
    ecmList: ECMReducer,
    audit: AuditReducer,
    registrationList: RegistrationReducer,
    generalConfig: generalConfigReducer,
    mapLayerList: MapLayerReducer,
  }),
  middleware: [...getDefaultMiddleware(), routerMiddleware],
});

export const history = createReduxHistory(store);
