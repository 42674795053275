// redux

import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";

import api from "../config/api";
import config from "../config/config";
import { getCurrentLoggedInSessionTokenWithPromise } from "../config/userPool";

const { GET_AUDIT_ENDPOINT } = config.api_endpoint;

const initialState = {
  loading: false,
  audit: [],
  error: null,
};

export const resetAuditList = createAction("RESET_ALL");

export const fetchALLAudit = createAsyncThunk(
  "audit/fetchALLAudit",
  async (_, { rejectWithValue }) => {
    try {
      const token = await getCurrentLoggedInSessionTokenWithPromise();
      const results = await api.get(
        GET_AUDIT_ENDPOINT,
        {
          event_id: _,
        },
        token
      );
      console.log(results);
      return {
        audit: results.data,
      };
    } catch (error) {
      return rejectWithValue(error.details);
    }
  }
);

const AuditSlice = createSlice({
  name: "audit",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchALLAudit.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchALLAudit.fulfilled, (state, action) => {
        console.log(action);
        state.loading = false;
        state.audit = action.payload;
      })
      .addCase(fetchALLAudit.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(resetAuditList, () => initialState);
  },
});

const AuditReducer = AuditSlice.reducer;
export default AuditReducer;
